import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import echarts from "echarts"

import './plugins/element.js'
// 引入全局样式
import './assets/css/global.css'
// 引入字体图标
import 'font-awesome/css/font-awesome.min.css'
import Element from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
import Cookies from 'js-cookie'
import VueAxios from 'vue-axios'
import axios from 'axios'
import service from './utils/service'
import initial from './initial/initialData'
import formatter from './initial/formatter'
import VueClipboard from 'vue-clipboard2'
import 'vant/lib/index.css'
import Vant from 'vant'
// import '@vant/touch-emulator';
import { MessageBox,Message } from 'element-ui'
import LemonIMUI from 'lemon-imui';
import 'lemon-imui/dist/index.css';
Vue.use(LemonIMUI);
import md5 from 'js-md5';
Vue.prototype.$md5 = md5;

Vue.use(Vant)
Vue.use(Element)
Vue.use(VueClipboard)
Vue.use(VueAxios, axios)
Vue.prototype.$echarts = echarts
Vue.prototype.$service=service
Vue.prototype.$initial=initial
Vue.prototype.$formatter=formatter

axios.defaults.headers.post['Content-Type'] = 'application/json';
//aixos拦截器
// axios.defaults.baseURL = 'http://test.api.generone.com';
// axios.defaults.proxy = {
//   host: 'http://test.api.generone.com',
//   port: 80,
//   protocol: 'http'
// };
axios.interceptors.request.use(config => {
	if (config.url.indexOf("/login") == -1 && config.url.indexOf("/customer") == -1) {
		config.headers.common['Authorization'] = sessionStorage.getItem('sessionToken') || ''
	}
	return config;
}, error => {
	return Promise.reject(error);
})
//aixos拦截器
axios.interceptors.response.use(res => {
	if (res.data.status == 40001) {
		MessageBox.confirm('登录已失效，请重新登录！', '提示', {
			confirmButtonText: '确定',
			type: 'warning'
		}).then(() => {
			// this.$store.commit('clearVUEX')
			sessionStorage.clear();
			router.replace({
				path: '/login'
			})
		}).catch((err) => {
			console.log(err)
		})
	} else if (res.data.status !== 200) {
		Message.warning(res.data.message);
	}
	return Promise.resolve(res)
}, error => {
	return Promise.reject(error);
})

Vue.config.productionTip = false

new Vue({
  router,
  store,  //store:store 和router一样，将创建的Vuex实例挂载到这个vue实例中
  render: h => h(App)
}).$mount('#app')
