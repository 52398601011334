<template>
  <div id="app">
    <!-- 路由占位符 -->
    <router-view />
    <audio controls ref="audio" style="display: none;">
      <source :src="mp3" />
    </audio>
  </div>
</template>
<script>
  import {
    mapState,
    mapMutations
  } from 'vuex';
  import ResizeMixin from '@/mixin/ResizeHandler'
  export default {
    data() {
      return {
        mp3: require("./assets/message.mp3"),
        path: "",
        isShow: false
      }
    },
    name: 'app',
    mixins: [ResizeMixin],
    computed: {
      ...mapState({
        device: state => state.device, //判断窗口类型
      })
    },
    // 防止数据丢失
    created() {
      //在页面加载时读取sessionStorage里的状态信息
      if (sessionStorage.getItem("store")) {
        this.$store.replaceState(Object.assign({}, this.$store.state, JSON.parse(sessionStorage.getItem("store"))))
      };
      window.addEventListener('beforeunload', () => {
        sessionStorage.setItem("store", JSON.stringify(this.$store.state))
      })
      // alert(`${this.$route.path}`)
      // if(this.$route.path){
      //   this.isShow=true;
      //   this.path=this.$route.path
      // }
    },
    mounted() {
    },
    methods:{
    }
  }
</script>
<style>
</style>
