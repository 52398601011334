<template>
  <div class="b_user">
    <div class="b_div">
    </div>
    <div class="t_header">
      <div class="flex_div">
        <div class="time">
          <span>公司名称：</span>
          <el-select :disabled="disable" v-model="form.companyName" filterable placeholder="请选择" @change="setDefaultData"
            size="small" style="width: 300px;">
            <el-option v-show="userInfo.userLevel == 0 || userInfo.userLevel == item.level" v-for="item in companyList" :key="item.companyName" :label="item.companyAlias"
              :value="item.companyName"> </el-option>
          </el-select>
        </div>
        <div class="time">
          <span>发布平台：</span>
          <el-select :disabled="disable" v-model="form.platform" filterable placeholder="请选择" size="small"
            style="width: 100px;">
            <el-option label="iOS" value="iOS"></el-option>
            <el-option label="Android" value="Android"></el-option>
          </el-select>
        </div>
      </div>
      <div class="flex_div">
        <div class="time">
          <span>应用包名：</span>
          <el-select :disabled="disable" v-model="form.packageName" filterable placeholder="请选择" size="small"
            style="width: 200px;">
            <el-option v-for="item in packageList" :key="item.packageName" :label="item.packageAlias"
              :value="item.packageName"> </el-option>
          </el-select>
        </div>
        <div class="time">
          <span>协议类型：</span>
          <el-select :disabled="disable" v-model="form.agreementType" filterable placeholder="请选择"
            @change="setDefaultData" size="small" style="width: 200px;">
            <el-option v-for="item in agreementTypeList" :key="item.value" :label="item.key" :value="item.value">
            </el-option>
          </el-select>
        </div>
      </div>
      <div class="time" style="display:flex;" v-if="form.platform == 'Android'">
        <div style="width: 70px;"><span>渠道：</span></div>
        <el-checkbox-group v-model="form.channelList" :disabled="disable">
          <el-checkbox v-for="channel in channelList" :key="channel" :label="channel" :value="channel">
            {{channel}}</el-checkbox>
        </el-checkbox-group>
      </div>
      <div class="time" v-if="type == 'edit'">
        <span>发布路径：</span>
        <el-input style="width: 50%;color:#666666;font-size: 14px!important;" disabled v-model="form.agreementUrl">
        </el-input>
      </div>
      <div class="time" style="display:flex;">
        <div style="width: 70px;"><span>备注：</span></div>
        <el-input style="width: 50%;color:#666666;font-size: 14px!important;" v-model="form.remark" type="textarea"
          placeholder="请输入内容 "></el-input>
      </div>
    </div>
    <div style="margin-top: 30px; width: 100%;display:flex;">
      <div style="font-size: 14px;color: #666666;"><span>协议内容：</span></div>
      <div style="width:90%">
        <vue-ueditor-wrap editor-id="editor" v-model="msg" :config="config" @ready="ready"></vue-ueditor-wrap>
      </div>
    </div>
    <div class="btn_group" v-loading="showLoading" element-loading-text="请稍后。。。">
      <el-button style="margin-right: 20px;" type="success" @click="back">返回</el-button>
      <el-button type="primary" @click="confirm">确定</el-button>
    </div>
  </div>
</template>
<script>
  import VueUeditorWrap from 'vue-ueditor-wrap'
  export default {
    components: {
      VueUeditorWrap,
    },
    name: 'AppAgreementDetail',
    created() {
      this.userInfo = JSON.parse(sessionStorage.getItem("userInfo"))
    },
    data() {
      return {
        userInfo: {},
        defaultPrivacyAgreement: '',
        defaultUserAgreement: '',
        disable: false,
        showLoading: false,
        UE: '',
        id: '',
        packageList: [ ],
        companyList: [
          {companyName: "generone", companyAlias: "成都初代科技有限公司", level: 10},
          {companyName: "lotsgain", companyAlias: "成都哆萤科技有限公司", level: 10},
          {companyName: "wisdomtec", companyAlias: "成都威仕多网络科技有限公司"},
          {companyName: "cdjztec", companyAlias: "成都璟智信息技术有限公司"},
        ],
        channelList: [
          "huawei", "xiaomi", "oppo", "vivo", "honor", "samsung", "yingyongbao", "baidu", "wandoujia"
        ],
        agreementTypeList: [{
            key: '隐私协议',
            value: 1
          },
          {
            key: '用户协议',
            value: 2
          },{
            key: '个人信息收集清单',
            value: 3
          },{
            key: '三方信息共享清单',
            value: 4
          },{
            key: '会员服务协议',
            value: 5
          },
          {
            key: '其他协议',
            value: 0
          },
        ],
        type: 'add',
        form: {
          companyName: "generone",
          channel: "",
          agreementType: 1,
          platform: "Android",
          channelList: []
        },
        msg: '',
        config: {
          UEDITOR_HOME_URL: process.env.BASE_URL + 'ueditor/', // 需要令此处的URL等于对应 ueditor.config.js 中的配置。
          initialFrameHeight: 400, // 初始容器高度
          initialFrameWidth: '90%', // 初始容器宽度
          autoHeightEnabled: false
        },
      }
    },
    mounted() {
      this.type = this.$route.params.type
      if (this.type == 'edit') {
        this.disable = true
        this.id = this.$route.params.data
      }
      this.getPackageList()
    },
    methods: {
      ready(ue) {
        this.UE = ue
        if (this.type == 'edit') {
          this.getAgreementDetail(this.id)
        } else {
          this.UE.setContent(this.loadFile(process.env.BASE_URL + 'privacyAgreement_generone.txt'))
        }
      },
      getAgreementDetail(id) {
        let hostUrl=this.$service.publicUrl().AgreementDetail + id
        this.axios({
        	method: "GET",
        	url: hostUrl,
        }).then((response)=>{
            if(response.data.status==200){
        		  this.form = response.data.data
              this.UE.setContent(this.form.htmlContent)
            }else{
        		  this.$message.warning("warning : "+response.data.message);
        	}
        	this.selected=null
        }).catch((error)=>{
        	this.loading=false
        	this.$message.error("查询失败，error : "+JSON.stringify(error));
        })
      },
      getPackageList() {
      	let hostUrl=this.$service.publicUrl().PackageListAll
      	this.axios({
      		method: "GET",
      		url: hostUrl,
      	}).then((response)=>{
      		this.loading=false
      	    if(response.data.status==200){
      			  this.packageList=response.data.data
      	    }else{
      			  this.$message.warning("warning : "+response.data.message);
      		}
      	}).catch((error)=>{
      		this.loading=false
      		this.$message.error("查询失败，error : "+JSON.stringify(error));
      	})
      },
      setDefaultData() {
        console.log(this.type)
        console.log(this.form)
        if (this.type == 'add') {
          if (this.form.agreementType == 1 && this.form.companyName != undefined) {
            //隐私协议
            switch (this.form.companyName) {
              case 'generone': {
                this.UE.setContent(this.loadFile(process.env.BASE_URL + 'privacyAgreement_generone.txt'))
                break
              }
              case 'lotsgain': {
                this.UE.setContent(this.loadFile(process.env.BASE_URL + 'privacyAgreement_lotsgain.txt'))
                break
              }
              case 'wisdomtec': {
                this.UE.setContent(this.loadFile(process.env.BASE_URL + 'privacyAgreement_wisdomtec.txt'))
                break
              }
              case 'cdjztec': {
                this.UE.setContent(this.loadFile(process.env.BASE_URL + 'privacyAgreement_cdjztec.txt'))
                break
              }
            }
            // this.UE.setContent(this.loadFile(process.env.BASE_URL + 'defaultPrivacyAgreement.txt'))
          } else if (this.form.agreementType == 2) {
            //用户协议
            switch (this.form.companyName) {
              case 'generone': {
                this.UE.setContent(this.loadFile(process.env.BASE_URL + 'userAgreement_generone.txt'))
                break
              }
              case 'lotsgain': {
                this.UE.setContent(this.loadFile(process.env.BASE_URL + 'userAgreement_lotsgain.txt'))
                break
              }
              case 'wisdomtec': {
                this.UE.setContent(this.loadFile(process.env.BASE_URL + 'userAgreement_wisdomtec.txt'))
                break
              }
              case 'cdjztec': {
                this.UE.setContent(this.loadFile(process.env.BASE_URL + 'userAgreement_cdjztec.txt'))
                break
              }
            }
          } else if (this.form.agreementType == 3) {
            //个人信息收集清单
            this.UE.setContent(this.loadFile(process.env.BASE_URL + 'userInfoCollect.txt'))
          } else if (this.form.agreementType == 4) {
            //第三方信息共享清单
            this.UE.setContent(this.loadFile(process.env.BASE_URL + 'userInfoShare.txt'))
          } else if (this.form.agreementType == 5) {
            //用户协议
            switch (this.form.companyName) {
              case 'generone': {
                this.UE.setContent(this.loadFile(process.env.BASE_URL + 'vipAgreement_generone.txt'))
                break
              }
              case 'lotsgain': {
                this.UE.setContent(this.loadFile(process.env.BASE_URL + 'vipAgreement_lotsgain.txt'))
                break
              }
              case 'wisdomtec': {
                this.UE.setContent(this.loadFile(process.env.BASE_URL + 'vipAgreement_wisdomtec.txt'))
                break
              }
              case 'cdjztec': {
                this.UE.setContent(this.loadFile(process.env.BASE_URL + 'vipAgreement_cdjztec.txt'))
                break
              }
            }
          } else {
            this.UE.setContent('')
          }
        }
      },
      back() {
        this.$router.go(-1)
      },
      confirm() {
        console.log(this.UE.getContent())
        if (!this.form.companyName || !this.form.packageName || !this.form.agreementType || !this.form.platform) {
          this.$message.error('请填写完整')
          return
        }

        this.showLoading = true
        let data = {
          ...this.form,
        }
        let formdata = new FormData()
        for (let item in data) {
          if (item != 'file' && data[item] !== null) {
            formdata.append(item, data[item])
          }
        }
        let fileName = this.form.packageName.replaceAll('.', '-')
        let title = ''
        if (this.form.agreementType == 1) {
          fileName += '_privacy'
          title = '隐私协议'
        } else if (this.form.agreementType == 2) {
          fileName += '_user'
          title = '用户协议'
        } else if (this.form.agreementType == 3) {
          fileName += '_collect'
          title = '个人信息收集清单'
        } else if (this.form.agreementType == 4) {
          fileName += '_share'
          title = '第三方信息共享清单'
        }  else if (this.form.agreementType == 5) {
          fileName += '_vip'
          title = '会员服务协议'
        } else {
          fileName += '_other'
          title = '其他协议'
        }
        let platForm = this.form.platform == 'Android' ? '' : ('_' + this.form.platform)
        let channel = this.form.channel ? ('_' + this.form.channel) : ''
        fileName = fileName + platForm + channel + '.html'
        var htmlHead =
          '<!DOCTYPE html><html xmlns="http://www.w3.org/1999/xhtml"><head><meta http-equiv="Content-Type" content="text/html; charset=UTF-8"><meta http-equiv="expires" content="0"> <meta http-equiv="pragma" content="no-cache"> <meta name="viewport" content="width=device-width, initial-scale=1, user-scalable=no"><title>' +
          title + '</title></head><body>'
        var htmlTail = '</body></html>'
        let html = htmlHead + this.UE.getContent() + htmlTail
        formdata.set('htmlContent', this.UE.getContent())
        var blob = new Blob([html])
        var file = new File([blob], fileName)
        formdata.append('file', file)
        if (this.type == 'edit') {
          this.update(formdata)
        } else {
          this.save(formdata)
        }
      },
      update(data) {
      	let hostUrl=this.$service.publicUrl().AgreementUpdate
      	this.axios({
      		method: "POST",
      		url: hostUrl,
          data: data
      	}).then((response)=>{
          this.showLoading = true
      	    if(response.data.status==200){
      			  this.$message.success("保存成功");
      			  this.back()
      	    }else{
      			  this.$message.warning("warning : "+response.data.message);
      		}
      	}).catch((error)=>{
      		this.showLoading = true
      		this.$message.error("查询失败，error : "+JSON.stringify(error));
      	})
      },
      save(data) {
      	let hostUrl= this.form.channelList.length > 0 ? this.$service.publicUrl().AgreementAddBatch : this.$service.publicUrl().AgreementAdd
      	this.axios({
      		method: "POST",
      		url: hostUrl,
          data: data
      	}).then((response)=>{
      		this.showLoading = true
      	    if(response.data.status==200){
      			   this.$message.success("保存成功");
               this.back()
      	    }else{
      			  this.$message.warning("warning : "+response.data.message);
      		}
      	}).catch((error)=>{
      		this.showLoading = true
      		this.$message.error("查询失败，error : "+JSON.stringify(error));
      	})
      },
      loadFile(name) {
        const xhr = new XMLHttpRequest()
        const okStatus = document.location.protocol === 'file:' ? 0 : 200
        xhr.open('GET', name, false)
        xhr.overrideMimeType('text/plain;charset=utf-8') // 默认为utf-8
        xhr.send(null)
        return xhr.status === okStatus ? xhr.responseText : null
      },
    },
  }
</script>
<style lang="less" scoped>
  .btn_group {
    margin-top: 40px;
    // padding-right: 50px;
    text-align: center;
  }

  .flex_div {
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }

  .t_header {

    // height: 48px;
    // display: flex;
    // justify-content: flex-start;
    // align-items: center;
    .time {
      // display: flex;
      margin-right: 30px;
      margin-bottom: 20px;

      span {
        font-size: 14px;
        color: #666666;
      }
    }
  }

  .b_div {
    margin: 38px 0;

    .b_divider {
      color: #9e9e9e;
    }
  }

  .f_page {
    margin-top: 20px;
    float: right;
  }

  .remark {
    margin-left: 5px;
    font-size: 10px;
    color: #606266;
    display: inline-block;
  }
</style>
<style lang="less">
  .el-textarea__inner:focus {
    border-color: #dcdfe6;
  }

  .switch_text .el-switch__label--left {
    color: #909399;
  }

  .switch_text .el-switch__label--right {
    color: #67c23a;
  }

  .el-button--text {
    padding-right: 10px;
  }

  .el-form-item__label {
    white-space: pre-line;
  }

  // .el-upload-list__item {
  //   width: 480px;
  // }
  // .el-upload-list--picture .el-upload-list__item {
  //   height: 220px;
  // }
  .el-upload-list__item-thumbnail {
    width: 100px;
    height: 100px;
  }

  span .el-upload-list__item-actions {
    width: 100px;
    height: 100px;
  }
</style>
