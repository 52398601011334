<template>
  <div id="dis_list" class="content" v-loading="loading" element-loading-text="加载中">
    <!-- 顶部操作栏 -->
    <div class="cont_bar">
      <div class="bar_left">
        <el-form :inline="true" :model="searchData" class="demo-form-inline" size="small">
          <el-form-item label="应用名称：">
            <el-select v-model="searchData.packageGroupName" placeholder="应用名称" :filterable="true">
              <el-option label="全部" value=""></el-option>
              <el-option v-for="item in seriesList" :key="item.id" :label="item.packageGroupName"
                :value="item.packageGroupName"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item>
            <el-tooltip content="查询" placement="top">
              <el-button type="primary" @click="search()" icon="el-icon-search"></el-button>
            </el-tooltip>
          </el-form-item>
        </el-form>
      </div>
      <div class="bar_right">
        <el-row>
          <el-tooltip content="新增" placement="top">
            <el-button type="success" icon="el-icon-plus" size="mini" @click="openAdd"></el-button>
          </el-tooltip>
          <el-tooltip content="编辑" placement="top">
            <el-button type="info" icon="el-icon-edit" size="mini" @click="openUpdate"></el-button>
          </el-tooltip>
          <el-tooltip content="删除" placement="top">
            <el-button type="warning" icon="el-icon-delete" size="mini" @click="openDetele()"></el-button>
          </el-tooltip>
        </el-row>
      </div>
    </div>
    <!-- 主内容区 -->
    <div class="cont_table">
      <!-- 表格 -->
      <el-table ref="goodsTable" id="goodsTable" :data="tableList" highlight-current-row
        @row-click="handleCurrentChange" width="100%" height="calc(100% - 70px)">
        <el-table-column type="index" width="100" label="编号"></el-table-column>
        <el-table-column property="packageAlias" label="应用名称" min-width="200"></el-table-column>
        <el-table-column property="packageName" label="应用包名" min-width="300"></el-table-column>
        <el-table-column property="packageGroupName" label="应用系列" min-width="200"></el-table-column>
        <el-table-column property="advertId" label="广告应用id" min-width="300"></el-table-column>
      </el-table>
      <!-- 分页器 -->
      <div class="block">
        <el-pagination :current-page="pageNum" :page-sizes="[30, 50, 100]" :page-size="pageSize" :total="total"
          layout="total, sizes, prev, pager, next, jumper" @size-change="pageSizeChange"
          @current-change="pageNumChange">
        </el-pagination>
      </div>
    </div>
    <el-dialog title="新增应用包" :visible.sync="addFormVisible" width="500px" @close="initDialog()">
      <el-form :model="addForm" ref="addForm" :rules="addRules" class="add_form">
        <el-form-item label="应用名称 :" label-width="100px" prop="packageAlias">
          <el-input v-model="addForm.packageAlias" autocomplete="off" placeholder="请输入应用名称"></el-input>
        </el-form-item>
        <el-form-item label="应用包名 :" label-width="100px" prop="packageName">
          <el-input v-model="addForm.packageName" autocomplete="off" placeholder="请输入应用包名"></el-input>
        </el-form-item>
        <el-form-item label="应用系列 :" label-width="100px" prop="packageGroupName">
          <el-select v-model="addForm.packageGroupName" placeholder="请选择应用包系列">
            <el-option :label="item.packageGroupName" :value="item.packageGroupName" v-for="(item, index) in seriesList"
              :key="index"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="广告应用id :" label-width="100px" prop="umengAppId">
          <el-input v-model="addForm.advertId" autocomplete="off" placeholder="请输入广告应用id"></el-input>
        </el-form-item>
        <el-form-item v-show="userInfo.userLevel == 0" label="绑定用户 :" label-width="100px" prop="adminIdList">
          <el-checkbox-group v-model="addForm.adminIdList">
            <el-checkbox v-for="admin in adminList" :key="admin.adminId" :label="admin.adminId" :value="admin.adminId">
              {{admin.userName}}</el-checkbox>
          </el-checkbox-group>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="addFormVisible = false">取 消</el-button>
        <el-button type="primary" @click="addPackage()">确 定</el-button>
      </div>
    </el-dialog>
    <el-dialog title="修改应用包" :visible.sync="updateFormVisible" width="500px" @close="initDialog()">
      <el-form :model="addForm" ref="addForm" :rules="addRules" class="add_form">
        <el-form-item label="应用名称 :" label-width="100px" prop="packageAlias">
          <el-input v-model="addForm.packageAlias" autocomplete="off" placeholder="请输入应用名称"></el-input>
        </el-form-item>
        <el-form-item label="应用包名 :" label-width="100px" prop="packageName">
          <el-input v-model="addForm.packageName" autocomplete="off" disabled></el-input>
        </el-form-item>
        <el-form-item label="应用系列 :" label-width="100px" prop="packageGroupName">
          <el-select v-model="addForm.packageGroupName" placeholder="请选择应用包系列">
            <el-option :label="item.packageGroupName" :value="item.packageGroupName" v-for="(item, index) in seriesList"
              :key="index"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="广告应用id :" label-width="100px" prop="umengAppId">
          <el-input v-model="addForm.advertId" autocomplete="off" placeholder="请输入广告应用id"></el-input>
        </el-form-item>
        <el-form-item v-show="userInfo.userLevel == 0" label="绑定用户 :" label-width="100px" prop="adminIdList">
          <el-checkbox-group v-model="addForm.adminIdList" disabled>
            <el-checkbox v-for="admin in adminList" :key="admin.adminId" :label="admin.adminId">
              {{admin.userName}}</el-checkbox>
          </el-checkbox-group>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="updateFormVisible = false">取 消</el-button>
        <el-button type="primary" @click="updatePackage()">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>
<script>
  export default {
    name: 'PackageManage',
    //组件创建
    created() {
      this.search()
      this.getSeriesList()
      this.userInfo = JSON.parse(sessionStorage.getItem("userInfo"))
      if (this.userInfo.userLevel == 0) {
        this.getAdminList()
      }
    },
    data() {
      return {
        userInfo: {},
        loading: false,
        updateFormVisible: false,
        addFormVisible: false,
        tableList: [], //tab列表
        seriesList: [],
        selected: null, //当前选中行
        //行详情抽屉
        rowDrawer: false,
        searchData: {},
        rowDetail: {},
        pageNum: 1,
        pageSize: 30,
        total: 0,
        addForm: {
          adminIdList: []
        },
        adminList: [],
        addRules: {
          packageAlias: [{
            required: true,
            message: '应用名称不能为空'
          }],
          packageName: [{
            required: true,
            message: '应用包名不能为空'
          }],
          packageGroupName: [{
            required: true,
            message: '应用包系列不能为空'
          }],
        },
      }
    },
    methods: {
      getAdminList() {
        var _this = this
        let hostUrl = this.$service.publicUrl().AdminList
        this.axios({
          method: "GET",
          url: hostUrl,
        }).then((response) => {
          this.loading = false
          if (response.data.status == 200) {
            _this.adminList = response.data.data
          } else {
            this.$message.warning("warning : " + response.data.message);
          }
        }).catch((error) => {
          this.loading = false
          this.$message.error("查询失败，error : " + JSON.stringify(error));
        })
      },
      getSeriesList() {
        let hostUrl = this.$service.publicUrl().PackageSeriesList
        this.axios({
          method: "GET",
          url: hostUrl,
        }).then((response) => {
          this.loading = false
          if (response.data.status == 200) {
            this.seriesList = response.data.data
          } else {
            this.$message.warning("warning : " + response.data.message);
          }
        }).catch((error) => {
          this.loading = false
          this.$message.error("查询失败，error : " + JSON.stringify(error));
        })
      },
      search() {
        this.pageNum = 1
        this.getDataList()
      },
      //列表查询
      getDataList() {
        let param = {
          pageNum: this.pageNum,
          pageSize: this.pageSize,
          ...this.searchData,
        }
        let hostUrl = this.$service.publicUrl().PackageList
        this.axios({
          method: "GET",
          url: hostUrl,
          params: param
        }).then((response) => {
          console.log(response)
          this.loading = false
          if (response.data.status == 200) {
            this.tableList = response.data.data.list
            this.total = response.data.data.total
          } else {
            this.$message.warning("warning : " + response.data.message);
          }
          this.selected = null
        }).catch((error) => {
          this.loading = false
          this.$message.error("查询失败，error : " + JSON.stringify(error));
        })
      },
      openAdd() {
        this.addForm = {
          adminIdList: []
        }
        this.addFormVisible = true
      },
      addPackage() {
        this.$refs["addForm"].validate((valid) => {
          if (valid) {
            let hostUrl = this.$service.publicUrl().PackageAdd
            this.axios({
              method: "POST",
              url: hostUrl,
              data: this.addForm
            }).then((response) => {
              this.loading = false
              if (response.data.status == 200) {
                this.$message.success("保存成功");
                this.search()
              } else {
                this.$message.warning("warning : " + response.data.message);
              }
              this.addFormVisible = false
            }).catch((error) => {
              this.loading = false
              this.$message.error("保存失败，error : " + JSON.stringify(error));
            })
          }
        })
      },
      openUpdate() {
        if (this.selected == null) {
          this.$message.warning("请选择一条记录");
          return
        }
        this.addForm = this.selected
        this.updateFormVisible = true
      },
      updatePackage() {
        this.$refs["addForm"].validate((valid) => {
          if (valid) {
            let hostUrl = this.$service.publicUrl().PackageUpdate
            this.axios({
              method: "PUT",
              url: hostUrl,
              data: this.addForm
            }).then((response) => {
              this.loading = false
              if (response.data.status == 200) {
                this.$message.success("修改成功");
                this.search()
              } else {
                this.$message.warning("warning : " + response.data.message);
              }
              this.updateFormVisible = false
            }).catch((error) => {
              this.loading = false
              this.$message.error("保存失败，error : " + JSON.stringify(error));
            })
          }
        })
      },
      openDetele() {
        if (this.selected == null) {
          this.$message.warning("请先选中一条记录！");
          return
        }
        this.$confirm('此操作将永久删除该应用包的数据，并且会一并删除该应用包所关联的所有数据，不可恢复！请慎重考虑后操作！！！', '删除警告', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'error',
          confirmButtonClass: "buttom_delete",
          cancelButtonClass: "buttom_cancel"
        }).then(() => {
          this.deleteChannel()
        }).catch(() => {});
      },
      deleteChannel() {
        let hostUrl = this.$service.publicUrl().PackageDelete
        this.axios({
          method: "DELETE",
          url: hostUrl,
          params: {
            packageName: this.selected.packageName
          }
        }).then((response) => {
          this.loading = false
          if (response.data.status == 200) {
            this.$message.success("删除成功");
            this.search()
          } else {
            this.$message.warning("warning : " + response.data.message);
          }
          this.selected = null
        }).catch((error) => {
          this.loading = false
          this.$message.error("删除失败，error : " + JSON.stringify(error));
        })
      },
      //点击行
      handleCurrentChange(e) {
        this.selected = e
      },
      initDialog() {
        this.$refs["addForm"].resetFields()
      },
      pageSizeChange(e) {
        this.pageSize = e
        this.getDataList()
      },
      //pageNum变化
      pageNumChange(e) {
        this.pageNum = e
        this.getDataList();
      },
    }
  }
</script>
<style lang="less" scoped>
  .drawer_inner {
    width: 95%;
    height: 100%;
    margin: auto;
  }

  .cont_table /deep/ .el-icon-tickets {
    font-size: 20px;
    margin-top: 4px;
  }

  .tips {
    font-size: 8px;
    margin-left: 30px;
    color: #878787;
  }

  .tips>span {
    color: orangered !important;
  }
</style>
<style>
  .buttom_cancel {
    background: #409EFF;
    color: #FFFFFF;
  }

  .buttom_delete {
    background: #999999 !important;
    border-color: #999999 !important;
  }
</style>
