<template>
  <div id="dis_list" class="content" v-loading="loading" element-loading-text="加载中">
    <div class="content_right">
      <!-- 顶部操作栏 -->
      <div class="cont_bars">
      </div>
      <!-- 主内容区 -->
      <div class="cont_table">
        <div class="count_list">
          <div class="count_item background1">
            <div>新增用户</div>
            <div style="font-size: 18px;font-weight:400; padding: 20px 0 0 10px;text-align: left;">
              <div>
                <label>今日</label>
                <label style="font-weight: 550;padding-left: 10px;font-size: 22px;">{{userStatisticsTotal.newToday}}</label>
              </div>
              <div style="margin-top: 20px;">
                <label>昨日</label>
                <label style="font-weight: 550;padding-left: 10px;font-size: 22px;">{{userStatisticsTotal.newYesterday}}</label>
              </div>
            </div>
          </div>
          <div class="count_item background2">
            <div>活跃用户</div>
            <div style="font-size: 18px;font-weight:400; padding: 20px 0 0 10px;text-align: left;">
              <div>
                <label>今日</label>
                <label style="font-weight: 550;padding-left: 10px;font-size: 22px;">{{userStatisticsTotal.activeToday}}</label>
              </div>
              <div style="margin-top: 20px;">
                <label>昨日</label>
                <label style="font-weight: 550;padding-left: 10px;font-size: 22px;">{{userStatisticsTotal.activeYesterday}}</label>
              </div>
            </div>
          </div>
          <div class="count_item background4">
            <div>运行次数</div>
            <div style="font-size: 18px;font-weight:400; padding: 20px 0 0 10px;text-align: left;">
              <div>
                <label>今日</label>
                <label style="font-weight: 550;padding-left: 10px;font-size: 22px;">{{userStatisticsTotal.startToday}}</label>
              </div>
              <div style="margin-top: 20px;">
                <label>昨日</label>
                <label style="font-weight: 550;padding-left: 10px;font-size: 22px;">{{userStatisticsTotal.startYesterday}}</label>
              </div>
            </div>
          </div>
        </div>
        <!-- 表格 -->
        <div class="table-box">
          <el-table size="mini" ref="goodsTable" id="goodsTable" :data="tableList"
            highlight-current-row @row-click="handleCurrentChange" width="100%" height="calc(100% - 150px)">
            <el-table-column type="index" width="50" label="编号" header-align="center" fixed></el-table-column>
            <el-table-column property="packageAlias" label="应用名称">
              <template slot-scope="scope">
                <label class="package_click" @click="detail(scope.row)">{{scope.row.packageAlias}}</label>
              </template>
            </el-table-column>
            <el-table-column property="newToday" label="今日新增用户" sortable></el-table-column>
            <el-table-column property="newYesterday" label="昨日新增用户" sortable></el-table-column>
            <el-table-column property="activeToday" label="今日活跃用户" sortable></el-table-column>
            <el-table-column property="activeYesterday" label="昨日活跃用户"></el-table-column>
            <el-table-column property="startToday" label="今日启动次数"></el-table-column>
            <el-table-column property="startYesterday" label="昨日启动次数"></el-table-column>
          </el-table>
        </div>
        <!-- 分页器 -->
        <div class="block">
          <el-pagination :current-page="pageNum" :page-sizes="[30, 50, 100]" :page-size="pageSize" :total="total"
            layout="total, sizes, prev, pager, next, jumper" @size-change="pageSizeChange"
            @current-change="pageNumChange">
          </el-pagination>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
  export default {
    name: 'UserStatistics',
    //组件创建
    created() {
      this.yesterday = this.getNextDate(new Date(), -1) + " 00:00:00"
      this.today = this.getNextDate(new Date(), 0) + " 00:00:00"

      this.getStatisticsHome()
      this.getStatisticsTotal()
    },
    data() {
      return {
        loading: false,
        userStatisticsTotal: {},
        tableList: [], //tab列表
        selected: null, //当前选中行
        pageNum: 1,
        pageSize: 30,
        total: 0,
      }
    },
    methods: {
      getNextDate(date, day) {
      　　var dd = new Date(date);
      　　dd.setDate(dd.getDate() + day);
      　　var y = dd.getFullYear();
      　　var m = dd.getMonth() + 1 < 10 ? "0" + (dd.getMonth() + 1) : dd.getMonth() + 1;
      　　var d = dd.getDate() < 10 ? "0" + dd.getDate() : dd.getDate();
      　　return y + "-" + m + "-" + d;
      },
      //列表查询
      getStatisticsTotal() {
        let param = {
          startDate: this.yesterday,
          endDate: this.today
        }
        delete param['time']
        let hostUrl = this.$service.publicUrl().UserStatisticsTotal
        this.axios({
          method: "GET",
          url: hostUrl,
          params: param
        }).then((response) => {
          this.loading = false
          if (response.data.status == 200) {
            this.userStatisticsTotal = response.data.data
          } else {
            this.$message.warning("warning : " + response.data.message);
          }
          this.selected = null
        }).catch((error) => {
          this.loading = false
          this.$message.error("查询失败，error : " + JSON.stringify(error));
        })
      },
      getStatisticsHome() {
        let param = {
          pageSize: this.pageSize,
          pageNum: this.pageNum,
          startDate: this.yesterday,
          endDate: this.today
        }
        let hostUrl = this.$service.publicUrl().UserStatisticsHome
        this.axios({
          method: "GET",
          url: hostUrl,
          params: param
        }).then((response) => {
          this.loading = false
          if (response.data.status == 200) {
            this.tableList = response.data.data.list
            this.total = response.data.data.total
          } else {
            this.$message.warning("warning : " + response.data.message);
          }
          this.selected = null
        }).catch((error) => {
          this.loading = false
          this.$message.error("查询失败，error : " + JSON.stringify(error));
        })
      },
      detail(row) {
        this.$router.push({
          name: 'user_statistics_detail',
          params: {
            packageName: row.packageName
          }
        })
      },
      //点击行
      handleCurrentChange(e) {
        this.selected = e
      },
      pageSizeChange(e) {
        this.pageSize = e
        this.search()
      },
      //pageNum变化
      pageNumChange(e) {
        this.pageNum = e;
        this.getDataList();
      },
    }
  }
</script>
<style lang="less" scoped>
  .drawer_inner {
    width: 95%;
    height: 100%;
    margin: auto;
  }

  .cont_table {
    height: 100%;
  }

  .table-box {
    height: calc(100% - 150px);
  }

  .cont_table /deep/ .el-icon-tickets {
    font-size: 20px;
    // margin-top: 4px;
  }

  .tips {
    font-size: 8px;
    margin-left: 30px;
    color: #878787;
  }

  .tips>span {
    color: orangered !important;
  }

  .content {
    display: flex;
    flex-direction: row;
    width: 100%;
    // justify-content: space-around;
  }

  .content_left {
    margin-right: 16px;
    padding-top: 21px;
    letter-spacing: 2px;
    width: 15%;
    height: calc(100% - 65px);
    background: #FFFFFF;
    overflow: auto
  }

  .content_right {
    width: 100%;
  }

  .cont_bar {
    display: flex;
  }

  /deep/.bar_left .el-form-item__content {
    max-width: 195px;
  }

  .cont_bars {
    width: 100%;
    display: flex;
    flex-direction: row;
    // align-items: center;
    justify-content: space-between;
  }
  .count_list {
    width: 100%;
    display: flex;
    // justify-content: space-between;
    flex-wrap: wrap;
  }
  .count_item {
    height: 150px;
    width: 250px;
    border-radius: 9px;
    font-size: 15px;
    text-align: center;
    color: #FFFFFF;
    padding-top: 20px;
    margin-bottom: 10px;
    margin-right: 30px;
    font-size: 20px;
    font-weight: 550;
  }
  .background1 {
    background: linear-gradient(90deg, #5171FD, #C97AFD);
  }

  .background2 {
    background: linear-gradient(90deg, #3EADF6, #737BFC);
  }

  .background3 {
    background: linear-gradient(90deg, #EA677C, #EF9B5F);
  }

  .background4 {
    background: linear-gradient(90deg, #42D79B, #A5E25F);
  }
  .package_click {
    color: #3b82fe;
    cursor: pointer;
  }
</style>
<style>
  .el-tree-node__content {
    height: 40px !important;
  }

  .el-table {
    overflow: visible !important;
  }
</style>
