<template>
  <div class="login">
    <div class="main">
      <h1 class="page_title">初代科技可视化数据平台</h1>
      <el-form class="login-form" ref="loginForm" :model="loginForm" :rules="loginRules" label-position="left"
        autocomplete="on">
        <h3 class="title">
          用户登录
        </h3>
        <el-form-item prop="username">
          <el-input v-model="loginForm.username" type="text" auto-complete="off" placeholder="账号"
            prefix-icon="el-icon-user">
            <i class="el-icon-user"></i>
          </el-input>
        </el-form-item>
        <el-form-item prop="password">
          <el-input v-model="loginForm.password" type="password" auto-complete="off" placeholder="密码"
            @keyup.enter.native="handleLogin" prefix-icon="el-icon-lock">
          </el-input>
        </el-form-item>
        <el-checkbox v-model="loginForm.rememberMe" style="margin:10px 0 40px 0;color: #fff;">记住密码</el-checkbox>
        <el-form-item style="width:100%;">
          <el-button :loading="loading" size="medium" type="primary" style="width:100%;"
            @click.native.prevent="handleLogin">
            <span v-if="!loading">登 录</span>
            <span v-else>登 录 中...</span>
          </el-button>
        </el-form-item>
      </el-form>
    </div>
  </div>
</template>

<script>
  import Config from '@/settings'
  import Cookies from 'js-cookie'
  	import Md5 from "js-md5";
  export default {
    name: 'login',
    data() {
      return {
        cookiePass: '',
        loginForm: {
          username: '',
          password: '',
          rememberMe: false
        },
        loginRules: {
          username: [{
            required: true,
            trigger: 'blur',
            message: '用户名不能为空'
          }],
          password: [{
            required: true,
            trigger: 'blur',
            message: '密码不能为空'
          }]
        },
        loading: false,
        redirect: undefined
      }
    },
    created() {
      this.$store.commit('clearVUEX')
      this.$store.commit('selectMenu', {
        'name': 'welcome'
      })
      this.getCookie()
      if (this.token != null && this.token.length > 0) {
        this.$router.push('/')
      }
    },
    computed: {
      token() { //调用username即可   {{username}}
        return sessionStorage.getItem("sessionToken")
      }
    },
    methods: {
      getCookie() {
        const username = Cookies.get('username')
        let password = Cookies.get('password')
        const rememberMe = Cookies.get('rememberMe')
        // 保存cookie里面的加密后的密码
        this.cookiePass = password === undefined ? '' : password
        password = password === undefined ? this.loginForm.password : password
        this.loginForm = {
          username: username === undefined ? this.loginForm.username : username,
          password: password,
          rememberMe: rememberMe === undefined ? false : Boolean(rememberMe)
        }
      },
      handleLogin() {
        let _this = this
        this.$refs.loginForm.validate(valid => {
          const user = {
            username: this.loginForm.username,
            password: this.loginForm.password,
            rememberMe: this.loginForm.rememberMe
          }
          if (user.password !== this.cookiePass) {
            user.password = Md5(user.password)
            // user.password = user.password
          }
          if (valid) {
            this.loading = true
            if (user.rememberMe) {
              Cookies.set('username', user.username, {
                expires: Config.passCookieExpires
              })
              Cookies.set('password', user.password, {
                expires: Config.passCookieExpires
              })
              Cookies.set('rememberMe', user.rememberMe, {
                expires: Config.passCookieExpires
              })
            } else {
              Cookies.remove('username')
              Cookies.remove('password')
              Cookies.remove('rememberMe')
            }
            let hostUrl = this.$service.publicUrl().UserLogin
            let requestData = {
              account: user.username,
              password: Md5(user.password)
            }
            _this.axios.post(hostUrl, requestData).then((response) => {
              console.log(response)
              if (response.data.status == 200) {
                let token = response.data.data.token
                let userInfo = {
                  account: response.data.data.account,
                  userName: response.data.data.userName,
                  userLevel: response.data.data.userLevel,
                }
                this.$store.commit('setToken', token);
                sessionStorage.setItem("sessionToken", token)
                sessionStorage.setItem("userInfo", JSON.stringify(userInfo))
                this.$router.replace("/")
                this.loading = false
              } else {
                this.$message.warning(response.data.message);
                this.loading = false
              }
            }).catch((error) => {
              this.$message.error("登录失败，error:" + JSON.stringify(error));
              this.loading = false
            })
          } else {
            return false
          }
        })
      }
    }
  }
</script>

<style lang="css"></style>
