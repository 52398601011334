/********************************** 商品分类 **********************************/
let labelFormatter=function(row, column){
	let returnVal=""
	switch(row.goodsTypeLabel) {
		case 1:
			returnVal='普通产品'
			break;
		case 2:
			returnVal="热门产品"
			break;
		case 3:
			returnVal="测量产品"
			break;
	} 
	return returnVal
}

let statusFormatter=function(row, column){
	let returnVal=""
	switch(row.goodsTypeStatus) {
		case 0:
			returnVal='已下架'
			break;
		case 1:
			returnVal="已上架"
			break;
	} 
	return returnVal
}

/********************************** 商品 **********************************/
let goodsLabelFormatter=function(row, column){
	let returnVal=""
	switch(row.goodsLabel) {
		case 1:
			returnVal='测量类产品'
			break;
		case 2:
			returnVal="普通产品"
			break;
	} 
	return returnVal
}
let goodsStatusFormatter=function(row, column){
	let returnVal=""
	switch(row.goodsStatus) {
		case 0:
			returnVal='已下架'
			break;
		case 1:
			returnVal="已上架"
			break;
	} 
	return returnVal
}

/********************************** 优惠活动 **********************************/
let actCodeFormatter=function(row, column){
	let returnVal=""
	switch(row.groupCode) {
		case 'coupon_receive_user_new':
			returnVal='新人领券活动'
			break;
		case 'coupon_receive_order':
			returnVal="测量下单返券"
			break;
	} 
	return returnVal
}
let actStatusFormatter=function(row, column){
	let returnVal=""
	switch(row.groupStatus) {
		case 0:
			returnVal='已下架'
			break;
		case 1:
			returnVal="已上架"
			break;
	} 
	return returnVal
}

/********************************** 优惠券  **********************************/
let grantTypeFormatter=function(row, column){
	let returnVal=""
	switch(row.grantType) {
		case 1:
			returnVal='主动领取'
			break;
		case 2:
			returnVal="后台发放"
			break;
	} 
	return returnVal
}
let validityTypeFormatter=function(row, column){
	let returnVal=""
	switch(row.validityType) {
		case 1:
			returnVal='固定'
			break;
		case 2:
			returnVal="领取后生效"
			break;
	} 
	return returnVal
}
let couponTypeFormatter=function(row, column){
	let returnVal=""
	switch(row.couponType) {
		case 1:
			returnVal='满减'
			break;
		case 2:
			returnVal="立减"
			break;
		case 3:
			returnVal="折扣"
			break;
		case 4:
			returnVal="随机"
			break;
	} 
	return returnVal
}
let couponStatusFormatter=function(row, column){
	let returnVal=""
	switch(row.couponStatus) {
		case 0:
			returnVal='已下架'
			break;
		case 1:
			returnVal="已上架"
			break;
	} 
	return returnVal
}
let userFormatter=function(row, column){
	let returnVal=""
	switch(row.userType) {
		case 0:
			returnVal='所有用户'
			break;
		case 1:
			returnVal="新用户"
			break;
		case 2:
			returnVal="老用户"
			break;
		case 3:
			returnVal="自定义用户"
			break;
	} 
	return returnVal
}
let recordStatusFormatter=function(row, column){
	let returnVal=""
	switch(row.couponStatus) {
		case 1:
			returnVal='已使用'
			break;
		case 2:
			returnVal="未使用"
			break;
		case 3:
			returnVal="已过期"
			break;
	} 
	return returnVal
}
/********************************** 订单  **********************************/
let orderStatusFormatter=function(row, column){
	let returnVal=""
	switch(row.orderStatus) {
		case 1:
			returnVal='未支付'
			break;
		case 4:
			returnVal="已完成"
			break;
	} 
	return returnVal
}
let orStatusAllFormatter=function(row, column){
	let returnVal=""
	switch(row.orderStatus) {
		case 0:
			returnVal="已取消"
			break;
		case 1:
			returnVal='待付款'
			break;
		case 2:
			returnVal='已付款-待派单'
			break;
		case 3:
			returnVal='进行中'
			break;
		case 4:
			returnVal="已完成"
			break;
	} 
	return returnVal
}
let payTypeFormatter=function(row, column){
	let returnVal=""
	switch(row.payType) {
		case 1:
			returnVal='余额支付'
			break;
		case 2:
			returnVal='微信支付'
			break;
	} 
	return returnVal
}
let evaluatedFormatter=function(row, column){
	let returnVal=""
	switch(row.evaluated) {
		case 0:
			returnVal='未评价'
			break;
		case 1:
			returnVal='已评价'
			break;
	} 
	return returnVal
}
let orderTypeFormatter=function(row, column){
	let returnVal=""
	switch(row.orderType) {
		case 1:
			returnVal='测量单'
			break;
		case 2:
			returnVal='产品单'
			break;
	} 
	return returnVal
}

export default{
	//商品分类
	labelFormatter:labelFormatter,
	statusFormatter:statusFormatter,
	//商品
	goodsLabelFormatter:goodsLabelFormatter,
	goodsStatusFormatter:goodsStatusFormatter,
	//优惠活动
	actCodeFormatter:actCodeFormatter,
	actStatusFormatter:actStatusFormatter,
	// 优惠券
	grantTypeFormatter:grantTypeFormatter,
	validityTypeFormatter:validityTypeFormatter,
	couponTypeFormatter:couponTypeFormatter,
	couponStatusFormatter:couponStatusFormatter,
	userFormatter:userFormatter,
	recordStatusFormatter:recordStatusFormatter,
	//订单
	orderStatusFormatter:orderStatusFormatter,
	orStatusAllFormatter:orStatusAllFormatter,
	evaluatedFormatter:evaluatedFormatter,
	orderTypeFormatter:orderTypeFormatter
}
