let menuData = function() {
  let menu = [
    {
      udid: 'advert', name: 'advert', label: '广告', icon: 'el-icon-film', path: '', sub: [
        { udid: 'ad_board_real_time', name: 'ad_board_real_time', label: '实时广告数据', icon: 'el-icon-menu', path: '/ad_board_real_time' },
        { udid: 'advert_config', name: 'advert_config', label: '广告配置', icon: 'el-icon-menu', path: '/advert_config' },
        { udid: 'ad_statistics', name: 'ad_statistics', label: '广告统计', icon: 'el-icon-menu', path: '/ad_statistics' }
      ]
    },
    {
      udid: 'data', name: 'data', label: '用户', icon: 'el-icon-user-solid', path: '', sub: [
        { udid: 'leave_words', name: 'leave_words', label: '用户留言', icon: 'el-icon-menu', path: '/leave_words' },
        { udid: 'user_statistics', name: 'user_statistics', label: '用户统计', icon: 'el-icon-menu', path: '/user_statistics' },
        { udid: 'user_statistics_channel_list', name: 'user_statistics_channel_list', label: '渠道统计', icon: 'el-icon-menu', path: '/user_statistics_channel_list' },
      ]
    },
    {
      udid: 'order', name: 'order', label: '订单', icon: 'el-icon-user-solid', path: '', sub: [
        { udid: 'order_statistics', name: 'order_statistics', label: '订单统计', icon: 'el-icon-s-order', path: '/order_statistics' },
        { udid: 'order_info', name: 'order_info', label: '订单列表', icon: 'el-icon-film', path: '/order_info' }
      ]
    },
    {
      udid: 'business', name: 'business', label: '业务', icon: 'el-icon-receiving', path: '', sub: [
        { udid: 'package_group', name: 'package_group', label: '应用分组', icon: 'el-icon-menu', path: '/package_group' },
        { udid: 'package_info', name: 'package_info', label: '应用包', icon: 'el-icon-menu', path: '/package_info' },
        { udid: 'app_share', name: 'app_share', label: 'App分享', icon: 'el-icon-menu', path: '/app_share' },
        { udid: 'payment_manage', name: 'payment_manage', label: '付费管理', icon: 'el-icon-menu', path: '/payment_manage' },
        { udid: 'config_pay', name: 'config_pay', label: '支付参数配置', icon: 'el-icon-menu', path: '/config_pay' },
      ]
    },
    {
      udid: 'docs_index', name: 'docs_index', label: '文档', icon: 'el-icon-document', path: '/docs_index', sub: [
        { udid: 'app_agreement', name: 'app_agreement', label: 'App协议', icon: 'el-icon-menu', path: '/app_agreement' },
        { udid: 'website_config', name: 'website_config', label: '官网配置', icon: 'el-icon-menu', path: '/website_config' },
        {udid: 'docs_manage', name: 'docs_manage', label: 'App配置文档', icon: 'el-icon-menu', path: '/docs_manage' },
      ]
    },
    {
      udid: 'costomer_index', name: 'costomer_index', label: '客服', icon: 'el-icon-document', path: '/costomer_index', sub: [
        { udid: 'chat', name: 'chat', label: '客服', icon: 'el-icon-menu', path: '/chat' },
      ]
    },
    {
      udid: 'system', name: 'system', label: '系统', icon: 'el-icon-s-tools', path: '', sub: [
        { udid: 'system_manage', name: 'system_manage', label: '系统配置', icon: 'el-icon-menu', path: '/system_manage' }
      ]
    },

  ]
  return menu
}
export default {
  menuData: menuData
}
