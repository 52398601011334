<template>
  <div id="dis_list" class="content" v-loading="loading" element-loading-text="加载中">
    <div class="content_left">
      <el-tree :data="packageTree" :props="defaultProps" @node-click="nodeClick"></el-tree>
    </div>
    <div class="content_right">
      <!-- 顶部操作栏 -->
      <div class="cont_bar">
        <div class="bar_left" style="width:80%;">
        </div>
        <div class="bar_right" style="width:20%;">
          <el-row>
            <el-tooltip content="新增" placement="top">
              <el-button type="primary" icon="el-icon-plus" size="mini" @click="openAdd()">新增</el-button>
            </el-tooltip>
            <!-- <el-tooltip content="详情" placement="top">
          <el-button type="primary" icon="el-icon-tickets" size="mini" @click="detail">详情</el-button>
        </el-tooltip> -->
          </el-row>
        </div>
      </div>
      <!-- 主内容区 -->
      <div class="cont_table">
        <!-- 表格 -->
        <el-table size="mini" ref="goodsTable" id="goodsTable" :data="tableList"
          highlight-current-row @row-click="handleCurrentChange" width="100%" height="calc(100% - 110px)">
          <el-table-column type="index" width="50" label="编号" header-align="center"></el-table-column>
          <el-table-column property="packageAlias" label="包名"></el-table-column>
          <el-table-column property="goodsType" label="产品类型">
            <template slot-scope="scope">
              <label :style="scope.row.goodsType != 1 ? 'color: #4382e6' : 'color: #faa2db'">
                {{scope.row.goodsType == 1 ? "VIP" : "单项"}}
              </label>
            </template>
          </el-table-column>
          <el-table-column property="vipTitle" label="产品标题"></el-table-column>
          <el-table-column property="vipDesc" label="产品描述">
          </el-table-column>
          <el-table-column property="vipDisPrice" label="产品价格"></el-table-column>
          <el-table-column property="vipSign" label="标签">
            <template slot-scope="scope">
              <label :style="scope.row.vipSign != 0 ? 'color: #e6a23c' : ''">
                {{scope.row.vipSign == 1 ? "热门" : scope.row.vipSign == 2 ? "推荐" : "普通"}}
              </label>
            </template>
          </el-table-column>
          <el-table-column property="arpuActive" label="有效期">
            <template slot-scope="scope">
              <el-row class="row-op-text"
                :style="scope.row.validityType == 1 ? '' : scope.row.validityType == 2 ? 'color: #66b1ff;': scope.row.validityType == 3 ? 'color: #67c23a' : 'color: #666666'">
                {{scope.row.validityType == 1 ? scope.row.validityValue + ' 天' : scope.row.validityType == 2 ? scope.row.validityValue + ' 月': scope.row.validityType == 3 ? '永久' : '无' }}
              </el-row>
            </template>
          </el-table-column>
          <el-table-column property="payRate" label="状态">
            <template slot-scope="scope">
              <el-row class="row-op-text">
                <el-switch :active-value="true" :inactive-value="false" v-model="scope.row.vipStatus" @change="changeVipStatus(scope.row)">
                </el-switch>
              </el-row>
            </template>
          </el-table-column>
          <el-table-column property="orderCount" label="操作">
            <template slot-scope="scope">
              <el-row class="row-op-text">
                <el-tooltip content="修改" placement="top">
                  <el-button type="text" icon="el-icon-edit" size="medium"
                    @click="openUpdate(scope.row)"></el-button>
                </el-tooltip>
                <el-tooltip content="删除" placement="top">
                  <el-button type="text" icon="el-icon-delete" size="medium"
                    @click="deleteVip(scope.row.id)"></el-button>
                </el-tooltip>
              </el-row>
            </template>
          </el-table-column>
        </el-table>
        <!-- 分页器 -->
        <div class="block">
          <el-pagination :current-page="pageNum" :page-sizes="[30, 50, 100]" :page-size="pageSize" :total="total"
            layout="total, sizes, prev, pager, next, jumper" @size-change="pageSizeChange"
            @current-change="pageNumChange">
          </el-pagination>
        </div>
      </div>
    </div>
    <el-dialog :title="formType == 'add' ? 'VIP商品新增' : 'VIP商品修改'" :visible.sync="vipFormVisible" width="550px"
    	@close="initDialog()">
    	<el-form :model="vipForm" ref="addForm" :rules="vipAddRules" class="add_form">
    		<el-form-item label="应用包 :" label-width="100px" prop="packageName">
    			<el-select v-model="vipForm.packageName" placeholder="请选择应用包" :disabled="this.formType == 'edit'">
    				<el-option :label="item.packageAlias" :value="item.packageName" v-for="(item, index) in packageList"
    					:key="index"></el-option>
    			</el-select>
    		</el-form-item>
    		<el-form-item label="Vip标题 :" label-width="100px" prop="vipTitle">
    			<el-input v-model="vipForm.vipTitle" autocomplete="off"></el-input>
    		</el-form-item>
    		<el-form-item label="Vip描述 :" label-width="100px" prop="vipDesc">
    			<el-input v-model="vipForm.vipDesc" autocomplete="off"></el-input>
    		</el-form-item>
    		<el-row>
    			<el-col :span="12">
    				<el-form-item label="原价 :" label-width="100px" prop="vipOrgPrice">
    					<el-input v-model="vipForm.vipOrgPrice" autocomplete="off" type="number"></el-input>
    				</el-form-item>
    			</el-col>
    			<el-col :span="12">
    				<el-form-item label="折扣价 :" label-width="100px" prop="vipDisPrice">
    					<el-input v-model="vipForm.vipDisPrice" autocomplete="off" type="number"></el-input>
    				</el-form-item>
    			</el-col>
    		</el-row>
    		<el-row>
    			<el-col :span="12">
    				<el-form-item label="排序 :" label-width="100px" prop="vipOrder">
    					<el-input v-model="vipForm.vipOrder" autocomplete="off" type="number"></el-input>
    				</el-form-item>
    			</el-col>
    		</el-row>
        <el-form-item label="产品类型 :" label-width="100px" prop="goodsType">
        	<el-radio-group v-model="vipForm.goodsType">
        		<el-radio :label="1">VIP</el-radio>
        		<el-radio :label="2">单项</el-radio>
        	</el-radio-group>
        </el-form-item>
    		<el-row v-show="vipForm.goodsType == 1">
    			<el-col :span="14">
    				<el-form-item label="VIP有效期方式 :" label-width="120px" prop="validityType">
    					<el-select v-model="vipForm.validityType" placeholder="请选择">
    						<el-option :key="item.value" :label="item.label" :value="item.value"
    							v-for="item in validityTypeList"></el-option>
    					</el-select>
    				</el-form-item>
    			</el-col>
    			<el-col :span="10" v-if="vipForm.validityType != '3'">
    				<el-form-item label="VIP有效期 :" label-width="100px" prop="validityValue">
    					<el-input v-model="vipForm.validityValue" autocomplete="off" type="number"></el-input>
    				</el-form-item>
    			</el-col>
    		</el-row>
    		<el-form-item label="产品标签 :" label-width="100px" prop="vipSign">
    			<el-radio-group v-model="vipForm.vipSign">
    				<el-radio :key="item.value" :label="item.value" v-for="item in goodsSignList">{{item.label}}
    				</el-radio>
    			</el-radio-group>
    		</el-form-item>
    	</el-form>
    	<div slot="footer" class="dialog-footer">
    		<el-button @click="vipFormVisible = false">取 消</el-button>
    		<el-button type="primary" @click="confirm()">确 定</el-button>
    	</div>
    </el-dialog>
  </div>
</template>
<script>
  export default {
    name: 'PaymentManage',
    //组件创建
    created() {
      this.getPackageList()
      this.getPackageTree()
      this.getDataList()
    },
    updated() {
      this.$nextTick(() => {
        this.$refs.goodsTable.doLayout();
      })
    },
    data() {
      return {
        defaultProps: {
          children: 'children',
          label: 'label'
        },
        loading: false,
        tableList: [], //tab列表
        searchData: {},
        packageTree: [],
        packageList: [],
        selected: null, //当前选中行
        //行详情抽屉
        rowDrawer: false,
        rowDetail: {},
        pageNum: 1,
        pageSize: 30,
        total: 0,
        formType: "add",
        vipFormVisible: false,
        validityTypeList: [{label:'天',value:1},{label:'月',value:2},{label:'永久',value:3}],
        goodsSignList: [{label:'普通',value:0},{label:'热门',value:1},{label:'推荐',value:2}],
        vipForm: {
          goodsType: 1,
          vipSign: 0
        },
        vipAddRules: {
        	packageName: [{ required: true, message: '应用名称不能为空' }],
        	vipTitle: [{ required: true, message: 'vip名称不能为空' }],
        	vipOrgPrice: [{ required: true, message: '价格不能为空' }],
        	vipDisPrice: [{ required: true, message: '价格不能为空' }],
        	goodsType: [{ required: true, message: '产品类型不能为空' }],
      }
      }
    },
    methods: {
      getPackageList() {
      	let hostUrl=this.$service.publicUrl().PackageListAll
      	this.axios({
      		method: "GET",
      		url: hostUrl,
      	}).then((response)=>{
      		this.loading=false
      	    if(response.data.status==200){
      			  this.packageList=response.data.data
      	    }else{
      			  this.$message.warning("warning : "+response.data.message);
      		}
      	}).catch((error)=>{
      		this.loading=false
      		this.$message.error("查询失败，error : "+JSON.stringify(error));
      	})
      },
      getPackageTree() {
        let hostUrl = this.$service.publicUrl().PackageTree
        this.axios({
          method: "GET",
          url: hostUrl,
        }).then((response) => {
          this.loading = false
          if (response.data.status == 200) {
            this.packageTree = response.data.data
            this.packageTree.unshift({
              label: '全部',
              value: ''
            })
          } else {
            this.$message.warning("warning : " + response.data.message);
          }
          this.selected = null
        }).catch((error) => {
          this.loading = false
          this.$message.error("查询失败，error : " + JSON.stringify(error));
        })
      },
      openAdd () {
        this.formType = "add"
        this.vipFormVisible = true
        this.initDialog()
      },
      openUpdate (row) {
        this.formType = "edit"
        this.vipFormVisible = true
        this.$nextTick(() => {
            this.vipForm = {...row}
        });
      },
      confirm () {
        this.$refs["addForm"].validate((valid) => {
        	if (valid) {
        		let param = {
        			...this.vipForm,
        		}
        		let hostUrl
        		let desc
            let method
        		if (this.formType == 'add') {
        			hostUrl = this.$service.publicUrl().VipAdd
        			desc = '新增'
              method = "POST"
        		} else {
        			hostUrl = this.$service.publicUrl().VipUpdate
        			desc = '修改'
              method = "PUT"
        		}
            this.axios({
              method: method,
              url: hostUrl,
              data: param
            }).then((response) => {
        			this.loading = false
        			if (response.data.status == 200) {
        				this.$message.success(desc + "成功");
                this.vipFormVisible = false
                this.initDialog()
        				this.getDataList()
        			} else {
        				this.$message.warning("warning : " + response.data.msg);
        			}
        		}).catch((error) => {
        			this.loading = false
        			this.$message.error(desc + "失败，error : " + JSON.stringify(error));
        		})
        	}
        })
      },
      deleteVip (id) {
        this.$confirm('确认删除本条数据吗?', '提示', {
        	confirmButtonText: '确定',
        	cancelButtonText: '取消',
        	type: 'warning'
        }).then(() => {
        	let param = {
        		id: id,
        	}
        	this.axios({
        	  method: "DELETE",
        	  url: this.$service.publicUrl().VipDelete,
        	  params: param
        	}).then((response) => {
        		if (response.data.status == 200) {
        			this.getDataList()
        		} else {
        			this.$message.warning("warning : " + response.data.msg);
        		}
        	}).catch((error) => {
        		this.loading = false
        		this.$message.error("删除失败，error : " + JSON.stringify(error));
        	})
        }).catch((error) => {
        	this.$message.error("删除失败，error : " + JSON.stringify(error));
        });
      },
      changeVipStatus(data) {
      	let type = data.vipStatus ? '启用' : '禁用'
      	this.$confirm('确认' + type + '?', '提示', {
      		confirmButtonText: '确定',
      		cancelButtonText: '取消',
      		type: 'warning'
      	}).then(() => {
      		let hostUrl = this.$service.publicUrl().VipUpdate
      		this.axios({
      		  method: "PUT",
      		  url: hostUrl,
      		  data: data
      		}).then((response) => {
      			if (response.data.status == 200) {
      				this.getDataList()
      			} else {
      				this.$message.warning("warning : " + response.data.msg);
      			}
      		}).catch((error) => {
      			this.loading = false
      			this.$message.error("更新失败，error : " + JSON.stringify(error));
      		})
      	}).catch(() => {
      		data.vipStatus = !data.vipStatus
      	});
      },
      search() {
        this.pageNum = 1
        this.getDataList()
      },
      nodeClick(data) {
        if (data.value != undefined) {
          if (data.value == '') {
            this.pageNum = 1
            this.searchData = {
              startDate: this.yesterday,
              endDate: this.yesterday,
            }

          } else {
            this.pageNum = 1
            this.searchData = {}
            this.searchData.packageName = data.value
          }
        } else {
          this.searchData.packageName = ''
          this.searchData.packageSeries = data.label
        }
        this.getDataList()
      },
      //列表查询
      getDataList() {
        let param = {
          pageNum: this.pageNum,
          pageSize: this.pageSize,
          ...this.searchData,
        }
        delete param['time']
        let hostUrl = this.$service.publicUrl().VipList
        this.axios({
          method: "GET",
          url: hostUrl,
          params: param
        }).then((response) => {
          this.loading = false
          if (response.data.status == 200) {
            this.tableList = response.data.data.list
            this.total = response.data.data.total
          } else {
            this.$message.warning("warning : " + response.data.message);
          }
          this.selected = null
        }).catch((error) => {
          this.loading = false
          this.$message.error("查询失败，error : " + JSON.stringify(error));
        })
      },
      //点击行
      handleCurrentChange(e) {
        this.selected = e
      },
      pageSizeChange(e) {
        this.pageSize = e
        this.search()
      },
      //pageNum变化
      pageNumChange(e) {
        this.pageNum = e;
        this.getDataList();
      },
      initDialog () {
        this.$refs["addForm"].resetFields()
      }
    }
  }
</script>
<style lang="less" scoped>
  .drawer_inner {
    width: 95%;
    height: 100%;
    margin: auto;
  }

  .cont_table /deep/ .el-icon-tickets {
    font-size: 20px;
    margin-top: 4px;
  }

  .tips {
    font-size: 8px;
    margin-left: 30px;
    color: #878787;
  }

  .tips>span {
    color: orangered !important;
  }

  .content {
    display: flex;
    flex-direction: row;
    width: 100%;
    // justify-content: space-around;
  }

  .content_left {
    margin-right: 16px;
    padding-top: 21px;
    letter-spacing: 2px;
    width: 15%;
    height: calc(100% - 65px);
    background: #FFFFFF;
    overflow: auto
  }

  .content_right {
    width: 85%;
  }

  .cont_bar {
    display: flex;
  }
</style>
<style>
  .el-tree-node__content {
    height: 40px !important;
  }
</style>
