<template>
<div id="dis_list" class="content" v-loading="loading" element-loading-text="加载中">
	<!-- 顶部操作栏 -->
	<div class="cont_bar">
		<div class="bar_left">

		</div>
    <div class="bar_right">
			<el-row>
				<el-tooltip content="新增"  placement="top">
					<el-button type="success" icon="el-icon-plus" size="mini" @click="addFormVisible=true"></el-button>
				</el-tooltip>
				<el-tooltip content="删除"  placement="top">
					<el-button type="warning" icon="el-icon-delete" size="mini" @click="openDetele()"></el-button>
				</el-tooltip>
			</el-row>
		</div>
	</div>
	<!-- 主内容区 -->
	<div class="cont_table">
		<!-- 表格 -->
		<el-table ref="goodsTable" id="goodsTable" :data="tableList" highlight-current-row @row-click="handleCurrentChange" width="100%" height="calc(100% - 70px)">
		    <el-table-column type="index" width="500" label="编号"></el-table-column>
		    <el-table-column property="packageGroupName" label="包系列名称" min-width="500"></el-table-column>
		</el-table>
		<!-- 分页器 -->
		<!-- <div class="block">
			<el-pagination :current-page="pageNum" :page-sizes="[30, 50, 100]" :page-size="pageSize" :total="total"
				layout="total, sizes, prev, pager, next, jumper" @size-change="pageSizeChange" @current-change="pageNumChange">
			</el-pagination>
		</div> -->
	</div>
  <el-dialog title="新增应用包系列" :visible.sync="addFormVisible" width="500px" @close="initDialog()">
	  <el-form :model="addForm" ref="addForm" :rules="addRules" class="add_form">
	    <el-form-item label="系列名称 :" label-width="90px"  prop="packageGroupName">
	      <el-input v-model="addForm.packageGroupName" autocomplete="off" maxlength="20"></el-input>
	    </el-form-item>
	  </el-form>
	  <div slot="footer" class="dialog-footer">
	    <el-button @click="addFormVisible = false">取 消</el-button>
	    <el-button type="primary" @click="addSeries()">确 定</el-button>
	  </div>
	</el-dialog>
</div>
</template>
<script>
export default {
	name: 'PackageSeriesManage',
	//组件创建
	created() {
		this.search()
	},
	data() {
		return{
			loading:false,
      addFormVisible: false,
			tableList:[],//tab列表
			selected:null,//当前选中行
			//行详情抽屉
			rowDrawer:false,
			rowDetail:{},
      addForm: {
        packageGroupName: '',
      },
      addRules: {
				packageGroupName: [
					{ required: true, message: '名称不能为空'},
					{ min: 2, max: 10, message: '长度在 2 到 10 个字符', trigger: 'blur' }
				],
			},
		}
	},
	methods:{
		//列表查询
		search(){
			let hostUrl=this.$service.publicUrl().PackageSeriesList
			this.axios({
				method: "GET",
				url: hostUrl,
			}).then((response)=>{
				this.loading=false
			    if(response.data.status==200){
					  this.tableList=response.data.data
			    }else{
					  this.$message.warning("warning : "+response.data.message);
				}
				this.selected=null
			}).catch((error)=>{
				this.loading=false
				this.$message.error("查询失败，error : "+JSON.stringify(error));
			})
		},
    addSeries () {
      this.$refs["addForm"].validate((valid) => {
        if (valid) {
          let hostUrl=this.$service.publicUrl().PackageSeriesAdd
          this.axios({
            method: "POST",
            url: hostUrl,
            data: { packageGroupName: this.addForm.packageGroupName}
          }).then((response)=>{
            this.loading=false
            if(response.data.status==200){
              this.$message.success("保存成功");
              this.search()
            }else{
              this.$message.warning("warning : "+response.data.message);
            }
            this.addFormVisible = false
          }).catch((error)=>{
            this.loading=false
            this.$message.error("保存失败，error : "+JSON.stringify(error));
          })
        }
      })
    },
    openDetele(){
			if(this.selected==null){
				this.$message.warning("请先选中一条记录！");
				return
			}
			this.$confirm('确认删除该记录?', '提示', {
				confirmButtonText: '确定',
				cancelButtonText: '取消',
				type: 'warning'
			}).then(() => {
				this.deleteSeries()
			}).catch(() => {
			});
		},
    deleteSeries() {
			let hostUrl=this.$service.publicUrl().PackageSeriesDelete
			this.axios({
				method: "DELETE",
				url: hostUrl,
				params: { id: this.selected.id }
			}).then((response)=>{
        console.log(response)
				this.loading=false
        if(response.data.status==200){
          this.$message.success("删除成功");
          this.search()
        }else{
          this.$message.warning("warning : "+response.data.message);
        }
        this.selected = null
			}).catch((error)=>{
				this.loading=false
				this.$message.error("删除失败，error : "+JSON.stringify(error));
			})
    },
		//点击行
		handleCurrentChange(e){
			this.selected=e
		},
    initDialog(){
			this.$refs["addForm"].resetFields()
		},
	}
}
</script>
<style lang="less" scoped>
.drawer_inner{
	width: 95%;
	height: 100%;
	margin: auto;
}
.cont_table /deep/ .el-icon-tickets{
	font-size: 20px;
	margin-top: 4px;
}
.tips{
	font-size: 8px;
	margin-left: 30px;
	color: #878787;
}
.tips>span{
	color: orangered !important;
}
</style>
