<template>
<div id="dis_list" class="content" v-loading="loading" element-loading-text="加载中">
	<!-- 顶部操作栏 -->
	<div class="cont_bar">
		<div class="bar_left">
      <el-form :inline="true" :model="searchData" class="demo-form-inline" size="small">
			  <el-form-item label="应用名称：">
			    <el-select v-model="searchData.packageName" placeholder="应用名称" :filterable="true" >
            <el-option label="全部" value=""></el-option>
			      <el-option v-for="item in packageList" :key="item.id" :label="item.name" :value="item.packageAlias"></el-option>
			    </el-select>
			  </el-form-item>
        <el-form-item>
				<el-tooltip content="查询"  placement="top">
					<el-button type="primary" @click="search1()" icon="el-icon-search"></el-button>
				</el-tooltip>
			  </el-form-item>
			</el-form>
		</div>
    <div class="bar_right">
			<el-row>
				<el-tooltip content="新增"  placement="top">
					<el-button type="success" icon="el-icon-plus" size="mini" @click="openAdd"></el-button>
				</el-tooltip>
        <el-tooltip content="编辑"  placement="top">
					<el-button type="info" icon="el-icon-edit" size="mini" @click="openUpdate"></el-button>
				</el-tooltip>
				<el-tooltip content="删除"  placement="top">
					<el-button type="warning" icon="el-icon-delete" size="mini" @click="openDetele()"></el-button>
				</el-tooltip>
			</el-row>
		</div>
	</div>
	<!-- 主内容区 -->
	<div class="cont_table">
		<!-- 表格 -->
		<el-table ref="goodsTable" id="goodsTable" :data="tableList" highlight-current-row @row-click="handleCurrentChange" width="100%" height="calc(100% - 70px)">
		    <el-table-column prop="id" width="100" label="编号"></el-table-column>
		    <el-table-column property="packageAlias" label="应用名称" min-width="100"></el-table-column>
        <el-table-column property="shareChannels" label="分享渠道" min-width="300"></el-table-column>
        <el-table-column property="shareDesc" label="分享标题" min-width="100"></el-table-column>
        <el-table-column property="shareTitle" label="分享描述" min-width="300"></el-table-column>
        <el-table-column property="shareIcon" label="分享图标" min-width="80">
          <template slot-scope="scope">
		    		<el-popover placement="top-start" trigger="hover">
		    			<a :href="scope.row.shareIcon" target="_blank" title="查看最大化图片"><img :src="scope.row.shareIcon" style="width: 280px;height: 200px" /></a>
		    			<img slot="reference" :src="scope.row.shareIcon" style="height: 50px; cursor:pointer" />
		    		</el-popover>
		    	</template>
        </el-table-column>
        <el-table-column property="shareUrl" label="分享链接" min-width="300"></el-table-column>
		</el-table>
		<!-- 分页器 -->
		<div class="block">
			<el-pagination :current-page="pageNum" :page-sizes="[30, 50, 100]" :page-size="pageSize" :total="total"
				layout="total, sizes, prev, pager, next, jumper" @size-change="pageSizeChange" @current-change="pageNumChange">
			</el-pagination>
		</div>
	</div>
  <el-dialog title="新增分享信息" :visible.sync="addFormVisible" width="800px" @close="initDialog()">
	  <el-form :model="addForm" ref="addForm" :rules="addRules" class="add_form">
	    <el-form-item label="应用包名 :" label-width="100px"  prop="packageName">
	      <el-select v-model="addForm.packageName" placeholder="请选择应用包">
	        <el-option :label="item.packageAlias" :value="item.packageName" v-for="(item, index) in packageList" :key="index"></el-option>
	      </el-select>
	    </el-form-item>
	    <el-form-item label="分享渠道 :" label-width="100px"  prop="shareChannels">
	      <el-checkbox v-model="checkAll" @change="handleCheckAllChange">全选</el-checkbox>
	      <div style="margin: 15px 0;"></div>
	      <el-checkbox-group v-model="channelArray" @change="handleCheckedChannelChange">
	        <el-checkbox v-for="item in channelList" :label="item" :key="item">{{item}}</el-checkbox>
	      </el-checkbox-group>
	    </el-form-item>
	    <el-form-item label="分享标题 :" label-width="100px"  prop="shareTitle">
	      <el-input v-model="addForm.shareTitle" autocomplete="off" placeholder="请输入分享标题"></el-input>
	    </el-form-item>
	    <el-form-item label="分享描述 :" label-width="100px"  prop="shareDesc">
	      <el-input v-model="addForm.shareDesc" autocomplete="off" type="textarea" placeholder="请输入分享描述"></el-input>
	    </el-form-item>
	    <el-form-item label="分享链接 :" label-width="100px"  prop="shareUrl">
	      <el-input v-model="addForm.shareUrl" autocomplete="off" placeholder="请输入分享链接"></el-input>
	    </el-form-item>
	    <el-form-item label="分享图标 :" label-width="100px"  prop="icon">
	      <div class="out_side">
	    			<el-input v-model="addForm.shareIcon" v-if="false"></el-input>
	    			<el-upload class="avatar-uploader"
	    			  action="thumb/justforaction"
	    			  :show-file-list="false"
	    			  :before-upload="beforeThumbUp"
	    			  accept=".jpg,.png,.jpeg,JPG,JPEG">
	    			  <el-image class="avatar" style="width: 200px;" v-if="addForm.shareIcon" :src="addForm.shareIcon" fit="contain"></el-image>
	    			  <i v-else class="el-icon-plus avatar-uploader-icon"></i>
	    			</el-upload>
	    		</div>
	    </el-form-item>
	  </el-form>
	  <div slot="footer" class="dialog-footer">
	    <el-button @click="addFormVisible = false">取 消</el-button>
	    <el-button type="primary" @click="addShare()">确 定</el-button>
	  </div>
	</el-dialog>
  <el-dialog title="修改分享信息" :visible.sync="updateFormVisible" width="800px" @close="initDialog()">
	  <el-form :model="addForm" ref="addForm" :rules="addRules" class="add_form">
	     <el-form-item label="应用包名 :" label-width="100px"  prop="packageName">
        <el-select v-model="addForm.packageName" placeholder="请选择应用包">
          <el-option :label="item.packageAlias" :value="item.packageName" v-for="(item, index) in packageList" :key="index"></el-option>
        </el-select>
	    </el-form-item>
      <el-form-item label="分享渠道 :" label-width="100px"  prop="shareChannels">
        <el-checkbox v-model="checkAll" @change="handleCheckAllChange">全选</el-checkbox>
        <div style="margin: 15px 0;"></div>
        <el-checkbox-group v-model="channelArray" @change="handleCheckedChannelChange">
          <el-checkbox v-for="item in channelList" :label="item" :key="item">{{item}}</el-checkbox>
        </el-checkbox-group>
	    </el-form-item>
      <el-form-item label="分享标题 :" label-width="100px"  prop="shareTitle">
	      <el-input v-model="addForm.shareTitle" autocomplete="off" placeholder="请输入分享标题"></el-input>
	    </el-form-item>
      <el-form-item label="分享描述 :" label-width="100px"  prop="shareDesc">
	      <el-input v-model="addForm.shareDesc" autocomplete="off" type="textarea" placeholder="请输入分享描述"></el-input>
	    </el-form-item>
      <el-form-item label="分享链接 :" label-width="100px"  prop="shareUrl">
        <el-input v-model="addForm.shareUrl" autocomplete="off" placeholder="请输入分享链接"></el-input>
	    </el-form-item>
      <el-form-item label="分享图标 :" label-width="100px"  prop="icon">
        <div class="out_side">
						<el-input v-model="addForm.shareIcon" v-if="false"></el-input>
						<el-upload class="avatar-uploader"
						  action="thumb/justforaction"
						  :show-file-list="false"
						  :before-upload="beforeThumbUp"
						  accept=".jpg,.png,.jpeg,JPG,JPEG">
						  <el-image class="avatar" style="width: 200px;" v-if="addForm.shareIcon" :src="addForm.shareIcon" fit="contain"></el-image>
						  <i v-else class="el-icon-plus avatar-uploader-icon"></i>
						</el-upload>
					</div>
	    </el-form-item>
	  </el-form>
	  <div slot="footer" class="dialog-footer">
	    <el-button @click="updateFormVisible = false">取 消</el-button>
	    <el-button type="primary" @click="updatePackage()">确 定</el-button>
	  </div>
	</el-dialog>
</div>
</template>
<script>
export default {
	name: 'ShareManage',
	//组件创建
	created() {
		this.search(),
    this.getPackageList()
    // this.getChannelList()
	},
	data() {
		return{
			loading:false,
      updateFormVisible: false,
      addFormVisible: false,
      checkAll:false,
			tableList:[],//tab列表
      searchData: {},
      packageList: [],
      channelList: [
        "huawei", "xiaomi", "oppo", "vivo", "honor", "samsung", "yingyongbao", "baidu", "wandoujia"
      ],
			selected:null,//当前选中行
			//行详情抽屉
			rowDrawer:false,
			rowDetail:{},
      pageNum: 1,
      pageSize: 30,
      total: 0,
      channelArray: [],
      addForm: {

      },
      addRules: {
				packageName: [
					{ required: true, message: '应用名称不能为空'}
				],
        shareChannels: [
          { required: true, message: '分享渠道不能为空'}
				],
        shareTitle: [
          { required: true, message: '分享标题不能为空'}
				],
        shareDesc: [
          { required: true, message: '分享描述不能为空'}
				],
        shareUrl: [
          { required: true, message: '分享链接不能为空'}
				],
        shareIcon: [
          { required: true, message: '分享图片不能为空'}
        ]
			},
		}
	},
	methods:{
    getPackageList() {
			let hostUrl=this.$service.publicUrl().PackageListAll
			this.axios({
				method: "GET",
				url: hostUrl,
			}).then((response)=>{
				this.loading=false
			    if(response.data.status==200){
					  this.packageList=response.data.data
			    }else{
					  this.$message.warning("warning : "+response.data.message);
				}
			}).catch((error)=>{
				this.loading=false
				this.$message.error("查询失败，error : "+JSON.stringify(error));
			})
    },
    search1 () {
      this.pageNum = 1
      this.search()
    },
		//列表查询
		search(){
			let param = {
        pageNum: this.pageNum,
        pageSize: this.pageSize,
        ...this.searchData,
      }
			let hostUrl=this.$service.publicUrl().ShareList
			this.axios({
				method: "GET",
				url: hostUrl,
				params: param
			}).then((response)=>{
				this.loading=false
			    if(response.data.status==200){
					  this.tableList=response.data.data.list
            this.total = response.data.data.total
			    }else{
					  this.$message.warning("warning : "+response.data.message);
				}
				this.selected=null
			}).catch((error)=>{
				this.loading=false
				this.$message.error("查询失败，error : "+JSON.stringify(error));
			})
		},
    openAdd() {
      this.addForm = {},
      this.addFormVisible = true
    },
    //略缩图上传
		beforeThumbUp(file){
			let fd = new FormData();
			fd.append('file',file);//传文件
			let hostUrl=this.$service.publicUrl().ShareUploadFile
			this.axios.post(hostUrl,fd).then((response)=>{
			    if(response.data.status==200){
			       this.addForm.shareIcon=response.data.data
				   this.$forceUpdate()
			    }
			}).catch((error)=>{
				this.$message.error("略缩图上传失败，error:"+JSON.stringify(error));
			})
		},
    addShare () {
      this.$refs["addForm"].validate((valid) => {
        if (valid) {
          let param = {
            ...this.addForm,
          }
          let hostUrl=this.$service.publicUrl().ShareAdd
          this.axios({
            method: "POST",
            url: hostUrl,
            data: param
          }).then((response)=>{
            this.loading=false
            if(response.data.status==200){
              this.$message.success("保存成功");
              this.search()
            }else{
              this.$message.warning("warning : "+response.data.message);
            }
            this.addFormVisible = false
          }).catch((error)=>{
            this.loading=false
            this.$message.error("保存失败，error : "+JSON.stringify(error));
          })
        }
      })
    },
    openUpdate() {
      if (this.selected == null) {
         this.$message.warning("请选择一条记录");
         return
      }
      this.addForm = this.selected
      this.channelArray = this.addForm.shareChannels.split(',')
      this.updateFormVisible = true
    },
    updatePackage () {
      this.$refs["addForm"].validate((valid) => {
        if (valid) {
          let param = {
            ...this.addForm,
          }
          let hostUrl=this.$service.publicUrl().ShareUpdate
          this.axios({
            method: "PUT",
            url: hostUrl,
            data: param
          }).then((response)=>{
            this.loading=false
            if(response.data.status==200){
              this.$message.success("修改成功");
              this.search()
            }else{
              this.$message.warning("warning : "+response.data.message);
            }
            this.updateFormVisible = false
          }).catch((error)=>{
            this.loading=false
            this.$message.error("保存失败，error : "+JSON.stringify(error));
          })
        }
      })
    },
    openDetele(){
			if(this.selected==null){
				this.$message.warning("请先选中一条记录！");
				return
			}
			this.$confirm('确认删除该记录?', '提示', {
				confirmButtonText: '确定',
				cancelButtonText: '取消',
				type: 'warning'
			}).then(() => {
				this.deleteChannel()
			}).catch(() => {
			});
		},
    deleteChannel() {
       let param = {
        id: this.selected.id,
      }
			let hostUrl=this.$service.publicUrl().ShareDelete
			this.axios({
				method: "DELETE",
				url: hostUrl,
				params: param
			}).then((response)=>{
        console.log(response)
				this.loading=false
        if(response.data.status==200){
          this.$message.success("删除成功");
          this.search()
        }else{
          this.$message.warning("warning : "+response.data.message);
        }
        this.selected = null
			}).catch((error)=>{
				this.loading=false
				this.$message.error("删除失败，error : "+JSON.stringify(error));
			})
    },
    handleCheckAllChange(val) {
      this.channelArray = val ? this.channelList : []
      this.addForm.shareChannels = this.channelArray.join(',')
    },
    handleCheckedChannelChange(value) {
      this.channelArray = value
      if (value.length > 0){
        this.addForm.shareChannels = value.join(',')
      }
    },
		//点击行
		handleCurrentChange(e){
			this.selected=e
		},
     initDialog(){
			this.$refs["addForm"].resetFields()
		},
    pageSizeChange(e){
			this.pageSize=e
			this.search()
		},
		//pageNum变化
		pageNumChange(e){
			this.pageNum=e
			this.search();
		},
	}
}
</script>
<style lang="less" scoped>
.drawer_inner{
	width: 95%;
	height: 100%;
	margin: auto;
}
.cont_table /deep/ .el-icon-tickets{
	font-size: 20px;
	margin-top: 4px;
}
.tips{
	font-size: 8px;
	margin-left: 30px;
	color: #878787;
}
.tips>span{
	color: orangered !important;
}
</style>
