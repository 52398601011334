<template>
  <div id="dis_list" class="content" v-loading="loading" element-loading-text="加载中">
    <div class="content_left">
      <el-tree :data="packageTree" :props="defaultProps" @node-click="nodeClick"></el-tree>
    </div>
    <div class="content_right">
      <!-- 顶部操作栏 -->
      <div class="cont_bar">
        <div class="bar_left">

        </div>
        <div class="bar_right" style="width: 100%;">
          <el-row>
            <el-tooltip content="批量控制" placement="top">
              <el-button type="primary" :disabled="batchDisabled" icon="el-icon-s-operation" size="mini" @click="handleChannelDialog('batch')">批量控制</el-button>
            </el-tooltip>
            <el-tooltip content="渠道控制" placement="top">
              <el-button type="primary" icon="el-icon-share" size="mini" @click="handleChannelDialog('single')">渠道控制</el-button>
            </el-tooltip>
            <el-tooltip content="广告新增" placement="top">
              <el-button type="success" icon="el-icon-plus" size="mini" @click="handleAdvertDialog('add')">广告新增
              </el-button>
            </el-tooltip>
            <el-tooltip content="广告编辑" placement="top">
              <el-button type="info" icon="el-icon-edit" size="mini" @click="handleAdvertDialog('edit')">广告编辑
              </el-button>
            </el-tooltip>
            <el-tooltip content="广告删除" placement="top">
              <el-button type="warning" icon="el-icon-delete" size="mini" @click="advertDelete">广告删除</el-button>
            </el-tooltip>
          </el-row>
        </div>
      </div>
      <!-- 主内容区 -->
      <div class="cont_table">
        <!-- 表格 -->
        <el-table border size='small' ref="goodsTable" id="goodsTable" :data="tableList" highlight-current-row
          @row-click="handleCurrentChange" width="100%" height="calc(100% - 110px)" @selection-change="changeSelectedAdvert">
          <el-table-column type="selection" width="55"> </el-table-column>
          <el-table-column property="packageAlias" label="包名"></el-table-column>
          <el-table-column property="advertName" label="广告名称" width="150"></el-table-column>
          <!-- <el-table-column property="advertFirm" label="广告商" width="100">
            <template slot-scope="scope">
              <el-row class="row-op-text">
                {{scope.row.advertFirm == "CSJ" ? '穿山甲' : '其他'}}
              </el-row>
              <el-row>{{scope.row.advertAppId}}</el-row>
            </template>
          </el-table-column>
          <el-table-column property="advertTypeName" label="广告类型">
            <template slot-scope="scope">
              <el-row class="row-op-text">
                {{scope.row.advertTypeName}}
              </el-row>
              <el-row>{{scope.row.advertTypeId}}</el-row>
            </template>
          </el-table-column> -->
          <el-table-column property="advertRemark" label="位置说明" width="100"></el-table-column>
          <el-table-column property="notControlList" label="完全投放渠道" min-width="200">
            <template slot-scope="scope">
              <el-button type="text" size="mini" style="margin-right: 10px;" v-for="channel in scope.row.notControlList" :key="channel.id">
                <label :style="channel.controlWorkTimeShow ? 'font-weight:800;color: #999999;' : ''">
                  {{channel.controlWorkTimeShow ? (channel.controlChannel + "-CT") : channel.controlChannel}}
                </label>
              </el-button>
            </template>
          </el-table-column>
          <el-table-column property="controlList" label="控制投放渠道" min-width="200">
            <template slot-scope="scope">
              <el-button type="text" size="mini" style="margin-right: 10px;" v-for="channel in scope.row.controlList" :key="channel.id">
                <label :style="channel.controlWorkTimeShow ? 'font-weight:800;color: #999999;' : 'color:red;'">
                  {{channel.controlWorkTimeShow ? (channel.controlChannel+' [ ' + channel.controlVersion + ' ]-CT') : (channel.controlChannel+' [ ' + channel.controlVersion + ' ] ')}}
                </label>
              </el-button>
            </template>
          </el-table-column>
          <el-table-column property="advertStatus" label="状态" width="70" style="overflow: auto!important;">
            <template slot-scope="scope">
              <el-switch v-model="scope.row.advertStatus" :active-value="1" :inactive-value="0"
                @change="changeAdvertStatus(scope.row)"></el-switch>
            </template>
          </el-table-column>
        </el-table>
        <!-- 分页器 -->
        <div class="block">
          <el-pagination :current-page="pageNum" :page-sizes="[30, 50, 100]" :page-size="pageSize" :total="total"
            layout="total, sizes, prev, pager, next, jumper" @size-change="pageSizeChange"
            @current-change="pageNumChange">
          </el-pagination>
        </div>
      </div>
    </div>

    <!-- 广告新增修改.弹窗 -->
    <el-dialog :title="advertDialogType == 'add' ? '广告新增' : '广告修改'" :visible.sync="advertDialogVisible" width="600px"
      @close="initAdvertDialog()">
      <el-form :model="advertForm" ref="advertForm" :rules="advertRules" class="add_form" label-width="100px" >
        <el-form-item label="应用包名 :" prop="packageName">
          <el-select v-model="advertForm.packageName" placeholder="请选择应用包">
            <el-option :label="item.packageAlias" :value="item.packageName" v-for="(item, index) in packageList" :key="index"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="广告名称 :" prop="advertName">
          <el-input v-model="advertForm.advertName" autocomplete="off" placeholder="请输入广告名称"></el-input>
        </el-form-item>
        <el-form-item label="广告商 :" prop="advertFirm">
          <el-radio-group v-model="advertForm.advertFirm">
              <el-radio :label="item.code" v-for="(item, index) in advertFirmList" :key="index">{{item.name}}</el-radio>
            </el-radio-group>
        </el-form-item>
        <el-form-item label="广告应用id :" prop="advertAppId">
          <el-input v-model="advertForm.advertAppId" autocomplete="off" placeholder="请输入广告应用id"></el-input>
        </el-form-item>
        <el-form-item label="广告类型 :" prop="advertType">
          <el-select v-model="advertForm.advertType" placeholder="广告类型">
            <el-option :label="item.name" :value="item.code" v-for="(item, index) in advertTypeList" :key="index"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="广告位id :" prop="advertTypeId">
          <el-input v-model="advertForm.advertTypeId" autocomplete="off" placeholder="请输入广告位id"></el-input>
        </el-form-item>
        <el-form-item label="展示类型 :" prop="advertShowType">
          <el-radio-group v-model="advertForm.advertShowType">
              <el-radio :label="item.code" v-for="(item, index) in advertShowTypeList" :key="index">{{item.name}}</el-radio>
            </el-radio-group>
        </el-form-item>
        <el-form-item label="展示位置 :" prop="adSiteNo">
          <el-input v-model="advertForm.adSiteNo" type="number" autocomplete="off" placeholder="填写展示位置编号"></el-input>
        </el-form-item>
        <el-form-item label="位置备注 :" prop="advertRemark">
          <el-input v-model="advertForm.advertRemark" autocomplete="off" placeholder="填写展示位置说明"></el-input>
        </el-form-item>
        <el-form-item label="Banner 尺寸 :" prop="" v-show="advertForm.advertType == 'ADVERT_BANNER'">
          <el-col :span="10">
            <el-input v-model="advertForm.bannerWidth" autocomplete="off" placeholder="Banner宽度"></el-input>
          </el-col>
          <el-col :span="2" style="text-align: center;">-</el-col>
          <el-col :span="10">
            <el-input v-model="advertForm.bannerHeight" autocomplete="off" placeholder="Banner高度"></el-input>
          </el-col>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="closeAdvertDialog" size="medium">取 消</el-button>
        <el-button type="primary" @click="advertConfirm" size="medium">确 定</el-button>
      </div>
    </el-dialog>

    <!-- 渠道控制.弹窗 -->
    <el-dialog title="广告渠道控制" :visible.sync="advertChannelDialogVisible" width="800px" >
      <el-descriptions title="广告基础信息" border :column="1">
        <el-descriptions-item label="应用包名">{{advertChannelForm.packageAlias}}</el-descriptions-item>
        <el-descriptions-item label="广告信息">
          <el-table size='small' width="100%" :data="channelDialogType == 'single' ? [advertChannelForm] : advertSelectRows" style="margin-top: 0;">
            <el-table-column type="index" width="55" label="编号"></el-table-column>
            <el-table-column property="controlChannel" label="广告商">
              <template slot-scope="scope">
                <el-row class="row-op-text">
                  {{scope.row.advertFirm == "CSJ" ? '穿山甲' : '其他'}}
                </el-row>
              </template>
            </el-table-column>
            <el-table-column property="advertName" label="广告名称" min-width="150"></el-table-column>
            <el-table-column property="advertTypeName" label="广告类型"></el-table-column>
            <el-table-column property="advertShowTypeName" label="展示类型"></el-table-column>
          </el-table>
        </el-descriptions-item>
        <el-descriptions-item label="全渠道" size="small">
          <el-input-number @input="changAllVersion" :disabled="allChannelInputDisable" :min="0" :max="10000" size="small" style="width:260px" placeholder="请输入全渠道版本号"></el-input-number>
        </el-descriptions-item>
      </el-descriptions>
      <el-table border size='small' :data="advertChannelForm.allChannelList" @selection-change="changeSelectedChannel">
        <el-table-column type="selection" width="55"> </el-table-column>
        <el-table-column property="controlChannel" label="控制渠道"></el-table-column>
        <el-table-column property="controlWorkTimeShow" label="工作时间显示控制">
          <template slot-scope="scope">
            <el-switch v-model="scope.row.controlWorkTimeShow" :active-value="true" :inactive-value="false"></el-switch>
          </template>
        </el-table-column>
        <el-table-column property="controlVersion" label="控制版本号">
          <template slot-scope="scope">
            <el-input-number v-model="scope.row.controlVersion" :min="0" :max="10000" size="small" style="width:260px"></el-input-number>
          </template>
        </el-table-column>
      </el-table>
      <div slot="footer" class="dialog-footer">
        <el-button @click="advertChannelDialogVisible = false" size="medium">取 消</el-button>
        <el-button type="primary" @click="advertChannelConfirm" size="medium">确 定</el-button>
      </div>
    </el-dialog>

  </div>
</template>
<script>
  export default {
    name: 'AdvertConfig',
    //组件创建
    created() {
      this.getPackageList()
      this.getPackageTree()
      this.getDataList()
    },
    data() {
      return {
        defaultProps: {
          children: 'children',
          label: 'label'
        },
        loading: false,
        tableList: [], //tab列表
        searchData: {},
        packageList: [],
        packageTree: [],
        channelList: [
          "huawei", "xiaomi", "oppo", "vivo", "honor", "samsung", "yingyongbao", "baidu", "wandoujia"
        ],
        selected: null, //当前选中行
        pageNum: 1,
        pageSize: 30,
        total: 0,

        advertDialogType: 'add',
        advertDialogVisible: false,
        advertForm: {
          advertFirm: 'CSJ',
          advertShowType: 'DEFAULT'
        },
        advertRules: {
          advertName: [{required: true, message: '广告名称不能为空'}],
          packageName: [{required: true, message: '应用名称不能为空'}],
          advertFirm: [{required: true, message: '广告商不能为空'}],
          advertType: [{required: true, message: '广告类型不能为空'}],
          advertAppId: [{required: true, message: '广告应用ID不能为空'}],
          advertTypeId: [{required: true, message: '广告位ID不能为空'}],
          advertShowType: [{required: true, message: '广告展示方式不能为空'}],
        },
        advertSelectRows: [],
        advertFirmList: [
          {code: "CSJ", name: "穿山甲"}
        ],
        advertTypeList: [{
            code: "ADVERT_OPEN",
            name: "开屏广告"
          },
          {
            code: "ADVERT_INSERT_NEW",
            name: "新插屏广告"
          },
          {
            code: "ADVERT_INSPIRE_VIDEO",
            name: "激励视频广告"
          },
          {
            code: "ADVERT_BANNER",
            name: "Banner广告"
          },
          {
            code: "ADVERT_INFORMATION",
            name: "信息流广告"
          },
          {
            code: "ADVERT_DRAW_INFORMATION",
            name: "Draw信息流广告"
          }
        ],
        advertShowTypeList: [{
            code: "DEFAULT",
            name: "默认"
          },
          {
            code: "INSERT_INDEX",
            name: "首页插屏"
          },
          {
            code: "BANNER",
            name: "Banner"
          },
          {
            code: "PULL",
            name: "拉起"
          },
          {
            code: "QUIT",
            name: "退出"
          }
        ],

        batchDisabled: true,
        channelSelectRows: [],
        allChannelInputDisable: true,
        advertChannelForm: {},
        advertChannelDialogVisible: false,
        channelDialogType: ''

      }
    },
    methods: {
      getPackageList() {
      	let hostUrl=this.$service.publicUrl().PackageListAll
      	this.axios({
      		method: "GET",
      		url: hostUrl,
      	}).then((response)=>{
      		this.loading=false
      	    if(response.data.status==200){
      			  this.packageList=response.data.data
      	    }else{
      			  this.$message.warning("warning : "+response.data.message);
      		}
      	}).catch((error)=>{
      		this.loading=false
      		this.$message.error("查询失败，error : "+JSON.stringify(error));
      	})
      },
      getPackageTree() {
        let hostUrl = this.$service.publicUrl().PackageTree
        this.axios({
          method: "GET",
          url: hostUrl,
        }).then((response) => {
          this.loading = false
          if (response.data.status == 200) {
            this.packageTree = response.data.data
            this.packageTree.unshift({
              label: '全部',
              value: ''
            })
          } else {
            this.$message.warning("warning : " + response.data.message);
          }
          this.selected = null
        }).catch((error) => {
          this.loading = false
          this.$message.error("查询失败，error : " + JSON.stringify(error));
        })
      },
      search() {
        this.pageNum = 1
        this.getDataList()
      },
      nodeClick(data) {
        if (data.value != undefined) {
          this.searchData.packageName = data.value
          this.getDataList()
        }
      },
      //列表查询
      getDataList() {
        let param = {
          pageNum: this.pageNum,
          pageSize: this.pageSize,
          ...this.searchData,
        }
        let hostUrl = this.$service.publicUrl().AdvertList
        this.axios({
          method: "GET",
          url: hostUrl,
          params: param
        }).then((response) => {
          this.loading = false
          if (response.data.status == 200) {
            // this.tableList = response.data.data.list
            this.total = response.data.data.total
            this.constructTabData(response.data.data.list)
          } else {
            this.$message.warning("warning : " + response.data.message);
          }
          this.selected = null
        }).catch((error) => {
          this.loading = false
          this.$message.error("查询失败，error : " + JSON.stringify(error));
        })
      },
      handleAdvertDialog(type) {
        this.advertDialogType = type
        if (type == 'edit') {
          if (this.selected == null) {
            this.$message.warning("请选择一条记录");
            return
          }
          this.$nextTick(() => {
            this.advertForm = JSON.parse(JSON.stringify(this.selected))
          });
        }
        this.advertDialogVisible = true
      },
      closeAdvertDialog() {
        this.advertDialogVisible = false
        this.initAdvertDialog()
      },
      changeAdvertStatus(data) {
        let title = '确认全渠道关闭该广告？'
        if (data.advertStatus == 1) {
          title = '确认全渠道打开该广告？'
        }
        this.$confirm(title, '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          this.axios({
            method: "PUT",
            url: this.$service.publicUrl().AdvertUpdate,
            data: data
          }).then((response) => {
            if (response.data.status == 200) {
              this.$message.success("更新成功");
              this.search()
            } else {
              this.$message.warning("warning : " + response.data.message);
            }
          }).catch((error) => {
            this.loading = false
            this.$message.error("更新失败，error : " + JSON.stringify(error));
          })
        }).catch(() => {
          this.search()
        });
      },
      advertConfirm() {
        this.$refs["advertForm"].validate((valid) => {
          if (valid) {
            let hostUrl = this.advertDialogType == 'add' ? this.$service.publicUrl().AdvertAdd : this.$service.publicUrl().AdvertUpdate
            this.axios({
              method: this.advertDialogType == 'add' ? "POST" : "PUT",
              url: hostUrl,
              data: this.advertForm
            }).then((response) => {
              if (response.data.status == 200) {
                this.$message.success("保存成功");
                this.search()
              } else {
                this.$message.warning("warning : " + response.data.message);
              }
              this.advertDialogVisible = false
            }).catch((error) => {
              this.loading = false
              this.$message.error("保存失败，error : " + JSON.stringify(error));
            })
          }
        })
      },
      advertDelete() {
        if (this.selected == null) {
            this.$message.warning("请先选中一条记录！");
            return
        }
        this.$confirm('确认删除该广告? 删除后不可回复！', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          let hostUrl = this.$service.publicUrl().AdvertDelete
          this.axios({
            method: "DELETE",
            url: hostUrl,
            params: {advertId: this.selected.advertId}
          }).then((response) => {
            this.loading = false
            if (response.data.status == 200) {
              this.$message.success("删除成功");
              this.search()
            } else {
              this.$message.warning("warning : " + response.data.message);
            }
            this.selected = null
          }).catch((error) => {
            this.loading = false
            this.$message.error("删除失败，error : " + JSON.stringify(error));
          })
        }).catch(() => {});
      },
      handleChannelDialog(type) {
        this.channelDialogType = type
        if (type == 'single') {
          if (this.selected == null) {
              this.$message.warning("请先选中一条记录！");
              return
          }
          this.advertChannelForm = this.selected
        } else {
          this.advertChannelForm = this.advertSelectRows[0]
        }
        this.advertChannelDialogVisible = true
      },
      changeSelectedAdvert (e) {
        this.advertSelectRows = e;
        let flag = true
        if (e.length == 0) {
          this.batchDisabled = true
        } else {
          let packageName = this.advertSelectRows[0].packageName
          this.advertSelectRows.forEach(item => {
            if (item.packageName != packageName) {
              flag = false;
              this.batchDisabled = true
            } else {
              flag = true;
            }
          })
          if (flag) {
            this.batchDisabled = false
          }
        }
      },
      changeSelectedChannel (e) {
        this.channelSelectRows = e;
        if (e.length == 0) {
          this.allChannelInputDisable = true
        } else {
          this.allChannelInputDisable = false
        }
        this.advertChannelForm.allChannelList.forEach(channel => {
            channel.checked = false
            this.channelSelectRows.forEach(item => {
              if (channel.id == item.id) {
                  channel.checked = true
              }
            })
          })
      },
      changAllVersion(version) {
        if (version == undefined) {
          version = 0
        }
        this.advertChannelForm.allChannelList.forEach(channel => {
          if (channel.checked) {
            channel.controlVersion = version
          }
        })
      },
      advertChannelConfirm() {
        let param = []
        if (this.channelDialogType == 'single') {
          param = this.advertChannelForm.allChannelList
          let list = []
          param.forEach(channel => {
            if (channel.checked) {
                list.push(channel)
            }
          })
          param = list
        } else {
          this.advertSelectRows.forEach(advertInfo => {
            param = param.concat(advertInfo.allChannelList)
          })
          let list = []
          this.advertChannelForm.allChannelList.forEach(item => {
            param.forEach(advertChannelInfo => {
              if (advertChannelInfo.controlChannel == item.controlChannel) {
                advertChannelInfo.controlVersion = item.controlVersion
                advertChannelInfo.controlWorkTimeShow = item.controlWorkTimeShow
                if (item.checked) {
                  list.push(advertChannelInfo)
                }
              }
            })
          })
          param = list
        }
        let hostUrl = this.$service.publicUrl().AdvertChannelUpdate
        this.axios({
          method: "PUT",
          url: hostUrl,
          data: param
        }).then((response) => {
          if (response.data.status == 200) {
            this.$message.success('更新成功');
          } else {
            this.$message.warning("warning : " + response.data.message);
          }
          this.advertChannelDialogVisible = false
          this.selected = null
          this.search()
        }).catch((error) => {
          this.loading = false
          this.$message.error("更新失败，error : " + JSON.stringify(error));
        })
      },
      constructTabData(data) {
        data.forEach(item => {
          switch (item.advertType) {
            case 'ADVERT_INFORMATION': {
              item.advertTypeName = '信息流广告'
              break
            }
            case 'ADVERT_DRAW_INFORMATION': {
              item.advertTypeName = 'Draw信息流广告'
              break;
            }
            case 'ADVERT_BANNER': {
              item.advertTypeName = 'Banner广告'
              break;
            }
            case 'ADVERT_OPEN': {
              item.advertTypeName = '开屏广告'
              break;
            }
            case 'ADVERT_INSERT_NEW': {
              item.advertTypeName = '新插屏广告'
              break;
            }
            case 'ADVERT_INSPIRE_VIDEO': {
              item.advertTypeName = '激励视频广告'
              break;
            }
          }
          switch (item.advertShowType) {
            case 'DEFAULT': {
              item.advertShowTypeName = "默认"
              break
            }
            case 'INSERT_INDEX': {
              item.advertShowTypeName = "首页插屏"
              break
            }
            case 'BANNER': {
              item.advertShowTypeName = "Banner"
              break;
            }
            case 'PULL': {
              item.advertShowTypeName = "拉起"
              break;
            }
            case 'QUIT': {
              item.advertShowTypeName = "退出"
              break;
            }
          }
        })
        this.tableList = data
      },
      //点击行
      handleCurrentChange(e) {
        this.selected = e
      },
      pageSizeChange(e) {
        this.pageSize = e
        this.search()
      },
      //pageNum变化
      pageNumChange(e) {
        this.pageNum = e;
        this.getDataList();
      },
      initAdvertDialog(){
      	// this.advertForm = {}
        this.$refs["advertForm"].resetFields()
      },
    }
  }
</script>
<style lang="less" scoped>
  .drawer_inner {
    width: 95%;
    height: 100%;
    margin: auto;
  }

  .cont_table /deep/ .el-icon-tickets {
    font-size: 20px;
    margin-top: 4px;
  }

  .tips {
    font-size: 8px;
    margin-left: 30px;
    color: #878787;
  }

  .tips>span {
    color: orangered !important;
  }

  .content {
    display: flex;
    flex-direction: row;
    width: 100%;
    // justify-content: space-around;
  }

  .content_left {
    margin-right: 16px;
    padding-top: 21px;
    letter-spacing: 2px;
    width: 15%;
    height: calc(100% - 65px);
    background: #FFFFFF;
    overflow: auto
  }

  .content_right {
    width: 85%;
  }

  .cont_bar {
    display: flex;
  }
</style>
<style>
  .el-tree-node__content {
    height: 40px !important;
  }

  .el-switch {
    height: 40px;
  }
</style>
