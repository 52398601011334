<template>
<div id="dis_list" class="content" v-loading="loading" element-loading-text="加载中">
	<!-- 顶部操作栏 -->
	<div class="cont_bar">
		<div class="bar_left">
			<el-form :inline="true" :model="searchData" class="demo-form-inline" size="small">
			  <el-form-item label="应用名称：">
			    <el-select v-model="searchData.packageName" placeholder="应用名称" :filterable="true" >
			      <el-option label="全部" value=""></el-option>
			      <el-option v-for="item in packageList" :key="item.id" :label="item.name" :value="item.packageAlias"></el-option>
			    </el-select>
			  </el-form-item>
        <el-form-item label="公司名称：">
          <el-select v-model="searchData.companyName" placeholder="公司名称" :filterable="true" >
            <el-option label="全部" value=""></el-option>
            <el-option v-show="userInfo.userLevel == 0 || userInfo.userLevel == item.level" v-for="item in companyList" :key="item.companyName" :label="item.companyAlias" :value="item.companyName"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
				<el-tooltip content="查询"  placement="top">
					<el-button type="primary" @click="search1()" icon="el-icon-search"></el-button>
				</el-tooltip>
			  </el-form-item>
			</el-form>
		</div>
    <div class="bar_right">
			<el-row>
				<el-tooltip content="预览"  placement="top">
					<el-button type="primary" icon="el-icon-share" size="mini" @click="preview"></el-button>
				</el-tooltip>
        <el-tooltip content="新增"  placement="top">
					<el-button type="success" icon="el-icon-plus" size="mini" @click="addItem"></el-button>
				</el-tooltip>
        <el-tooltip content="编辑"  placement="top">
					<el-button type="info" icon="el-icon-edit" size="mini" @click="handleEdit"></el-button>
				</el-tooltip>
				<el-tooltip content="删除"  placement="top">
					<el-button type="warning" icon="el-icon-delete" size="mini" @click="openDetele()"></el-button>
				</el-tooltip>
			</el-row>
		</div>
	</div>
	<!-- 主内容区 -->
	<div class="cont_table">
		<!-- 表格 -->
		<el-table ref="goodsTable" id="goodsTable" :data="tableList" highlight-current-row @row-click="handleCurrentChange" width="100%" height="calc(100% - 70px)">
        <el-table-column property="email" label="公司名称" min-width="100">
          <template slot-scope="scope">
            <el-row class="row-op-text">
              {{scope.row.companyName == 'generone' ? '初代科技' : scope.row.companyName == 'lotsgain' ?  '哆萤科技' : scope.row.companyName == 'wisdomtec' ? '威仕多网络' : '璟智信息'}}
            </el-row>
          </template>
        </el-table-column>
		    <el-table-column property="packageName" label="应用包名" min-width="200"></el-table-column>
		    <el-table-column property="appName" label="显示名称" min-width="200"></el-table-column>
		    <el-table-column property="appDesc" label="产品描述" min-width="200"></el-table-column>
		    <el-table-column property="downloadUrl" label="下载链接" min-width="200"></el-table-column>
		</el-table>
		<!-- 分页器 -->
		<div class="block">
			<el-pagination :current-page="pageNum" :page-sizes="[30, 50, 100]" :page-size="pageSize" :total="total"
				layout="total, sizes, prev, pager, next, jumper" @size-change="pageSizeChange" @current-change="pageNumChange">
			</el-pagination>
		</div>
	</div>
  <el-dialog :title="type == 'add' ? '新增' : '编辑'" :visible.sync="visible" width="900" :before-close="handleClose"
    :close-on-click-modal="false">
    <el-form ref="form" :model="form" :rules="rules" label-width="160px" style="padding-right:100px;">
      <el-form-item label="应用包名：" prop="packageName">
        <el-select v-model="form.packageName" filterable placeholder="请选择" size="small">
          <el-option v-for="item in packageList" :key="item.packageName" :label="item.packageAlias + '-' + item.packageName" :value="item.packageName"> </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="公司名称：" prop="companyName">
        <el-select v-model="form.companyName" filterable placeholder="请选择" size="small">
          <el-option v-show="userInfo.userLevel == 0 || userInfo.userLevel == item.level" v-for="item in companyList" :key="item.companyName" :label="item.companyAlias"
            :value="item.companyName"> </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="app显示名称：" prop="appName">
        <el-input v-model="form.appName" placeholder="请输入App名称"></el-input>
      </el-form-item>
      <el-form-item label="缩略图：" prop="files">
        <el-upload action="#" :on-exceed="maxFun" :on-change="handleImgChange" list-type="picture-card"
          :file-list="form.file" :class="{ hide: hideUploadEdit }" :on-success="handleSuccess"
          accept="image/jpeg,image/jpg,image/png" :limit="1" :auto-upload="false">
          <i slot="default" class="el-icon-plus"></i>
          <div slot="file" slot-scope="{ file }">
            <img class="el-upload-list__item-thumbnail" :src="file.url" alt="" />
            <span class="el-upload-list__item-actions">
              <span class="el-upload-list__item-preview" @click="handlePictureCardPreview(file)">
                <i class="el-icon-zoom-in"></i>
              </span>

              <span class="el-upload-list__item-delete" @click="handleRemove(file)">
                <i class="el-icon-delete"></i>
              </span>
            </span>
          </div>
        </el-upload>
        <el-dialog append-to-body :visible.sync="dialogVisible">
          <img width="100%" :src="dialogImageUrl" alt="" />
        </el-dialog>
      </el-form-item>
      <el-form-item label="应用截图：" prop="files1">
        <el-upload action="#" accept="image/jpeg,image/jpg,image/png" list-type="picture-card" :file-list="form.bannerList" :on-change="handleImgChange1" :on-preview="handlePictureCardPreview1" :on-remove="handleRemove1" :auto-upload="false">
          <i class="el-icon-plus"></i>
        </el-upload>
        <el-dialog :visible.sync="dialogVisible1">
          <img width="100%" :src="dialogImageUrl1" alt="">
        </el-dialog>
      </el-form-item>
      <el-form-item label="app下载链接：" prop="downloadUrl">
        <el-input v-model="form.downloadUrl" placeholder="请输入App下载链接"></el-input>
      </el-form-item>
      <el-form-item label="app描述：" prop="appDesc">
        <el-input v-model="form.appDesc" type="textarea" :rows="10" placeholder="请输入App描述,如需换行,请在行末添加 <br>"></el-input>
      </el-form-item>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button @click="handleClose">取 消</el-button>
      <el-button type="primary" @click="confirm" :disabled="disable">确 定</el-button>
    </span>
  </el-dialog>
</div>
</template>
<script>
export default {
	name: 'WebsiteConfig',
	//组件创建
	created() {
		this.search(),
    this.getPackageList()
    this.userInfo = JSON.parse(sessionStorage.getItem("userInfo"))
	},
	data() {
		return{
      userInfo: {},
			loading:false,
			tableList:[],//tab列表
      packageList: [],
      companyList: [
        {companyName: "generone", companyAlias: "成都初代科技有限公司", domain: 'https://www.generone.com', level: 10},
        {companyName: "lotsgain", companyAlias: "成都哆萤科技有限公司", domain: 'https://www.lotsgain.com', level: 10},
        {companyName: "wisdomtec", companyAlias: "成都威仕多网络科技有限公司", domain: 'https://www.wisdomtec.com'},
        {companyName: "cdjztec", companyAlias: "成都璟智信息技术有限公司", domain: 'https://www.cdjztec.com'},
      ],
			selected:null,//当前选中行
			//行详情抽屉
      searchData: {},
      pageNum: 1,
      pageSize: 30,
      total: 0,

      visible: false,
      disable: false,
      hideUploadEdit: false,
      dialogImageUrl: '',
      dialogVisible1: false,
      hideUploadEdit1: false,
      dialogImageUrl1: '',
      bannerFileList: [],
      disabled: false,
      dialogVisible: false,
      type: '',
      form: {},
      rules: {
        companyName: [{
          required: true,
          message: '请选择公司',
          trigger: 'blur'
        }],
        appName: [{
          required: true,
          message: '请输入app名称',
          trigger: 'blur'
        }],
        appDesc: [{
          required: true,
          message: '请输入app描述',
          trigger: 'blur'
        }],
      },
		}
	},
	methods:{
    getPackageList() {
    	let hostUrl=this.$service.publicUrl().PackageListAll
    	this.axios({
    		method: "GET",
    		url: hostUrl,
    	}).then((response)=>{
    		this.loading=false
    	    if(response.data.status==200){
    			  this.packageList=response.data.data
    	    }else{
    			  this.$message.warning("warning : "+response.data.message);
    		}
    	}).catch((error)=>{
    		this.loading=false
    		this.$message.error("查询失败，error : "+JSON.stringify(error));
    	})
    },
    search1 () {
      this.pageNum = 1
      this.search()
    },
		//列表查询
		search(){
			let param = {
        pageNum: this.pageNum,
        pageSize: this.pageSize,
        ...this.searchData,
      }
			let hostUrl=this.$service.publicUrl().WebsiteAppList
			this.axios({
				method: "GET",
				url: hostUrl,
				params: param
			}).then((response)=>{
        console.log (response)
				this.loading=false
			    if(response.data.status==200){
					  this.tableList=response.data.data.list
            this.total = response.data.data.total
			    }else{
					  this.$message.warning("warning : "+response.data.message);
				}
				this.selected=null
			}).catch((error)=>{
				this.loading=false
				this.$message.error("查询失败，error : "+JSON.stringify(error));
			})
		},
    preview() {
      if (this.selected == null) {
         this.$message.warning("请选择一条记录");
         return
      }
      this.companyList.forEach(item => {
        if (item.companyName == this.selected.companyName) {
          console.log(item.domain)
          window.open(item.domain)
        }
      })
    },
    addItem() {
      this.type = 'add'
      this.disable = false
      this.visible = true
      this.hideUploadEdit = false
      this.form = {}
    },
    confirm() {
      this.$refs['form'].validate((valid) => {
        this.disable = true
        if (valid) {
          if (this.form.files == '') {
            this.$message.error('请上传缩略图')
            return

          }
          let data = {
            ...this.form,
          }

          delete data.files
          delete data.thumbUrl

          let formdata = new FormData()
          for (let item in data) {
            if (item != 'file' && data[item] !== null) {
              formdata.append(item, data[item])
            }
          }
          if (this.imgUrl != undefined && this.imgUrl.length != 0) {
            formdata.append('file', this.imgUrl[0].raw)
          }
          if (this.bannerFileList != undefined && this.bannerFileList.length != 0) {
            this.bannerFileList.forEach(item => {
              formdata.append('bannerFile', item.raw)
            })
          }
          if (this.type == 'add') {
            let hostUrl=this.$service.publicUrl().WebsiteAppAdd
            this.axios({
            	method: "POST",
            	url: hostUrl,
            	data: formdata
            }).then((response)=>{
              console.log (response)
            	this.loading=false
                if(response.data.status==200){
                  this.$message.success('新增成功')
            		  this.pageNum = 1
            		  this.search()
                }else{
            		  this.$message.warning("warning : "+response.data.message);
            	}
            	this.selected=null
              this.visible = false
            }).catch((error)=>{
            	this.loading=false
            	this.$message.error("查询失败，error : "+JSON.stringify(error));
            })
          }
          if (this.type == 'edit') {
            let hostUrl=this.$service.publicUrl().WebsiteAppUpdate
            this.axios({
            	method: "POST",
            	url: hostUrl,
            	data: formdata
            }).then((response)=>{
              console.log (response)
            	this.loading=false
                if(response.data.status==200){
                  this.$message.success('修改成功')
            		  this.pageNum = 1
            		  this.search()
                }else{
            		  this.$message.warning("warning : "+response.data.message);
            	}
            	this.selected=null
              this.visible = false
            }).catch((error)=>{
            	this.loading=false
            	this.$message.error("查询失败，error : "+JSON.stringify(error));
            })
          }
        }
      })
    },
    handleEdit() {
      if(this.selected==null){
      	this.$message.warning("请先选中一条记录！");
      	return
      }
      this.type = 'edit'
      this.disable = false
      this.visible = true
      this.hideUploadEdit = true
      this.form = JSON.parse(JSON.stringify(this.selected))
      this.bannerFileList = []
      this.form.file = [{
        url: this.selected.appIcon,
        uid: Math.random()
      }]
      this.form.bannerList = []
      if (this.form.bannerUrl != undefined) {
        let urlList = this.form.bannerUrl.split(',')
        urlList.forEach(url => {
          this.form.bannerList.push({
            url: url,
            uid: Math.random()
          })
        })
      }
      if (this.form.bannerList.length >= 5) {
        this.hideUploadEdit1 = true
      }
      this.form.files = '1'
    },
    handleClose() {
      this.visible = false
      this.form = this.$options.data().form
      this.$refs['form'].resetFields()
    },
    handleRemove() {
      this.form.file = []
      this.form.files = ''
      this.imgUrl = []
      this.hideUploadEdit = false
    },
    handlePictureCardPreview(file) {
      this.dialogImageUrl = file.url
      this.dialogVisible = true
    },
    maxFun() {
      this.$message.info('最多只能上传一张图片')
    },
    handleSuccess() {},
    handleImgChange(file, fileList) {
      let reg = /\.(png|jpg|jpeg)$/
      if (reg.test(file.name)) {
        this.imgUrl = [file]
        this.hideUploadEdit = fileList.length >= 1
        this.form.files = '1'
      } else {
        this.hideUploadEdit = true
        this.$message.error('仅支持png/jpg格式图片')
        return false
      }
    },
    handleImgChange1(file, fileList) {
      let reg = /\.(png|jpg|jpeg)$/
      if (reg.test(file.name)) {
        this.bannerFileList.push(file)
        this.hideUploadEdit1 = fileList.length >= 5
      } else {
        this.hideUploadEdit1 = true
        this.$message.error('仅支持png/jpg格式图片')
        return false
      }
    },
    handleRemove1(file) {
      for (let i = 0, len = this.bannerFileList.length; i < len; i++) {
        if (this.bannerFileList[i].uid === file.uid) {
          this.bannerFileList.splice(i, 1)
          break
        }
      }
      this.form.bannerUrl = ''
      for (let i = 0, len = this.form.bannerList.length; i < len; i++) {
        if (this.form.bannerList[i].uid != file.uid) {
            this.form.bannerUrl += (this.form.bannerList[i].url + ',')
        }
      }
      if (this.form.bannerUrl.length > 0) {
        this.form.bannerUrl = this.form.bannerUrl.substring(0, this.form.bannerUrl.length - 1)
      }
    },
    handlePictureCardPreview1(file) {
      this.dialogImageUrl = file.url
      this.dialogVisible1 = true
    },
    openDetele(){
			if(this.selected==null){
				this.$message.warning("请先选中一条记录！");
				return
			}
			this.$confirm('确认删除该记录?', '提示', {
				confirmButtonText: '确定',
				cancelButtonText: '取消',
				type: 'warning'
			}).then(() => {
				this.deleteChannel()
			}).catch(() => {
			});
		},
    deleteChannel() {
       let param = {
        id: this.selected.id,
      }
			let hostUrl=this.$service.publicUrl().AgreementDelete
			this.axios({
				method: "DELETE",
				url: hostUrl,
				params: param
			}).then((response)=>{
        console.log(response)
				this.loading=false
        if(response.data.status==200){
          this.$message.success("删除成功");
          this.search()
        }else{
          this.$message.warning("warning : "+response.data.message);
        }
        this.selected = null
			}).catch((error)=>{
				this.loading=false
				this.$message.error("删除失败，error : "+JSON.stringify(error));
			})
    },
		//点击行
		handleCurrentChange(e){
			this.selected=e
		},
    pageSizeChange(e){
			this.pageSize=e
			this.search()
		},
		//pageNum变化
		pageNumChange(e){
			this.pageNum=e
			this.search();
		},
	}
}
</script>
<style lang="less" scoped>
.drawer_inner{
	width: 95%;
	height: 100%;
	margin: auto;
}
.cont_table /deep/ .el-icon-tickets{
	font-size: 20px;
	margin-top: 4px;
}
.tips{
	font-size: 8px;
	margin-left: 30px;
	color: #878787;
}
.tips>span{
	color: orangered !important;
}
</style>
