<template>
    <div id="welcome">
        这是系统管理
    </div>
</template>
<script>
export default {
    name: 'SystemManage',
    //组件创建
    created() {
    },
    mounted(){
    },
    data() {
        return{
        }
    },
    methods:{
    }
}
</script>
<style lang="less" scoped></style>