<template>
<div class="content" id="DocxIndex">
	<router-view />
</div>
</template>
<script>
export default {
    name: 'DocxIndex',
    //组件创建
    created() {
    },
    data() {
        return{
        }
    },
    methods:{
    }
}
</script>
<style lang="less" scoped>
</style>