<template>
  <div id="dis_list" class="content" v-loading="loading" element-loading-text="加载中">
    <div>
      <div class="bar_left" style="width: 80%">
        <el-form :inline="true" :model="searchData" class="demo-form-inline" size="small">
          <el-form-item label="应用名称：">
            <el-select collapse-tags v-model="searchData.packageName" placeholder="应用名称" :filterable="true"
              @change="getDataList">
              <el-option v-for="item in packageList" :key="item.packageName" :label="item.packageAlias" :value="item.packageName">
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="统计维度：">
            <el-select v-model="chartsType" placeholder="统计维度" @change="initEchars">
              <el-option v-for="item in chartsTypeList" :key="item.id" :label="item.label" :value="item.id"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="日期：">
            <el-date-picker v-model="searchData.time" value-format="yyyy-MM-dd" :picker-options="pickerOptions"
              type="daterange" range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期" @change="setTime">
            </el-date-picker>
          </el-form-item>
        </el-form>
        <el-tooltip content="渠道详情"  placement="top">
        	<el-button icon="el-icon-share" size="mini" @click="channelDetail">渠道详情</el-button>
        </el-tooltip>
      </div>
    </div>
    <!-- 主内容区 -->
    <div class="cont_table">
      <div class="charts">
        <div id="char" style="height:500px;"></div>
      </div>
      <el-table :stripe="true" size="mini" ref="goodsTable" id="goodsTable" :data="tableData" highlight-current-row
        width="100%">
        <el-table-column property="recordDate" label="日期" min-width="150">
          <template slot-scope="scope">
            <label>{{scope.row.recordDate.substr(0, 10)}}</label>
          </template>
        </el-table-column>
        <el-table-column property="newUser" label="新增用户"></el-table-column>
        <el-table-column property="activeUser" label="活跃用户"></el-table-column>
        <el-table-column property="launchTimes" label="运行次数"></el-table-column>
        <el-table-column property="registerUser" label="注册用户"></el-table-column>
        <el-table-column property="totalUser" label="总用户" ></el-table-column>
      </el-table>
    </div>
  </div>
</template>
<script>
  import * as echarts from 'echarts';
  export default {
    name: 'UserStatisticsDetail',
    //组件创建
    created() {
      this.startDate = this.getNextDate(new Date(), -8)
      this.endDate = this.getNextDate(new Date(), -1)

      this.searchData = {
        startDate: this.startDate + " 00:00:00",
        endDate: this.endDate + " 00:00:00",
        time: [
          this.startDate + " 00:00:00", this.endDate + " 00:00:00",
        ]
      }
      this.getPackageList()
    },
    data() {
      return {
        title: '广告收益',
        loading: false,
        chartsType: 1,
        now: "",
        chart: "",
        chartsTypeList: [{
            id: 1,
            label: '新增用户'
          },
          {
            id: 2,
            label: '活跃用户'
          },
          {
            id: 3,
            label: '运行次数'
          },
          {
            id: 4,
            label: '注册用户'
          },
          {
            id: 5,
            label: '总用户'
          },
        ],
        packageList: [],
        searchData: {},
        pickerOptions: {
          disabledDate(time) {
            return time.getTime() > Date.now() - 3600 * 1000 * 24;
          },
          shortcuts: [{
            text: '一周前',
            onClick(picker) {
              const date = new Date();
              date.setTime(date.getTime() - 3600 * 1000 * 24 * 7);
              picker.$emit('pick', [date, new Date()]);
            }
          }]
        },
        dataTotal: [],
        tableData: [],
        dataList: [],
        xData: [],
      }
    },
    mounted() {
      this.searchData.packageName = this.$route.params.packageName
    },
    methods: {
      getNextDate(date, day) {
      　　var dd = new Date(date);
      　　dd.setDate(dd.getDate() + day);
      　　var y = dd.getFullYear();
      　　var m = dd.getMonth() + 1 < 10 ? "0" + (dd.getMonth() + 1) : dd.getMonth() + 1;
      　　var d = dd.getDate() < 10 ? "0" + dd.getDate() : dd.getDate();
      　　return y + "-" + m + "-" + d;
      },
      initEchars() {
        let yData = []
        this.xData = []
        for (let i = this.dataList.length - 1; i >= 0 ; i--) {
          let item = this.dataList[i]
          this.xData.push(item.recordDate.substr(0, 10))
          switch (this.chartsType) {
            case 1:
              yData.push(item.newUser)
              break
            case 2:
              yData.push(item.activeUser)
              break
            case 3:
              yData.push(item.launchTimes)
              break
            case 4:
              yData.push(item.registerUser)
              break
            case 5:
              yData.push(item.totalUser)
              break
          }
        }
        var chartDom = document.getElementById('char');
        this.chart = echarts.init(chartDom);
        var option = {
          title: {
            text: this.chartsTypeList[this.chartsType - 1].label,
            left: 'center'
          },
          tooltip: {
            trigger: 'axis'
          },
          xAxis: {
            type: 'category',
            boundaryGap: false,
            // data: xData
            data: this.xData
          },
          yAxis: {
            type: 'value'
          },
          series: [{
            name: this.chartsTypeList[this.chartsType - 1].label,
            type: 'line',
            smooth: true,
            data: yData
          }]
        };

        option && this.chart.setOption(option, true);
      },
      changePackage(e) {
        console.log(e)
      },
      refresh() {
        this.getDataList()
      },
      setTime(e) {
        if (e) {
          this.searchData.startDate = e[0] + ' 00:00:00'
          this.searchData.endDate = e[1] + ' 00:00:00'
        } else {
          this.searchData.startDate = null
          this.searchData.endDate = null
        }
        this.getDataList()
      },
      cancel(e) {
        console.log(e)
      },
      getDataList(type) {
        let param = JSON.parse(JSON.stringify(this.searchData))
        delete param['time']
        let hostUrl = this.$service.publicUrl().UserStatisticsDay
        this.axios({
          method: "GET",
          url: hostUrl,
          params: param,
        }).then((response) => {
          this.loading = false
          if (response.data.status == 200) {
            this.dataList = response.data.data
            this.tableData = response.data.data
            console.log(this.dataList)
            this.initEchars()
          } else {
            this.$message.warning("warning : " + response.data.message);
          }
        })
      },
      getPackageList() {
        let hostUrl = this.$service.publicUrl().PackageListAll
        this.axios({
        		method: "GET",
          url: hostUrl,
        }).then((response) => {
        		this.loading = false
          if (response.data.status == 200) {
            this.packageList = response.data.data
            console.log(this.packageList[0])
            console.log(this.packageList[1])
            if(!this.searchData.packageName) {
              this.searchData.packageName = this.packageList[0].packageName
            }
            this.getDataList()
          } else {
            this.$message.warning("warning : " + response.data.message);
       		 }
        }).catch((error) => {
       		 this.loading = false
          this.$message.error("查询失败，error : " + JSON.stringify(error));
        })
      },
      channelDetail () {
        this.$router.push({
          name: 'user_statistics_channel',
          params: {
            packageName: this.searchData.packageName
          }
        })
      }
    }
  }
</script>
<style lang="less" scoped>
  .charts {
    background: #FFFFFF;
    width: 98%;
    padding-right: 5%;
    // display: flex;
    height: 500px;
    margin-top: 10px;
    padding-top: 15px;
  }

  .ranking-item {
    display: flex;
  }

  // .el-main {
  //   padding-top: 0px;
  //   height: 80vh;
  // }
  /deep/.bar_left .el-form-item__content {
    max-width: 190px;
  }

  .cont_bars {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    // overflow-x: auto;
  }
</style>
