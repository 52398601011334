<template>
<div id="dis_list" class="content" v-loading="loading" element-loading-text="加载中">
	<!-- 顶部操作栏 -->
	<div class="cont_bar">
		<div class="bar_left">
      <el-form :inline="true" :model="searchData" class="demo-form-inline" size="small">
			  <el-form-item label="应用名称：">
			    <el-select v-model="searchData.packageName" placeholder="应用名称" :filterable="true" >
            <el-option label="全部" value=""></el-option>
			      <el-option v-for="item in packageList" :key="item.id" :label="item.name" :value="item.packageAlias"></el-option>
			    </el-select>
			  </el-form-item>
        <el-form-item>
				<el-tooltip content="查询"  placement="top">
					<el-button type="primary" @click="search1()" icon="el-icon-search"></el-button>
				</el-tooltip>
			  </el-form-item>
			</el-form>
		</div>
    <div class="bar_right">
			<el-row>
				<el-tooltip content="新增"  placement="top">
					<el-button type="success" icon="el-icon-plus" size="mini" @click="openAdd"></el-button>
				</el-tooltip>
        <el-tooltip content="编辑"  placement="top">
					<el-button type="info" icon="el-icon-edit" size="mini" @click="openUpdate"></el-button>
				</el-tooltip>
				<el-tooltip content="删除"  placement="top">
					<el-button type="warning" icon="el-icon-delete" size="mini" @click="openDetele()"></el-button>
				</el-tooltip>
			</el-row>
		</div>
	</div>
	<!-- 主内容区 -->
	<div class="cont_table">
		<!-- 表格 -->
		<el-table ref="goodsTable" id="goodsTable" :data="tableList" highlight-current-row @row-click="handleCurrentChange" width="100%" height="calc(100% - 70px)">
		    <el-table-column prop="id" width="100" label="编号"></el-table-column>
		    <el-table-column property="packageAlias" label="应用名称" min-width="100"></el-table-column>
        <el-table-column property="payMode" label="支付方式" min-width="100">
          <template slot-scope="scope">
            <label :style="scope.row.payMode != 1 ? 'color: #4382e6' : 'color: #faa2db'">
              {{scope.row.payMode == 1 ? "App支付" : "H5支付"}}
            </label>
          </template>
        </el-table-column>
        <el-table-column property="wechatAppId" label="微信appKey" min-width="200"></el-table-column>
        <el-table-column property="aliAppId" label="支付宝appId" min-width="200"></el-table-column>
		</el-table>
		<!-- 分页器 -->
		<div class="block">
			<el-pagination :current-page="pageNum" :page-sizes="[30, 50, 100]" :page-size="pageSize" :total="total"
				layout="total, sizes, prev, pager, next, jumper" @size-change="pageSizeChange" @current-change="pageNumChange">
			</el-pagination>
		</div>
	</div>
  <el-dialog :title="dialogType ==1 ? '新增付费参数' : '修改付费参数'" :visible.sync="addFormVisible" width="650px" @close="initDialog()">
	  <el-form :model="addForm" ref="addForm" :rules="addRules" class="add_form">
	    <el-form-item label="应用包名 :" label-width="120px"  prop="packageName">
	      <el-select v-model="addForm.packageName" placeholder="请选择应用包" :disabled="dialogType == 'edit'">
	        <el-option :label="item.packageAlias" :value="item.packageName" v-for="(item, index) in packageList" :key="index"></el-option>
	      </el-select>
	    </el-form-item>
	    <el-form-item label="支付方式 :" label-width="120px"  prop="payMode">
	      <el-radio-group v-model="addForm.payMode">
	      	<el-radio :label="1">App支付</el-radio>
	      	<el-radio :label="2">H5支付</el-radio>
	      </el-radio-group>
	    </el-form-item>
	    <el-form-item label="收款商户 :" label-width="120px"  prop="wechatMchId">
	      <el-radio-group v-model="addForm.wechatMchId">
	      	<el-radio label="1639369732">初代科技</el-radio>
	      </el-radio-group>
	    </el-form-item>
	    <el-form-item label="微信appKey :" label-width="120px"  prop="wechatAppId">
	      <el-input v-model="addForm.wechatAppId" autocomplete="off" placeholder="请输入微信appKey"></el-input>
	    </el-form-item>
      <el-form-item label="支付宝appId :" label-width="120px"  prop="aliAppId">
	      <el-input v-model="addForm.aliAppId" autocomplete="off" placeholder="请输入支付宝appId"></el-input>
	    </el-form-item>
      <el-form-item label="支付宝支付公钥 :" label-width="120px"  prop="aliPayPublicKey">
	      <el-input v-model="addForm.aliPayPublicKey" type="textarea" autocomplete="off" placeholder="请输入支付宝支付公钥"></el-input>
	    </el-form-item>
      <el-form-item label="支付宝应用公钥 :" label-width="120px"  prop="aliAppPublicKey">
	      <el-input v-model="addForm.aliAppPublicKey" type="textarea" autocomplete="off" placeholder="请输入支付宝应用公钥"></el-input>
	    </el-form-item>
      <el-form-item label="支付宝应用私钥 :" label-width="120px"  prop="aliAppPrivateKey">
	      <el-input v-model="addForm.aliAppPrivateKey" type="textarea" autocomplete="off" placeholder="请输入支付宝应用私钥"></el-input>
	    </el-form-item>
	  </el-form>
	  <div slot="footer" class="dialog-footer">
	    <el-button @click="addFormVisible = false">取 消</el-button>
	    <el-button type="primary" @click="confirm">确 定</el-button>
	  </div>
	</el-dialog>
</div>
</template>
<script>
export default {
	name: 'ConfigPay',
	//组件创建
	created() {
		this.search(),
    this.getPackageList(),
    this.getChannelList()
	},
	data() {
		return{
			loading:false,
      dialogType: 'add',
      addFormVisible: false,
			tableList:[],//tab列表
      searchData: {},
      packageList: [],
			selected:null,//当前选中行
			//行详情抽屉
			rowDrawer:false,
			rowDetail:{},
      pageNum: 1,
      pageSize: 30,
      total: 0,
      addForm: {
        payMode: 1,
        wechatMchId: '1639369732'
      },
      addRules: {
				packageName: [{ required: true, message: '应用名称不能为空'}],
			},
		}
	},
	methods:{
    getPackageList() {
			let hostUrl=this.$service.publicUrl().PackageListAll
			this.axios({
				method: "GET",
				url: hostUrl,
			}).then((response)=>{
				this.loading=false
			    if(response.data.status==200){
					  this.packageList=response.data.data
			    }else{
					  this.$message.warning("warning : "+response.data.message);
				}
			}).catch((error)=>{
				this.loading=false
				this.$message.error("查询失败，error : "+JSON.stringify(error));
			})
    },
    search1 () {
      this.pageNum = 1
      this.search()
    },
		//列表查询
		search(){
			let param = {
        pageNum: this.pageNum,
        pageSize: this.pageSize,
        ...this.searchData,
      }
			let hostUrl=this.$service.publicUrl().PayConfigList
			this.axios({
				method: "GET",
				url: hostUrl,
				params: param
			}).then((response)=>{
				this.loading=false
			    if(response.data.status==200){
					  this.tableList=response.data.data.list
            this.total = response.data.data.total
			    }else{
					  this.$message.warning("warning : "+response.data.message);
				}
				this.selected=null
			}).catch((error)=>{
				this.loading=false
				this.$message.error("查询失败，error : "+JSON.stringify(error));
			})
		},
    openAdd() {
      this.addFormVisible = true
      this.dialogType = 'add'
      this.initDialog()
    },
    openUpdate() {
      if (this.selected == null) {
         this.$message.warning("请选择一条记录");
         return
      }
      this.dialogType = 'edit'
      this.addFormVisible = true
      this.$nextTick(() => {
          this.addForm = {...this.selected}
      });
    },
    confirm() {
      if (this.dialogType == 'add') {
        this.addConfig()
      } else {
        this.updateConfig()
      }
    },
    addConfig () {
      this.$refs["addForm"].validate((valid) => {
        if (valid) {
          let param = {
            ...this.addForm,
          }
          let hostUrl=this.$service.publicUrl().PayConfigAdd
          this.axios({
            method: "POST",
            url: hostUrl,
            data: param
          }).then((response)=>{
            this.loading=false
            if(response.data.status==200){
              this.$message.success("保存成功");
              this.search()
            }else{
              this.$message.warning("warning : "+response.data.message);
            }
            this.addFormVisible = false
          }).catch((error)=>{
            this.loading=false
            this.$message.error("保存失败，error : "+JSON.stringify(error));
          })
        }
      })
    },
    updateConfig () {
      this.$refs["addForm"].validate((valid) => {
        if (valid) {
          let param = {
            ...this.addForm,
          }
          let hostUrl=this.$service.publicUrl().PayConfigUpdate
          this.axios({
            method: "PUT",
            url: hostUrl,
            data: param
          }).then((response)=>{
            this.loading=false
            if(response.data.status==200){
              this.$message.success("修改成功");
              this.search()
            }else{
              this.$message.warning("warning : "+response.data.message);
            }
            this.addFormVisible = false
          }).catch((error)=>{
            this.loading=false
            this.$message.error("保存失败，error : "+JSON.stringify(error));
          })
        }
      })
    },
    openDetele(){
			if(this.selected==null){
				this.$message.warning("请先选中一条记录！");
				return
			}
			this.$confirm('确认删除该记录?', '提示', {
				confirmButtonText: '确定',
				cancelButtonText: '取消',
				type: 'warning'
			}).then(() => {
				this.deleteConfig()
			}).catch(() => {
			});
		},
    deleteConfig() {
       let param = {
        id: this.selected.id,
      }
			let hostUrl=this.$service.publicUrl().PayConfigDelete
			this.axios({
				method: "DELETE",
				url: hostUrl,
				params: param
			}).then((response)=>{
        console.log(response)
				this.loading=false
        if(response.data.status==200){
          this.$message.success("删除成功");
          this.search()
        }else{
          this.$message.warning("warning : "+response.data.message);
        }
        this.selected = null
			}).catch((error)=>{
				this.loading=false
				this.$message.error("删除失败，error : "+JSON.stringify(error));
			})
    },
		//点击行
		handleCurrentChange(e){
			this.selected=e
		},
     initDialog(){
			this.$refs["addForm"].resetFields()
		},
    pageSizeChange(e){
			this.pageSize=e
			this.search()
		},
		//pageNum变化
		pageNumChange(e){
			this.pageNum=e
			this.search();
		},
    initDialog () {
      this.$refs["addForm"].resetFields()
    }
	}
}
</script>
<style lang="less" scoped>
.drawer_inner{
	width: 95%;
	height: 100%;
	margin: auto;
}
.cont_table /deep/ .el-icon-tickets{
	font-size: 20px;
	margin-top: 4px;
}
.tips{
	font-size: 8px;
	margin-left: 30px;
	color: #878787;
}
.tips>span{
	color: orangered !important;
}
</style>
