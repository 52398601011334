<template>
 <div id="dis_list" class="content" v-loading="loading" element-loading-text="加载中">
    <div class="content_left">
      <el-tree :data="packageTree" :props="defaultProps" @node-click="nodeClick"></el-tree>
    </div>
    <div class="content_right">
      <!-- 顶部操作栏 -->
      <div class="cont_bar">
        <div class="bar_left" style="width:100%;">
          <el-form :inline="true" :model="searchData" class="demo-form-inline" size="small">
            <el-form-item label="订单号：">
              <el-input v-model="searchData.orderSn" placeholder="请输入订单号" @change="search()" />
            </el-form-item>
            <el-form-item label="用户id：">
              <el-input v-model="searchData.userId" placeholder="请输入用户id" @change="search()" />
            </el-form-item>
            <el-form-item prop="orderStatus" label="订单状态：">
              <el-select v-model="searchData.orderStatus" placeholder="应用名称" :filterable="true" @change="search()">
                <el-option v-for="item in orderStatusList" :key="item.value" :label="item.label" :value="item.value">
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item prop="channel" label="渠道：">
              <el-select v-model="searchData.channel" placeholder="渠道" :filterable="true" @change="search()">
                <el-option label="全部" value=""></el-option>
                <el-option v-for="item in channelList" :key="item" :label="item" :value="item"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="时间：">
              <el-date-picker v-model="searchData.time" value-format="yyyy-MM-dd" :picker-options="pickerOptions"
                type="daterange" range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期" @change="setTime">
              </el-date-picker>
            </el-form-item>
          </el-form>
        </div>
      </div>
      <!-- 主内容区 -->
      <div class="cont_table">
        <!-- 表格 -->
        <el-table ref="goodsTables" id="goodsTables" :data="tableList" highlight-current-row show-summary
          :summary-method="getSummaries" @row-click="handleCurrentChange" width="100%" height="80%" size="mini">
          <el-table-column property="createTime" label="创建时间" min-width="170"></el-table-column>
          <el-table-column prop="userId" width="70" label="用户ID"></el-table-column>
          <el-table-column property="packageAlias" label="应用名称" min-width="150"></el-table-column>
          <el-table-column property="goodsTitle" label="Vip名称" min-width="100"></el-table-column>
          <el-table-column property="payType" label="支付方式" min-width="80">
            <template slot-scope="scope">
              <el-row class="row-op-text">
                {{scope.row.payType == 1 ? '微信' : scope.row.payType == 2 ? '支付宝': '苹果'  }}
              </el-row>
            </template>
          </el-table-column>
          <el-table-column property="sourceChannel" label="渠道" min-width="80"></el-table-column>
          <!-- <el-table-column property="version" label="版本号" min-width="80"></el-table-column> -->
          <el-table-column property="orderAmount" label="订单金额" min-width="120"></el-table-column>
          <el-table-column property="orderStatus" label="订单状态" min-width="80">
            <template slot-scope="scope">
              <el-row class="row-op-text" :style="scope.row.orderStatus == 1 ? 'color: green;': ''">
                {{scope.row.orderStatus == 0 ? '未支付' : scope.row.orderStatus == 1 ? '支付成功': scope.row.orderStatus == 10 ? '已退款': '已取消'  }}
              </el-row>
            </template>
          </el-table-column>
          <el-table-column property="orderSn" label="订单号" min-width="200"></el-table-column>
          <el-table-column property="tradeNo" label="三方单号" min-width="200"></el-table-column>
          <el-table-column property="payTime" label="支付时间" min-width="150"></el-table-column>
          <el-table-column label="操作" min-width="150" align="center">
            <template slot-scope="scope">
              <el-tooltip content="退款" placement="top" v-if="scope.row.orderStatus == 1">
                <el-button :loading="refundLoading" type="text" icon="el-icon-warning" size="medium"
                  @click="refund(scope.row.orderSn, scope.row.orderAmount)">退款</el-button>
              </el-tooltip>
            </template>
          </el-table-column>
        </el-table>
        <!-- 分页器 -->
        <div class="block">
          <el-pagination :current-page="pageNum" :page-sizes="[30,50,100,200]" :page-size="pageSize" :total="total"
            layout="total, sizes, prev, pager, next, jumper" @size-change="pageSizeChange"
            @current-change="pageNumChange">
          </el-pagination>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
  export default {
    name: 'OrderManagemment',
    //组件创建
    created() {
      this.searchData = {
        orderStatus: 1,
        startTime: this.getNextDate(new Date(), 0) + " 00:00:00",
        endTime: this.getNextDate(new Date(), 0) + " 23:59:59",
        time: [this.getNextDate(new Date(), 0), this.getNextDate(new Date(), 0)]
      }
      this.getDataList(),
      this.getPackageTree()
    },
    data() {
      return {
        refundLoading: false,
        pickerOptions: {
          disabledDate(time) {
            return time.getTime() > Date.now();
          },
          shortcuts: [{
            text: '昨天',
            onClick(picker) {
              const date = new Date();
              date.setTime(date.getTime() - 3600 * 1000 * 24);
              picker.$emit('pick', [date, date]);
            }
          }, {
            text: '前天',
            onClick(picker) {
              const date = new Date();
              date.setTime(date.getTime() - 3600 * 1000 * 24 * 2);
              picker.$emit('pick', [date, date]);
            }
          }, {
            text: '一周前',
            onClick(picker) {
              const date = new Date();
              date.setTime(date.getTime() - 3600 * 1000 * 24 * 7);
              picker.$emit('pick', [date, new Date()]);
            }
          }]
        },
        loading: false,
        pageSize: 30,
        pageNum: 1,
        total: 0,
        searchData: {
          orderStatus: 1,
          channel: ''
        },
        orderStatusList: [{
            label: '全部',
            value: null
          },
          {
            label: '已支付',
            value: 1
          },
          {
            label: '未支付',
            value: 0
          },
          {
            label: '已退款',
            value: 10
          },
          {
            label: '已取消',
            value: -1
          }
        ],
        selected: null, //当前选中行
        tableList: [],
        packageTree: [],
        channelList: [
          "huawei", "xiaomi", "oppo", "vivo", "honor", "samsung", "yingyongbao", "baidu", "wandoujia"
        ],
        defaultProps: {
          children: 'children',
          label: 'label'
        },
      }
    },
    methods: {
      getNextDate(date, day) {
      　　var dd = new Date(date);
      　　dd.setDate(dd.getDate() + day);
      　　var y = dd.getFullYear();
      　　var m = dd.getMonth() + 1 < 10 ? "0" + (dd.getMonth() + 1) : dd.getMonth() + 1;
      　　var d = dd.getDate() < 10 ? "0" + dd.getDate() : dd.getDate();
      　　return y + "-" + m + "-" + d;
      },
      getSummaries(param) {
        const {
          columns,
          data
        } = param;
        const sums = [];
        columns.forEach((column, index) => {
          if (index === 0) {
            sums[index] = '总计';
            return;
          } else if (index != 6) {
            sums[index] = '-';
            return;
          } else {
            const values = data.map(item => Number(item[column.property]));
            if (!values.every(value => isNaN(value))) {
              sums[index] = values.reduce((prev, curr) => {
                const value = Number(curr);
                if (!isNaN(value)) {
                  return prev + curr;
                } else {
                  return prev;
                }
              }, 0);
              sums[index] = sums[index].toFixed(2)
              sums[index] += ' 元';
            } else {
              sums[index] = 'N/A';
            }
          }
        });
        return sums;
      },
      setTime(e) {
        if (e) {
          this.searchData.startTime = e[0] + ' 00:00:00'
          this.searchData.endTime = e[1] + ' 23:59:59'
          this.searchData.time = [this.searchData.startTime, this.searchData.endTime]
        } else {
          this.searchData.startTime = null
          this.searchData.endTime = null
        };
        this.search()
      },
      search() {
        this.pageNum = 1
        this.getDataList()
      },
      nodeClick(data) {
        if (data.value != undefined) {
          if (data.value == '') {
            this.pageNum = 1
            this.searchData.packageName = ''
            this.searchData.packageSeries = ''
          } else {
            this.pageNum = 1
            // this.searchData = {}
            this.searchData.packageName = data.value
          }
        } else {
          this.searchData.packageName = ''
          this.searchData.packageSeries = data.label
        }
        this.getDataList()
      },
      getPackageTree() {
        let hostUrl = this.$service.publicUrl().PackageTree
        this.axios({
          method: "GET",
          url: hostUrl,
        }).then((response) => {
          this.loading = false
          if (response.data.status == 200) {
            this.packageTree = response.data.data
            this.packageTree.unshift({
              label: '全部',
              value: ''
            })
          } else {
            this.$message.warning("warning : " + response.data.message);
          }
          this.selected = null
        }).catch((error) => {
          this.loading = false
          this.$message.error("查询失败，error : " + JSON.stringify(error));
        })
      },
      getDataList() {
        let param = {
          ...this.searchData,
          pageNum: this.pageNum,
          pageSize: this.pageSize
        }
        let hostUrl = this.$service.publicUrl().VipOrder
        this.axios({
          method: "GET",
          url: hostUrl,
          params: param
        }).then((response) => {
          this.loading = false
          if (response.data.status == 200) {
            this.tableList = response.data.data.list
            this.total = response.data.data.total
          } else {
            this.$message.warning("warning : " + response.data.message);
          }
          this.selected = null
        }).catch((error) => {
          this.loading = false
          this.$message.error("查询失败，error : " + JSON.stringify(error));
        })
      },
      //点击行
      handleCurrentChange(e) {
        this.selected = e
      },
      initDialog() {
        this.$refs["addForm"].resetFields()
      },
      pageSizeChange(e) {
        this.pageSize = e
        this.getDataList()
      },
      //pageNum变化
      pageNumChange(e) {
        this.pageNum = e
        this.getDataList();
      },
      refund(orderSn, orderAmount) {
        var _this = this
        this.$confirm('确认将此订单退款吗？该操作不可逆，退款后，若需取消用户VIP权限，请到用户列表修改。', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          _this.refundLoading = true
          let param = {
            orderSn: orderSn,
            refundAmount: orderAmount,
          }
          let hostUrl = this.$service.publicUrl().OrderRefund
          this.axios({
            method: "POST",
            url: hostUrl,
            data: param
          }).then((response) => {
            _this.refundLoading = false
            if (response.data.status == 200) {
              _this.$message.success("退款成功");
              _this.pageNum = 1
              _this.getDataList()
            } else {
              this.$message.warning("warning : " + response.data.message);
            }
          }).catch((error) => {
            _this.refundLoading = false
            _this.loading = false
            _this.$message.error("退款失败，error : " + JSON.stringify(error));
          })
        }).catch(() => {
          _this.refundLoading = false
        });
      },
    }
  }
</script>
<style lang="less" scoped>
  /deep/.el-collapse-item__header {
    font-weight: 600 !important;
  }

  .cont_table {
    // height: 85%;
    // overflow-y: scroll;
  }

  .eltabs {
    height: 100%;
  }

  // .el-tabs__content {
  //   height: 100%;
  //   overflow-y: scroll;
  // }

  .content {
    display: flex;
    flex-direction: row;
    width: 100%;
    // justify-content: space-around;
  }
  .content_left {
    margin-right: 16px;
    padding-top: 21px;
    letter-spacing: 2px;
    width: 15%;
    height: calc(100% - 65px);
    background: #FFFFFF;
    overflow: auto
  }

  .content_right {
    width: 85%;
  }

  .cont_bar {
    display: flex;
  }
  .fixed {
    position: fixed;
    right: 20px;
    top: 200px;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    opacity: 0.6;
    background-color: #FFFFFF;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 11;
  }

  .block {
    margin-top: 5vh;
  }

  /deep/.bar_left .el-form-item__content {
    max-width: 150px;
  }
</style>
