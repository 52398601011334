import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '@/components/home.vue'
import Login from '@/components/login.vue'

//广告
import AdvertIndex from '@/view/advert/advertIndex.vue'
import AdvertConfig from '@/view/advert/advertConfig.vue'
import AdBoardRealTime from '@/view/advert/advertStatisticsReal.vue'

//业务配置
import BusinessBasic from '@/view/business/businessIndex.vue'
import PackageSeriesManage from '@/view/business/packageSeriesManage.vue'
import PackageManage from '@/view/business/packageManage.vue'
import ShareManage from '@/view/business/shareManage.vue'
import AppAgreementManage from '@/view/business/appAgreementManage.vue'
import AgreementDetail from '@/view/business/agreementDetail.vue'
import WebsiteConfig from '@/view/business/websiteConfig.vue'
import PaymentManage from '@/view/business/paymentManage.vue'
import ConfigPay from '@/view/business/configPay.vue'

//数据统计
import Statistics from '@/view/statistics/statisticsIndex.vue'
import DataBoard from '@/view/statistics/dataBoard.vue'
import DataStatistics from '@/view/statistics/dataStatistics.vue'
import AdStatistics from '@/view/statistics/adStatistics.vue'
import UserStatistics from '@/view/statistics/userStatistics.vue'
import UserStatisticsChannel from '@/view/statistics/userStatisticsChannel.vue'
import UserStatisticsChannelList from '@/view/statistics/userStatisticsChannelList.vue'
import UserStatisticsDetail from '@/view/statistics/userStatisticsDetail.vue'
import OrderStatistics from '@/view/statistics/orderStatistics.vue'

//数据管理
import DataIndex from '@/view/dataManage/dataIndex'
import UserManagement from '@/view/dataManage/userManagement'
import OrderManagement from '@/view/dataManage/orderManagement'
import LeaveWordsManagement from '@/view/dataManage/leaveWordsManagement'

//系统管理
import SystemIndex from '@/view/system/systemIndex.vue'
import SystemManage from '@/view/system/systemManage.vue'

//文档管理
import DocsIndex from '@/view/docs/docsIndex.vue'
import DocsManage from '@/view/docs/docsManage.vue'
// import DocsThirdProject from '@/view/docs/docsThirdProject.vue'

//客服
import CustomerIndex from '@/view/customer/CustomerIndex.vue'
import Chat from '@/view/customer/Chat.vue'

// 引入加载条
import NProgress from 'nprogress'
import 'nprogress/nprogress.css'
import dataBoardVue from '../view/statistics/dataBoard.vue'
// 配置
NProgress.inc()
NProgress.configure({
    easing: 'ease',  // 动画方式
    speed: 800,  // 递增进度条的速度
    showSpinner: false, // 是否显示加载ico
    trickleSpeed: 200, // 自动递增间隔
    minimum: 0.1 // 初始化时的最小百分比
})

Vue.use(VueRouter)
const routes = [
  { path: '/', redirect: '/home' },
	{ path: '/login',meta: { title: '登录'},component: Login},
    // 访问home时，重定向到wellcome页
    { path: '/home', name: 'home', component: Home, redirect: '/data_board', meta: {title: '首页'},
      children: [
        // 首页整体数据看板
        { path: '/advert', name: 'advert', component: AdvertIndex, meta: {title: '广告'},
          children: [
            { path: '/advert_config', name: 'advert_config', component: AdvertConfig, meta: {title: '广告配置'}},
            { path: '/ad_board_real_time', name: 'ad_board_real_time', component: AdBoardRealTime, meta: {title: '实时广告数据看板'}},
          ],
        },
        //数据统计模块
        { path: '/statistics', name: 'statistics', component: Statistics, redirect: '/data_statistics',meta: {title: '统计'},
          children: [
            { path: '/data_statistics', name: 'data_statistics', component: DataStatistics, meta: {title: '整体数据统计'}},
            { path: '/user_statistics', name: 'user_statistics', component: UserStatistics, meta: {title: '用户统计'}},
            { path: '/user_statistics_channel', name: 'user_statistics_channel', component: UserStatisticsChannel, meta: {title: '用户渠道统计详情'}},
            { path: '/user_statistics_channel_list', name: 'user_statistics_channel_list', component: UserStatisticsChannelList, meta: {title: '用户渠道统计列表'}},
            { path: '/user_statistics_detail', name: 'user_statistics_detail', component: UserStatisticsDetail, meta: {title: '用户统计详情'}},
            { path: '/order_statistics', name: 'order_statistics', component: OrderStatistics, meta: {title: '订单统计'}},
            { path: '/ad_statistics', name: 'ad_statistics', component: AdStatistics, meta: {title: '广告统计'}},
            { path: '/data_board', name: 'data_board', component: DataBoard, meta: {title: '首页'}},
          ],
        },
        //数据管理模块
        { path: '/data_index', name: 'data_index', component: DataIndex, redirect: '/user_management',meta: {title: '数据'},
          children: [
            // { path: '/user_info', name: 'user_info', component: UserManagement, meta: {title: '用户管理'}},
            { path: '/order_info', name: 'order_info', component: OrderManagement, meta: {title: '订单管理'}},
            { path: '/leave_words', name: 'leave_words', component: LeaveWordsManagement, meta: {title: '用户留言'}}
          ],
        },
        //业务配置模块
        { path: '/business_basic', name: 'business_basic', component: BusinessBasic, redirect: '/channel_manage',meta: {title: '应用基础配置'},
          children: [
            { path: '/package_group', name: 'package_group', component: PackageSeriesManage, meta: {title: '包系列管理'}},
            { path: '/package_info', name: 'package_info', component: PackageManage, meta: {title: '应用包管理'}},
            { path: '/app_share', name: 'app_share', component: ShareManage, meta: {title: '分享管理'}},
            { path: '/payment_manage', name: 'payment_manage', component: PaymentManage, meta: {title: '付费管理'}},
            { path: '/app_agreement', name: 'app_agreement', component: AppAgreementManage, meta: {title: 'app协议管理'}},
            { path: '/agreement_detail', name: 'agreement_detail', component: AgreementDetail, meta: {title: 'app协议详情'}},
            { path: '/website_config', name: 'website_config', component: WebsiteConfig, meta: {title: '官网配置'}},
            { path: '/config_pay', name: 'config_pay', component: ConfigPay, meta: {title: '支付参数配置'}}
          ],
        },
        // 文档管理
        { path: '/docs_index', name: 'docs_index', component: DocsIndex, redirect: '/docs_manage', meta: {title: '文档管理'},
          children: [
            { path: '/docs_manage', name: 'docs_manage', component: DocsManage, meta: {title: 'App配置文档管理'}},
            // { path: '/docs_third', name: 'docs_third', component: DocsThirdProject, meta: {title: '外包项目文档'}},
            // { path: '/app_status', name: 'app_status', component: AppStatusManage, meta: {title: 'App在架状态'}},
          ],
        },
        //客服
        { path: '/customer_index', name: 'customer_index', component: CustomerIndex, redirect: '/chat', meta: {title: '客服'},
          children: [
            { path: '/chat', name: 'chat', component: Chat, meta: {title: '客服'}},
          ],
        },
        //系统管理模块
        { path: '/system_index', name: 'system_index', component: SystemIndex, redirect: '/system_manage', meta: {title: '系统管理'},
          children: [
            { path: '/system_manage', name: 'system_manage', component: SystemManage, meta: {title: '系统设置'}},
          ],
        }
      ]
    }

]

const router = new VueRouter({
  routes
})

/**
 * 挂载路由守卫
 * to 代表将要访问的路径
 * from 代表从哪个路径跳转而来
 * next 是一个函数，next() 放行。   next('/login') 强制跳转
 */
router.beforeEach((to, from, next) => {
    // 加载条
    NProgress.start()

    //放行
    next()
})


// 挂载路由守卫 - 访问结束
router.afterEach(() => {
    // 关闭加载条
    NProgress.done()
})

export default router
