import store from '@/store'

const { body } = document
const WIDTH = 992 // refer to Bootstrap's responsive design

export default {
  watch: {
    $route(route) {
      if (this.device == 'mobile') {
        store.commit('isSideBar',false)
      }else{
        store.commit('isSideBar',true)
      }
    }
  },
  beforeMount() {
    window.addEventListener('resize', this.$_resizeHandler)
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.$_resizeHandler)
  },
  mounted() {
    const isMobile = this.$_isMobile()
    if (isMobile) {
      // store.dispatch('app/toggleDevice', 'mobile')
      store.commit('setDevice', 'mobile');
      store.commit('isSideBar',false)
      // store.dispatch('app/closeSideBar', { withoutAnimation: true })
    }else{
      // store.commit('setDevice', 'desktop');
      store.commit('isSideBar',true)
    }
  },
  methods: {
    // use $_ for mixins properties
    // https://vuejs.org/v2/style-guide/index.html#Private-property-names-essential
    $_isMobile() {
      const rect = body.getBoundingClientRect();
      // console.log(rect)
      return rect.width - 1 < WIDTH
    },
    $_resizeHandler() {
      if (!document.hidden) {
        const isMobile = this.$_isMobile()
        // store.dispatch('app/toggleDevice', isMobile ? 'mobile' : 'desktop')
        store.commit('setDevice', isMobile ? 'mobile' : 'desktop');
        if (isMobile) {
          // console.log(22222222)
          // store.dispatch('app/closeSideBar', { withoutAnimation: true })
          store.commit('isSideBar',false)
        }else{
          // console.log(111111)
          store.commit('isSideBar',true)
        }
      }
    }
  }
}
