<template>
  <div id="dis_list" class="content" v-loading="loading" element-loading-text="加载中">
    <div class="content_left">
      <el-tree :data="packageList" :props="defaultProps" @node-click="nodeClick"></el-tree>
    </div>
    <div class="content_right">
      <!-- 顶部操作栏 -->
      <div class="cont_bar">
        <div class="bar_left" style="width:80%;">
          <el-row >
            <el-form :inline="true" :model="searchData" size="small">
              <el-form-item label="公司 :" prop="companySoft">
                <el-select v-model="searchData.company" placeholder="请选择公司" @change="search">
                  <el-option :label="item.name" :value="item.name" v-for="(item, index) in companyList" :key="index"></el-option>
                </el-select>
              </el-form-item>
            </el-form>
          </el-row>
        </div>
        <div class="bar_right" style="width:20%;">
          <el-tooltip content="详情" placement="top">
            <el-button type="primary" icon="el-icon-plus" size="mini" @click="openForm('add')">新增</el-button>
          </el-tooltip>
        </div>
      </div>
      <!-- 主内容区 -->
      <div class="cont_table">
        <!-- 表格 -->
        <el-table size="mini" ref="goodsTable" id="goodsTable" :data="tableList" highlight-current-row width="100%"
          height="calc(100% - 150px)">
          <el-table-column label="操作" width="150" align="center">
            <template slot-scope="scope">
              <el-row class="row-op-text">
                <el-tooltip content="详情" placement="top">
                  <el-button type="text" icon="el-icon-document" size="medium"
                    @click="openForm('detail', scope.row)"></el-button>
                </el-tooltip>
                <el-tooltip content="修改" placement="top">
                  <el-button type="text" icon="el-icon-edit" size="medium"
                    @click="openForm('edit',scope.row)"></el-button>
                </el-tooltip>
                <el-tooltip content="删除" placement="top">
                  <el-button type="text" icon="el-icon-delete" size="medium"
                    @click="deleteData(scope.row.id)"></el-button>
                </el-tooltip>
              </el-row>
            </template>
          </el-table-column>
          <el-table-column prop="docCode" width="100" label="编号"></el-table-column>
          <el-table-column property="packageName" label="应用包名" min-width="150"></el-table-column>
          <el-table-column property="packageAlias" label="产品名称"></el-table-column>
          <el-table-column property="registerName" label="备案名称"></el-table-column>
          <el-table-column property="registerCode" label="备案号" min-width="150"></el-table-column>
          <el-table-column property="companySoft" label="软著公司" min-width="150"></el-table-column>
        </el-table>
        <!-- 分页器 -->
        <div class="block">
          <el-pagination :current-page="pageNum" :page-sizes="[30, 50, 100]" :page-size="pageSize" :total="total"
            layout="total, sizes, prev, pager, next, jumper" @size-change="pageSizeChange"
            @current-change="pageNumChange">
          </el-pagination>
        </div>
      </div>
    </div>
    <el-dialog :title="formTitle" :visible.sync="formVisible" width="70%" :center="true" @close="initDialog()"
      :close-on-click-modal="false" class="add-form">
      <el-form :model="formData" ref="addForm" :rules="addRules" class="add_form" label-width="120px"
        label-position="left">
        <el-row type="flex" justify="space-between">
          <el-col :span="10">
            <el-form-item label="应用包名 :" prop="packageName">
              <el-input :disabled="this.formType == 'edit'" v-model="formData.packageName"
                autocomplete="off"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="10">
            <el-form-item label="产品系列 :" prop="packageSeries">
              <el-select v-model="formData.packageSeries" filterable placeholder="请选择应用包系列">
                <el-option :label="item.packageGroupName" :value="item.packageGroupName"
                  v-for="(item, index) in seriesList" :key="index"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row type="flex" justify="space-between">
          <el-col :span="10">
            <el-form-item label="应用名称 :" prop="packageAlias">
              <el-input v-model="formData.packageAlias" autocomplete="off"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="10">
            <el-form-item label="项目类型：" prop="docType">
              <el-select :disabled="this.formType == 'edit'" v-model="formData.docType">
                <el-option label="自营项目" value="ZY"></el-option>
                <el-option label="自营外包项目" value="ZW"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row type="flex" justify="space-between">
          <el-col :span="10">
            <el-form-item label="备案名称 :" prop="registerName">
              <el-input v-model="formData.registerName" autocomplete="off"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="10">
            <el-form-item label="备案号：" prop="registerCode">
              <el-input v-model="formData.registerCode" autocomplete="off"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row type="flex" justify="space-between" v-show="userInfo.userLevel == 0">
          <el-col :span="24">
            <el-form-item v-show="userInfo.userLevel == 0" label="绑定用户 :" label-width="100px" prop="adminIdList">
              <el-checkbox-group v-model="formData.adminIdList">
                <el-checkbox v-for="admin in adminList" :key="admin.adminId" :label="admin.adminId"
                  :value="admin.adminId">
                  {{admin.userName}}</el-checkbox>
              </el-checkbox-group>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row type="flex" justify="space-between">
          <el-col :span="10">
            <el-form-item label="软著名称 :" prop="softName">
              <el-input v-model="formData.softName" autocomplete="off"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="10">
            <el-form-item label="软著所属公司 :" prop="companySoft">
              <el-select v-model="formData.companySoft" placeholder="请选择公司">
                <el-option v-show="userInfo.userLevel == 0 || userInfo.userLevel == item.level" :label="item.name"
                  :value="item.name" v-for="(item, index) in companyList" :key="index"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row type="flex" justify="space-between">
          <el-col :span="10">
            <el-form-item label="应用签名MD5 :" prop="appMd5">
              <el-input v-model="formData.appMd5" autocomplete="off"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="10">
            <el-form-item label="安全码SHA1 :" prop="appSha1">
              <el-input v-model="formData.appSha1" autocomplete="off"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="24">
            <el-form-item label="证书指纹sha256 :" prop="appSha256">
              <el-input v-model="formData.appSha256" autocomplete="off"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="24">
            <el-form-item label="App公钥 :" prop="appSha256">
              <el-input type="textarea" :autosize="{ minRows: 4, maxRows: 10 }" v-model="formData.appPublicKey"
                autocomplete="off"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="24">
            <el-form-item label="隐私协议 :" prop="agreementPrivacy">
              <el-input v-model="formData.agreementPrivacy" autocomplete="off"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="24">
            <el-form-item label="用户协议 :" prop="agreementUser">
              <el-input v-model="formData.agreementUser" autocomplete="off"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row type="flex" justify="space-between">
          <el-col :span="10">
            <el-form-item label="QQ-AppId :" prop="appIdQq">
              <el-input v-model="formData.appIdQq" autocomplete="off"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="10">
            <el-form-item label="QQ-AppKey :" prop="appSecretQq">
              <el-input v-model="formData.appSecretQq" autocomplete="off"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row type="flex" justify="space-between">
          <el-col :span="10">
            <el-form-item label="QQ所属公司 :" prop="companyQq">
              <el-select v-model="formData.companyQq" placeholder="请选择公司">
                <el-option v-show="userInfo.userLevel == 0 || userInfo.userLevel == item.level" :label="item.name"
                  :value="item.name" v-for="(item, index) in companyList" :key="index"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="10">
            <el-form-item label="微信支付所属公司 :" prop="companyWechat">
              <el-select v-model="formData.companyWechat" placeholder="请选择公司">
                <el-option v-show="userInfo.userLevel == 0 || userInfo.userLevel == item.level" :label="item.name"
                  :value="item.name" v-for="(item, index) in companyList" :key="index"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row type="flex" justify="space-between">
          <el-col :span="10">
            <el-form-item label="微信appkey :" prop="appKeyWechat">
              <el-input v-model="formData.appKeyWechat" autocomplete="off"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="10">
            <el-form-item label="微信appSecret :" prop="appSecretWechat">
              <el-input v-model="formData.appSecretWechat" autocomplete="off"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row type="flex" justify="space-between">
          <el-col :span="10">
            <el-form-item label="支付宝appId :" prop="appIdAlipay">
              <el-input v-model="formData.appIdAlipay" autocomplete="off"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="10">
            <el-form-item label="支付宝所属公司 :" prop="companyAlipay">
              <el-select v-model="formData.companyAlipay" placeholder="请选择公司">
                <el-option v-show="userInfo.userLevel == 0 || userInfo.userLevel == item.level" :label="item.name"
                  :value="item.name" v-for="(item, index) in companyList" :key="index"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="24">
            <el-form-item label="支付宝支付公钥 :" prop="appKeyAlipay">
              <el-input type="textarea" :autosize="{ minRows: 4, maxRows: 10 }" v-model="formData.appKeyAlipay"
                autocomplete="off"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="24">
            <el-form-item label="支付宝应用公钥 :" prop="appPublicKeyAlipay">
              <el-input type="textarea" :autosize="{ minRows: 4, maxRows: 10 }" v-model="formData.appPublicKeyAlipay"
                autocomplete="off"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="24">
            <el-form-item label="支付宝应用私钥 :" prop="appPrivateKeyAlipay">
              <el-input type="textarea" :autosize="{ minRows: 6, maxRows: 10 }" v-model="formData.appPrivateKeyAlipay"
                autocomplete="off"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row type="flex" justify="space-between">
          <el-col :span="10">
            <el-form-item label="穿山甲AppId :" prop="appIdAd">
              <el-input v-model="formData.appIdAd" autocomplete="off"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="10">
            <el-form-item label="穿山甲公司 :" prop="companyAd">
              <el-select v-model="formData.companyAd" placeholder="请选择公司">
                <el-option v-show="userInfo.userLevel == 0 || userInfo.userLevel == item.level" :label="item.name"
                  :value="item.name" v-for="(item, index) in companyList" :key="index"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row type="flex" justify="space-between">
          <el-col :span="10">
            <el-form-item label="友盟Appkey :" prop="appIdUmeng">
              <el-input v-model="formData.appIdUmeng" autocomplete="off"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="10">
            <el-form-item label="高德Appkey :" prop="appKeyAmap">
              <el-input v-model="formData.appKeyAmap" autocomplete="off"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row type="flex" justify="space-between">
          <el-col :span="10">
            <el-form-item label="天气Appkey :" prop="appKeyWeather">
              <el-input v-model="formData.appKeyWeather" autocomplete="off"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="10">
            <el-form-item label="短信Appkey :" prop="appKeyMessage">
              <el-input v-model="formData.appKeyMessage" autocomplete="off"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="24">
            <el-form-item label="备注 :" prop="remark">
              <el-input type="textarea" :autosize="{ minRows: 6, maxRows: 10 }" v-model="formData.remark"
                autocomplete="off"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <el-row type="flex" class="row-bg" justify="end">
        <el-col :span="13">
          <el-button @click="formVisible = false">取 消</el-button>
          <el-button type="primary" @click="confirm()">确 定</el-button>
        </el-col>
      </el-row>
    </el-dialog>
    <el-dialog :title="detailTitle" :visible.sync="detailVisible" width="70%" class="detail-form">
      <el-row type="flex" justify="space-between">
        <el-col :span="4">应用包名 :</el-col>
        <el-col :span="8">{{selected.packageName}}</el-col>
        <el-col :span="4">产品系列 :</el-col>
        <el-col :span="8">{{selected.packageSeries}}</el-col>
      </el-row>
      <el-row type="flex" justify="space-between">
        <el-col :span="4">应用名称 :</el-col>
        <el-col :span="8">{{selected.packageAlias}}</el-col>
        <el-col :span="4">项目类型 :</el-col>
        <el-col :span="8">{{selected.docType == 'ZY' ? '自营项目' : '自营外包项目'}}</el-col>
      </el-row>
      <el-row type="flex" justify="space-between">
        <el-col :span="4">备案名称 :</el-col>
        <el-col :span="8">{{selected.registerName}}</el-col>
        <el-col :span="4">备案号 :</el-col>
        <el-col :span="8">{{selected.registerCode}}</el-col>
      </el-row>
      <el-row type="flex" justify="space-between" v-show="userInfo.userLevel == 0">
        <el-col :span="4">应用名称 :</el-col>
        <el-col :span="20">
          <el-checkbox-group v-model="formData.adminIdList" :disabled="true">
            <el-checkbox v-for="admin in adminList" :key="admin.adminId" :label="admin.adminId" :value="admin.adminId">
              {{admin.userName}}</el-checkbox>
          </el-checkbox-group>
        </el-col>
      </el-row>
      <el-row type="flex" justify="space-between">
        <el-col :span="4">软著名称 :</el-col>
        <el-col :span="8">{{selected.softName}}</el-col>
        <el-col :span="4">软著所属公司 :</el-col>
        <el-col :span="8">{{selected.companySoft}}</el-col>
      </el-row>
      <el-row type="flex" justify="space-between">
        <el-col :span="4">应用签名MD5 :</el-col>
        <el-col :span="8">{{selected.appMd5}}</el-col>
        <el-col :span="4">安全码SHA1 :</el-col>
        <el-col :span="8">{{selected.appSha1}}</el-col>
      </el-row>
      <el-row>
        <el-col :span="4">证书指纹SHA256 :</el-col>
        <el-col :span="20">{{selected.appSha256}}</el-col>
      </el-row>
      <el-row>
        <el-col :span="4">App公钥 :</el-col>
        <el-col :span="20">{{selected.appPublicKey}}</el-col>
      </el-row>
      <el-row>
        <el-col :span="4">隐私协议 :</el-col>
        <el-col :span="20">{{selected.agreementPrivacy}}</el-col>
      </el-row>
      <el-row>
        <el-col :span="4">用户协议 :</el-col>
        <el-col :span="20">{{selected.agreementUser}}</el-col>
      </el-row>
      <el-row type="flex" justify="space-between">
        <el-col :span="4">QQ-AppId :</el-col>
        <el-col :span="8">{{selected.appIdQq}}</el-col>
        <el-col :span="4">QQ-AppKey :</el-col>
        <el-col :span="8">{{selected.appSecretQq}}</el-col>
      </el-row>
      <el-row type="flex" justify="space-between">
        <el-col :span="4">QQ所属公司 :</el-col>
        <el-col :span="8">{{selected.companyQq}}</el-col>
        <el-col :span="4">微信支付所属公司 :</el-col>
        <el-col :span="8">{{selected.companyWechat}}</el-col>
      </el-row>
      <el-row type="flex" justify="space-between">
        <el-col :span="4">微信appkey :</el-col>
        <el-col :span="8">{{selected.appKeyWechat}}</el-col>
        <el-col :span="4">微信appSecret :</el-col>
        <el-col :span="8">{{selected.appSecretWechat}}</el-col>
      </el-row>
      <el-row type="flex" justify="space-between">
        <el-col :span="4">微信商户ID:</el-col>
        <el-col :span="8">{{selected.mchIdWechat}}</el-col>
        <el-col :span="4">微信商户密钥 :</el-col>
        <el-col :span="8">{{selected.mchKeyWechat}}</el-col>
      </el-row>
      <el-row type="flex" justify="space-between">
        <el-col :span="4">支付宝appId :</el-col>
        <el-col :span="8">{{selected.appIdAlipay}}</el-col>
        <el-col :span="4">支付宝所属公司 :</el-col>
        <el-col :span="8">{{selected.companyAlipay}}</el-col>
      </el-row>
      <el-row>
        <el-col :span="4">支付宝支付公钥：</el-col>
        <el-col :span="20">{{selected.appKeyAlipay}}</el-col>
      </el-row>
      <el-row>
        <el-col :span="4">支付宝应用公钥：</el-col>
        <el-col :span="20">{{selected.appPublicKeyAlipay}}</el-col>
      </el-row>
      <el-row>
        <el-col :span="4">支付宝应用私钥：</el-col>
        <el-col :span="20">{{selected.appPrivateKeyAlipay}}</el-col>
      </el-row>
      <el-row type="flex" justify="space-between">
        <el-col :span="4">穿山甲AppId :</el-col>
        <el-col :span="8">{{selected.appIdAd}}</el-col>
        <el-col :span="4">穿山甲公司 :</el-col>
        <el-col :span="8">{{selected.companyAd}}</el-col>
      </el-row>
      <el-row type="flex" justify="space-between">
        <el-col :span="4">友盟Appkey :</el-col>
        <el-col :span="8">{{selected.appIdUmeng}}</el-col>
        <el-col :span="4">高德Appkey :</el-col>
        <el-col :span="8">{{selected.appKeyAmap}}</el-col>
      </el-row>
      <el-row type="flex" justify="space-between">
        <el-col :span="4">天气Appkey :</el-col>
        <el-col :span="8">{{selected.appKeyWeather}}</el-col>
        <el-col :span="4">短信Appkey :</el-col>
        <el-col :span="8">{{selected.appKeyMessage}}</el-col>
      </el-row>
      <el-row>
        <el-col :span="4">备注：</el-col>
        <el-col :span="20">{{selected.remark}}</el-col>
      </el-row>
    </el-dialog>
  </div>
</template>
<script>
  export default {
    name: 'DocsManage',
    //组件创建
    created() {
      this.getDataList(),
        this.getPackageList(),
        this.getSeriesList()
      this.userInfo = JSON.parse(sessionStorage.getItem("userInfo"))
      if (this.userInfo.userLevel == 0) {
        this.getAdminList()
      }
    },
    data() {
      return {
        userInfo: {},
        defaultProps: {
          children: 'children',
          label: 'label'
        },
        loading: false,
        formType: 'add',
        formVisible: false,
        detailVisible: false,
        detailTitle: '',
        formTitle: '',
        selected: {},
        formData: {
          adminIdList: []
        },
        adminList: [],
        tableList: [], //tab列表
        searchData: {},
        packageList: [],
        seriesList: [],
        companyList: [{
            name: '成都初代科技有限公司',
            wechatMchId: '',
            wechatMchKey: '',
            level: 10
          },
          {
            name: '成都哆萤科技有限公司',
            wechatMchId: '',
            wechatMchKey: '',
            level: 10
          },
          {
            name: '成都威仕多网络科技有限公司',
            wechatMchId: '',
            wechatMchKey: ''
          },
          {
            name: '成都璟智信息技术有限公司',
            wechatMchId: '',
            wechatMchKey: ''
          }
        ],
        pageNum: 1,
        pageSize: 30,
        total: 0,
        addRules: {
          packageAlias: [{
            required: true,
            message: '应用名称不能为空'
          }],
          packageName: [{
            required: true,
            message: '应用包名不能为空'
          }],
          packageSeries: [{
            required: true,
            message: '应用包系列不能为空'
          }],
          docType: [{
            required: true,
            message: '项目类型不能为空'
          }],
        },
      }
    },
    methods: {
      // selectWechat(e) {
      //   this.companyList.forEach(item => {
      //     if (item.name == e) {
      //       this.formData.mchIdWechat = item.wechatMchId
      //       this.formData.mchKeyWechat = item.wechatMchKey
      //     }
      //   })
      // },
      getAdminList() {
        var _this = this
        let hostUrl = this.$service.publicUrl().AdminList
        this.axios({
          method: "GET",
          url: hostUrl,
        }).then((response) => {
          this.loading = false
          if (response.data.status == 200) {
            _this.adminList = response.data.data
          } else {
            this.$message.warning("warning : " + response.data.message);
          }
        }).catch((error) => {
          this.loading = false
          this.$message.error("查询失败，error : " + JSON.stringify(error));
        })
      },
      getPackageList() {
        let hostUrl = this.$service.publicUrl().PackageTree
        this.axios({
          method: "GET",
          url: hostUrl,
        }).then((response) => {
          if (response.data.status == 200) {
            this.packageList = response.data.data
            this.packageList.unshift({
              label: '全部',
              value: ''
            })
          } else {
            this.$message.warning("warning : " + response.data.message);
          }
        }).catch((error) => {
          this.loading = false
          this.$message.error("查询失败，error : " + JSON.stringify(error));
        })
      },
      getSeriesList() {
        let hostUrl = this.$service.publicUrl().PackageSeriesList
        this.axios({
          method: "GET",
          url: hostUrl,
        }).then((response) => {
          this.loading = false
          if (response.data.status == 200) {
            this.seriesList = response.data.data
          } else {
            this.$message.warning("warning : " + response.data.message);
          }
        }).catch((error) => {
          this.loading = false
          this.$message.error("查询失败，error : " + JSON.stringify(error));
        })
      },
      search(company) {
        this.pageNum = 1
        this.searchData.company = company
        this.getDataList()
      },
      qimai() {
        window.open("https://www.qimai.cn/search/android/market/6/search/" + this.channelVersionList[0].packageName)
      },
      nodeClick(data) {
        if (data.value != undefined) {
          this.searchData.packageName = data.value
          this.getDataList()
        }
      },
      //列表查询
      getDataList() {
        let param = {
          pageNum: this.pageNum,
          pageSize: this.pageSize,
          ...this.searchData,
        }
        let hostUrl = this.$service.publicUrl().AppDocList
        this.axios({
          method: "GET",
          url: hostUrl,
          params: param
        }).then((response) => {
          this.loading = false
          if (response.data.status == 200) {
            this.tableList = response.data.data.list
            this.total = response.data.data.total
          } else {
            this.$message.warning("warning : " + response.data.message);
          }
        }).catch((error) => {
          this.loading = false
          this.$message.error("查询失败，error : " + JSON.stringify(error));
        })
      },
      openForm(formType, row) {
        this.formType = formType;
        if (this.formType == 'add') {
          this.formVisible = true
          this.formData = {
            adminIdList: []
          }
          this.formTitle = '新增'
        } else if (this.formType == 'edit') {
          this.formVisible = true
          this.formData = {
            ...row
          }
          this.formTitle = row.packageAlias + '_' + row.docCode
        } else {
          this.selected = row
          this.detailVisible = true
          this.detailTitle = row.packageAlias + '_' + row.docCode
        }
      },
      confirm() {
        this.$refs["addForm"].validate((valid) => {
          if (valid) {
            let param = {
              ...this.formData,
            }
            let hostUrl
            let desc
            if (this.formType == 'add') {
              hostUrl = this.$service.publicUrl().AppDocAdd
              desc = '新增'
            } else if (this.formType == 'edit') {
              hostUrl = this.$service.publicUrl().AppDocUpdate
              desc = '修改'
            }
            this.axios({
              method: this.formType == 'add' ? "POST" : "PUT",
              url: hostUrl,
              data: param
            }).then((response) => {
              this.loading = false
              if (response.data.status == 200) {
                this.$message.success(desc + "成功");
                this.getDataList()
              } else {
                this.$message.warning("warning : " + response.data.message);
              }
              this.formVisible = false
            }).catch((error) => {
              this.loading = false
              this.$message.error(desc + "失败，error : " + JSON.stringify(error));
            })
          }
        })
      },
      deleteData(id) {
        this.$confirm('确认删除该条数据?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          let param = {
            id: id,
          }
          let hostUrl = this.$service.publicUrl().AppDocDelete
          this.axios({
            method: "DELETE",
            url: hostUrl,
            params: param
          }).then((response) => {
            if (response.data.status == 200) {
              this.getDataList()
            } else {
              this.$message.warning("warning : " + response.data.message);
            }
          }).catch((error) => {
            this.loading = false
            this.$message.error("删除失败，error : " + JSON.stringify(error));
          })
        }).catch(() => {});
      },
      compareDate(DateOne, DateTwo) {
        if (DateOne == undefined || DateTwo == undefined) {
          return 0
        }
        var time1 = new Date(DateOne.replace(/-/g, "/")).getTime();
        ///pattern/是正则表达式的界定符，里面的内容(pattern)是要匹配的内容
        //g 代表全局，所有的- 都替换
        //getTime() 方法可返回距 1970 年 1 月 1 日之间的毫秒数，用于比较时间。
        var time2 = new Date(DateTwo.replace(/-/g, "/")).getTime();

        if (time1 < time2) { //说明DateOne早于DateTwo
          return -1;
        } else {
          if (time1 > time2) {
            return 1
          } else {
            return 0
          }
        }
      },
      initDialog() {
        this.$refs["addForm"].resetFields()
      },
      initDialog1() {
        this.$refs["addForm1"].resetFields()
      },
      pageSizeChange(e) {
        this.pageSize = e
        this.search(null)
      },
      //pageNum变化
      pageNumChange(e) {
        this.pageNum = e;
        this.getDataList();
      },
    }
  }
</script>
<style lang="less" scoped>
  .drawer_inner {
    width: 95%;
    height: 100%;
    margin: auto;
  }

  .cont_table {
    height: 100%;
  }

  .table-box {
    height: calc(100% - 150px);
  }

  .cont_table /deep/ .el-icon-tickets {
    font-size: 20px;
    // margin-top: 4px;
  }

  .tips {
    font-size: 8px;
    margin-left: 30px;
    color: #878787;
  }

  .tips>span {
    color: orangered !important;
  }

  .content {
    display: flex;
    flex-direction: row;
    width: 100%;
    // justify-content: space-around;
  }

  .content_left {
    margin-right: 16px;
    padding-top: 21px;
    letter-spacing: 2px;
    width: 15%;
    height: calc(100% - 65px);
    background: #FFFFFF;
    overflow: auto;
  }

  .content_right {
    width: 85%;
  }

  .cont_bar {
    display: flex;
  }

  .add-form {
    .el-row {
      margin-bottom: 0.5rem;
    }
  }

  .detail-form {
    .el-row {
      margin-bottom: 2.5rem;
    }
  }

  .el-form-item {
    .el-select {
      width: 100%;
    }
  }
</style>
<style>
  .el-tree-node__content {
    height: 40px !important;
  }

  .el-table {
    overflow: visible !important;
  }
</style>
