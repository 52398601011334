<template>
  <div id="dis_list" class="content" v-loading="loading" element-loading-text="加载中">
    <div>
      <div class="bar_left" style="width: 100%">
        <el-form :inline="true" :model="searchData" class="demo-form-inline" size="small">
          <el-form-item label="应用名称：">
            <el-select collapse-tags v-model="searchData.packageName" placeholder="应用名称" :filterable="true"
              @change="reloadData">
              <el-option v-for="item in packageList" :key="item.packageName" :label="item.packageAlias"
                :value="item.packageName">
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="统计维度：">
            <el-select v-model="chartsType" placeholder="统计维度" @change="initEchars">
              <el-option v-for="item in chartsTypeList" :key="item.id" :label="item.label" :value="item.id"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="日期：">
            <el-date-picker v-model="searchData.time" value-format="yyyy-MM-dd" :picker-options="pickerOptions"
              type="daterange" range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期" @change="setTime">
            </el-date-picker>
          </el-form-item>
        </el-form>
      </div>

    </div>
    <!-- 主内容区 -->
    <div class="cont_table">
      <div class="charts">
        <div id="char" style="height:500px;"></div>
      </div>
      <el-table :stripe="true" size="mini" ref="goodsTable" id="goodsTable" :data="tableData" highlight-current-row
        width="100%">
        <el-table-column property="recordDate" label="日期" min-width="150">
          <!-- <template slot-scope="scope">
            <label>{{scope.row.recordDate.substr(0, 10)}}</label>
          </template> -->
        </el-table-column>
        <el-table-column property="newUser" label="新增用户"></el-table-column>
        <el-table-column property="activeUser" label="活跃用户"></el-table-column>
        <el-table-column property="launchTimes" label="运行次数"></el-table-column>
        <el-table-column property="registerUser" label="注册用户"></el-table-column>
        <el-table-column property="totalUser" label="总用户"></el-table-column>
      </el-table>
    </div>
  </div>
</template>
<script>
  import * as echarts from 'echarts';
  export default {
    name: 'UserStatisticsDetail',
    //组件创建
    created() {
      this.startDate = this.getNextDate(new Date(), 0)
      this.endDate = this.startDate

      this.searchData = {
        startDate: this.startDate + " 00:00:00",
        endDate: this.endDate + " 00:00:00",
        time: [
          this.startDate, this.endDate,
        ]
      }
      this.getPackageList()
    },
    data() {
      return {
        title: '广告收益',
        loading: false,
        chartsType: 1,
        now: "",
        chart: "",
        chartsTypeList: [{
            id: 1,
            label: '新增用户'
          },
          {
            id: 2,
            label: '活跃用户'
          },
          {
            id: 3,
            label: '运行次数'
          }
        ],
        packageList: [],
        searchData: {},
        pickerOptions: {
          disabledDate(time) {
            return time.getTime() > Date.now() - 3600 * 1000 * 24;
          },
          shortcuts: [{
            text: '今天',
            onClick(picker) {
              const date = new Date();
              date.setTime(date.getTime());
              picker.$emit('pick', [date, date]);
            }
          }]
        },
        dataType: "hour",
        dataTotal: [],
        tableData: [],
        dataList: [],
        xData: [],
      }
    },
    mounted() {
      this.searchData.packageName = this.$route.params.packageName
    },
    methods: {
      getNextDate(date, day) {
      　　var dd = new Date(date);
      　　dd.setDate(dd.getDate() + day);
      　　var y = dd.getFullYear();
      　　var m = dd.getMonth() + 1 < 10 ? "0" + (dd.getMonth() + 1) : dd.getMonth() + 1;
      　　var d = dd.getDate() < 10 ? "0" + dd.getDate() : dd.getDate();
      　　return y + "-" + m + "-" + d;
      },
      reloadData () {
        if (this.dataType == 'day') {
          this.getDataListDay()
        } else {
          this.getDataListHour()
        }
      },
      initEchars() {
        if (this.dataType == 'day') {
          this.xData = this.calcDate (this.searchData.startDate.substr(0, 10), this.searchData.endDate.substr(0, 10))
        } else {
          this.xData = []
          for(let i = 1; i <= 24; i++) {
            let time = i < 10 ? ('0' + i + ":00") : (i + ":00")
            this.xData.push(time)
          }
        }

        let series = []
        for (let i = this.dataList.length - 1; i >= 0; i--) {
          let item = this.dataList[i]
          let yData = {
              name: item.sourceChannel,
              type: 'line',
              smooth: true,
              data: []
            }
          for (let j = 0; j < item.dataList.length; j++) {
            switch (this.chartsType) {
              case 1:
                yData.data.push(item.dataList[j].newUser)
                break
              case 2:
                yData.data.push(item.dataList[j].activeUser)
                break
              case 3:
                yData.data.push(item.dataList[j].launchTimes)
                break
            }
          }
          series.push(yData)

        }
        console.log((series))
        var chartDom = document.getElementById('char');
        this.chart = echarts.init(chartDom);
        var option = {
          title: {
            text: this.chartsTypeList[this.chartsType - 1].label
          },
          tooltip: {
            trigger: 'axis'
          },
          legend: {
            data: ["huawei", "xiaomi", "oppo", "vivo", "honor", "samsung", "yingyongbao", "baidu", "wandoujia"]
          },
          grid: {
            left: '3%',
            right: '4%',
            bottom: '3%',
            containLabel: true
          },
          xAxis: {
            type: 'category',
            boundaryGap: false,
            // data: xData
            data: this.xData
          },
          yAxis: {
            type: 'value'
          },
          series: series
        };

        option && this.chart.setOption(option, true);
      },
      changePackage(e) {
        console.log(e)
      },
      refresh() {
        this.getDataList()
      },
      setTime(e) {
        if (e) {
          this.searchData.startDate = e[0] + ' 00:00:00'
          this.searchData.endDate = e[1] + ' 00:00:00'
          this.calcDate(e[0], e[1])
          if (e[0] == e[1]) {
            this.dataType = 'hour'
          } else {
            this.dataType = 'day'
          }
        } else {
          this.searchData.startDate = null
          this.searchData.endDate = null
        }
        this.reloadData()
      },
      cancel(e) {
        console.log(e)
      },
      getDataListDay() {
        let param = JSON.parse(JSON.stringify(this.searchData))
        delete param['time']
        let hostUrl = this.$service.publicUrl().UserChannelStatisticsDay
        this.axios({
          method: "GET",
          url: hostUrl,
          params: param,
        }).then((response) => {
          this.loading = false
          if (response.data.status == 200) {
            this.dataList = response.data.data
            this.tableData = response.data.data
            console.log(this.dataList)
            this.initEchars()
          } else {
            this.$message.warning("warning : " + response.data.message);
          }
        })
      },
      getDataListHour() {
        let param = JSON.parse(JSON.stringify(this.searchData))
        delete param['time']
        let hostUrl = this.$service.publicUrl().UserChannelStatisticsHour
        this.axios({
          method: "GET",
          url: hostUrl,
          params: param,
        }).then((response) => {
          this.loading = false
          if (response.data.status == 200) {
            this.dataList = response.data.data
            this.tableData = response.data.data
            this.initEchars()
          } else {
            this.$message.warning("warning : " + response.data.message);
          }
        })
      },
      getPackageList() {
        let hostUrl = this.$service.publicUrl().PackageListAll
        this.axios({
          method: "GET",
          url: hostUrl,
        }).then((response) => {
          this.loading = false
          if (response.data.status == 200) {
            this.packageList = response.data.data
            console.log(this.packageList[0])
            console.log(this.packageList[1])
            if (!this.searchData.packageName) {
              this.searchData.packageName = this.packageList[0].packageName
            }
            this.getDataListHour()
          } else {
            this.$message.warning("warning : " + response.data.message);
          }
        }).catch((error) => {
          this.loading = false
          this.$message.error("查询失败，error : " + JSON.stringify(error));
        })
      },
      calcDate(startDate, endDate) {

        //初始化日期列表，数组
        var diffdate = new Array();
        var i = 0;
        //开始日期小于等于结束日期,并循环
        while (startDate <= endDate) {
          diffdate[i] = startDate;
          //获取开始日期时间戳
          var startDate_ts = new Date(startDate).getTime();
          //增加一天时间戳后的日期
          var next_date = startDate_ts + (24 * 60 * 60 * 1000);

          //拼接年月日，这里的月份会返回（0-11），所以要+1
          var next_dates_y = new Date(next_date).getFullYear() + '-';
          var next_dates_m = (new Date(next_date).getMonth() + 1 < 10) ? '0' + (new Date(next_date).getMonth() + 1) +
            '-' : (new Date(next_date).getMonth() + 1) + '-';
          var next_dates_d = (new Date(next_date).getDate() < 10) ? '0' + new Date(next_date).getDate() : new Date(
            next_date).getDate();

          startDate = next_dates_y + next_dates_m + next_dates_d;

          //增加数组key
          i++;
        }
        return diffdate
      }
    }
  }
</script>
<style lang="less" scoped>
  .charts {
    background: #FFFFFF;
    width: 98%;
    padding-right: 5%;
    // display: flex;
    height: 500px;
    margin-top: 10px;
    padding-top: 15px;
  }

  .ranking-item {
    display: flex;
  }

  // .el-main {
  //   padding-top: 0px;
  //   height: 80vh;
  // }
  /deep/.bar_left .el-form-item__content {
    max-width: 190px;
  }

  .cont_bars {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    // overflow-x: auto;
  }
</style>
