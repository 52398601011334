<template>
  <div id="dis_list" class="content" v-loading="loading" element-loading-text="加载中">
    <div class="content_left">
      <el-tree :data="packageList" :props="defaultProps" @node-click="nodeClick"></el-tree>
    </div>
    <div class="content_right">
      <!-- 顶部操作栏 -->
      <div class="cont_bar">
        <div class="bar_left" style="width:80%;">
          <el-form :inline="true" :model="searchData" class="demo-form-inline" size="small">
            <el-form-item label="时间: ">
              <el-date-picker v-model="searchData.time" value-format="yyyy-MM-dd"
                :picker-options="pickerOptions" type="daterange" range-separator="至" start-placeholder="开始日期"
                end-placeholder="结束日期" @change="setTime">
              </el-date-picker>
            </el-form-item>
          </el-form>
        </div>
        <div class="bar_right" style="width:20%;">
          <el-row>
            <el-tooltip content="七麦" placement="top">
              <el-button type="success" icon="el-icon-link" size="mini" @click="qimai">七麦</el-button>
            </el-tooltip>
            <!-- <el-tooltip content="详情" placement="top">
          <el-button type="primary" icon="el-icon-tickets" size="mini" @click="detail">详情</el-button>
        </el-tooltip> -->
          </el-row>
        </div>
      </div>
      <!-- 主内容区 -->
      <div class="cont_table">
        <!-- 表格 -->
        <el-table :show-summary="true" size="mini" ref="goodsTable" id="goodsTable" :data="tableList"
          highlight-current-row @row-click="handleCurrentChange" width="100%" height="calc(100% - 110px)">
         <!-- <el-table-column type="index" width="50" label="编号" header-align="center"
            :fixed="device!='mobile'?true:false"></el-table-column> -->
            <el-table-column property="recordDate" label="日期" min-width="100" ></el-table-column>
          <el-table-column property="packageAlias" label="包名" ></el-table-column>
          <el-table-column property="newUserCount" label="新增用户" ></el-table-column>
          <el-table-column property="activeUserCount" label="活跃用户" >
          </el-table-column>
          <el-table-column property="totalIncome" label="收益" sortable></el-table-column>
          <el-table-column property="arpuNew" label="新增Arpu" sortable></el-table-column>
          <el-table-column property="arpuActive" label="活跃Arpu" sortable></el-table-column>
          <!-- <el-table-column property="arppu" label="ARPPU" sortable></el-table-column> -->
          <el-table-column property="payRate" label="付费率" sortable></el-table-column>
          <el-table-column property="orderCount" label="订单" sortable></el-table-column>
          <el-table-column property="userCountOrder" label="下单用户" sortable></el-table-column>
          <el-table-column property="userCountPaid" label="支付用户" sortable></el-table-column>
          <el-table-column property="orderCountPaid" label="支付订单" sortable></el-table-column>
        </el-table>
        <!-- 分页器 -->
        <div class="block">
          <el-pagination :current-page="pageNum" :page-sizes="[30, 50, 100]" :page-size="pageSize" :total="total"
            layout="total, sizes, prev, pager, next, jumper" @size-change="pageSizeChange"
            @current-change="pageNumChange">
          </el-pagination>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
  export default {
    name: 'OrderStatistics',
    //组件创建
    created() {
      this.yesterday = this.getNextDate(new Date(), -1) + " 00:00:00"
      this.searchData = {
        startDate: this.yesterday,
        endDate: this.yesterday,
        time: [
          this.yesterday, this.yesterday,
        ]
      }
      this.getDataList()
      this.getPackageList()
    },
    updated() {
      this.$nextTick(() => {
        this.$refs.goodsTable.doLayout();
      })
    },
    data() {
      return {
        defaultProps: {
          children: 'children',
          label: 'label'
        },
        loading: false,
        tableList: [], //tab列表
        searchData: {},
        packageList: [],
        channelList: [],
        selected: null, //当前选中行
        //行详情抽屉
        rowDrawer: false,
        rowDetail: {},
        pageNum: 1,
        pageSize: 30,
        total: 0,
        pickerOptions: {
          disabledDate(time) {
            return time.getTime() > Date.now() - 3600 * 1000 * 24;
          },
          shortcuts: [{
            text: '昨天',
            onClick(picker) {
              const date = new Date();
              date.setTime(date.getTime() - 3600 * 1000 * 24);
              picker.$emit('pick', [date, date]);
            }
          }, {
            text: '前天',
            onClick(picker) {
              const date = new Date();
              date.setTime(date.getTime() - 3600 * 1000 * 24 * 2);
              picker.$emit('pick', [date, date]);
            }
          }, {
            text: '一周前',
            onClick(picker) {
              const date = new Date();
              date.setTime(date.getTime() - 3600 * 1000 * 24 * 7);
              picker.$emit('pick', [date, new Date()]);
            }
          }]
        },
      }
    },
    methods: {
      getNextDate(date, day) {
      　　var dd = new Date(date);
      　　dd.setDate(dd.getDate() + day);
      　　var y = dd.getFullYear();
      　　var m = dd.getMonth() + 1 < 10 ? "0" + (dd.getMonth() + 1) : dd.getMonth() + 1;
      　　var d = dd.getDate() < 10 ? "0" + dd.getDate() : dd.getDate();
      　　return y + "-" + m + "-" + d;
      },
      setTime(e) {
        if (e) {
          this.searchData.startDate = e[0] + ' 00:00:00'
          this.searchData.endDate = e[1] + ' 23:59:59'
        } else {
          this.searchData.startDate = null
          this.searchData.endDate = null
        }
        this.search()
      },
      getPackageList() {
        let hostUrl = this.$service.publicUrl().PackageTree
        this.axios({
          method: "GET",
          url: hostUrl,
        }).then((response) => {
          this.loading = false
          if (response.data.status == 200) {
            this.packageList = response.data.data
            this.packageList.unshift({
              label: '全部',
              value: ''
            })
          } else {
            this.$message.warning("warning : " + response.data.message);
          }
          this.selected = null
        }).catch((error) => {
          this.loading = false
          this.$message.error("查询失败，error : " + JSON.stringify(error));
        })
      },
      search() {
        this.pageNum = 1
        this.getDataList()
      },
      nodeClick(data) {
        if (data.value != undefined) {
          if (data.value == '') {
            this.pageNum = 1
            this.searchData = {
              startDate: this.yesterday,
              endDate: this.yesterday,
            }

          } else {
            this.pageNum = 1
            this.searchData = {}
            this.searchData.packageName = data.value
          }
        } else {
          this.searchData.packageName = ''
          this.searchData.packageSeries = data.label
        }
        this.getDataList()
      },
      //列表查询
      getDataList() {
        let param = {
          pageNum: this.pageNum,
          pageSize: this.pageSize,
          ...this.searchData,
        }
        delete param['time']
        let hostUrl = this.$service.publicUrl().OrderStatisticsList
        this.axios({
          method: "GET",
          url: hostUrl,
          params: param
        }).then((response) => {
          this.loading = false
          if (response.data.status == 200) {
            this.tableList = response.data.data.list
            this.tableList.forEach(element => {
              element.recordDate = element.recordDate.slice(0, 10)
            });

            this.total = response.data.data.total
          } else {
            this.$message.warning("warning : " + response.data.message);
          }
          this.selected = null
        }).catch((error) => {
          this.loading = false
          this.$message.error("查询失败，error : " + JSON.stringify(error));
        })
      },
      detail() {},
      qimai() {
        if (this.selected == undefined) {
          this.$message.warning("请先选中一条记录");
        } else {
          window.open("https://www.qimai.cn/search/android/market/6/search/" + this.selected.packageName)
        }
      },
      //点击行
      handleCurrentChange(e) {
        this.selected = e
      },
      pageSizeChange(e) {
        this.pageSize = e
        this.search()
      },
      //pageNum变化
      pageNumChange(e) {
        this.pageNum = e;
        this.getDataList();
      },
    }
  }
</script>
<style lang="less" scoped>
  .drawer_inner {
    width: 95%;
    height: 100%;
    margin: auto;
  }

  .cont_table /deep/ .el-icon-tickets {
    font-size: 20px;
    margin-top: 4px;
  }

  .tips {
    font-size: 8px;
    margin-left: 30px;
    color: #878787;
  }

  .tips>span {
    color: orangered !important;
  }

  .content {
    display: flex;
    flex-direction: row;
    width: 100%;
    // justify-content: space-around;
  }

  .content_left {
    margin-right: 16px;
    padding-top: 21px;
    letter-spacing: 2px;
    width: 15%;
    height: calc(100% - 65px);
    background: #FFFFFF;
    overflow: auto
  }

  .content_right {
    width: 85%;
  }

  .cont_bar {
    display: flex;
  }
</style>
<style>
  .el-tree-node__content {
    height: 40px !important;
  }
</style>
