<template>
  <div id="dis_list" class="content" v-loading="loading" element-loading-text="加载中">
    <!-- 顶部操作栏 -->
    <div class="cont_bar">
      <div class="bar_left">
        <el-form :inline="true" :model="searchData" class="demo-form-inline" size="small">
          <el-form-item label="应用名称/系列：">
            <el-input v-model="searchData.packageAlias" placeholder="请输入搜索关键字" />
          </el-form-item>
          <el-form-item>
            <el-tooltip content="查询" placement="top">
              <el-button type="primary" @click="search()" icon="el-icon-search"></el-button>
            </el-tooltip>
          </el-form-item>
        </el-form>
      </div>
      <div class="bar_right">
        <el-row>
          <el-tooltip content="编辑" placement="top">
            <el-button type="info" icon="el-icon-edit" size="mini" @click="openUpdate"></el-button>
          </el-tooltip>
        </el-row>
      </div>
    </div>
    <!-- 主内容区 -->
    <div class="cont_table">
      <!-- 表格 -->
      <el-table ref="goodsTable" id="goodsTable" :data="tableList" highlight-current-row
        @row-click="handleCurrentChange" width="100%" height="90%">
        <el-table-column prop="id" width="50" label="编号"></el-table-column>
        <el-table-column property="packageAlias" label="应用名称" min-width="150"></el-table-column>
        <el-table-column property="userId" label="用户ID" min-width="80"></el-table-column>
        <el-table-column property="userName" label="用户姓名" min-width="100"></el-table-column>
        <el-table-column property="userContact" label="联系电话" min-width="150"></el-table-column>
        <el-table-column property="sourceChannel" label="渠道" min-width="80"></el-table-column>
        <el-table-column property="appVersion" label="版本号" min-width="80"></el-table-column>
        <el-table-column property="messageDetail" label="留言内容" min-width="240" show-overflow-tooltip></el-table-column>
        <el-table-column property="replyDetail" label="回复内容" min-width="240" show-overflow-tooltip></el-table-column>
        <el-table-column property="remark" label="备注" show-overflow-tooltip></el-table-column>
        <el-table-column property="createTime" label="留言时间" min-width="150" show-overflow-tooltip></el-table-column>
        <el-table-column property="updateTime" label="回复时间" min-width="150"></el-table-column>
      </el-table>
      <!-- 分页器 -->
      <div class="block">
        <el-pagination :current-page="pageNum" :page-sizes="[10, 15, 20, 30]" :page-size="pageSize" :total="total"
          layout="total, sizes, prev, pager, next, jumper" @size-change="pageSizeChange"
          @current-change="pageNumChange">
        </el-pagination>
      </div>
    </div>
    <el-dialog :visible.sync="updateFormVisible" width="800px" :show-close='false' :close-on-click-modal="false">
      <el-form :model="updateForm" ref="updateForm" class="add_form">
        <el-descriptions title="留言详情" border :column="1">
          <el-descriptions-item label="应用包名">{{updateForm.packageAlias}}</el-descriptions-item>
          <el-descriptions-item label="用户姓名">{{updateForm.userName}}</el-descriptions-item>
          <el-descriptions-item label="联系电话">{{updateForm.userContact}}</el-descriptions-item>
          <el-descriptions-item label="来源渠道">{{updateForm.sourceChannel}}</el-descriptions-item>
          <el-descriptions-item label="设备类型">{{updateForm.deviceType}}</el-descriptions-item>
          <el-descriptions-item label="版本号">{{updateForm.appVersion}}</el-descriptions-item>
          <el-descriptions-item label="留言时间">{{updateForm.createTime}}</el-descriptions-item>
          <el-descriptions-item label="留言内容">{{updateForm.messageDetail}}</el-descriptions-item>
          <el-descriptions-item label="回复内容">
            <el-input type="textarea" v-model="updateForm.replyDetail"></el-input>
          </el-descriptions-item>
          <el-descriptions-item label="备注">
            <el-input type="textarea" v-model="updateForm.remark"></el-input>
          </el-descriptions-item>
        </el-descriptions>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="updateFormVisible = false">取 消</el-button>
        <el-button type="primary" @click="updateFormConfirm()">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>
<script>
  export default {
    name: 'LeaveWordsManagemment',
    //组件创建
    created() {
      this.getDataList();
    },
    mounted() {},
    data() {
      return {
        loading: false,
        pageSize: 30,
        pageNum: 1,
        total: 0,
        searchData: {},
        selected: null, //当前选中行
        tableList: [],
        // 修改备注以及回复
        updateFormVisible: false,
        visibleLeave: "修改留言",
        updateForm: {},
      }
    },
    methods: {
      search() {
        this.pageNum = 1
        this.getDataList()
      },
      getDataList() {
        let param = {
          ...this.searchData,
          pageNum: this.pageNum,
          pageSize: this.pageSize
        }
        let hostUrl = this.$service.publicUrl().LeavingMessage
        this.axios({
          method: "GET",
          url: hostUrl,
          params: param
        }).then((response) => {
          this.loading = false
          if (response.data.status == 200) {
            this.tableList = response.data.data.list
            this.total = response.data.data.total
          } else {
            this.$message.warning("warning : " + response.data.message);
          }
          this.selected = null
        }).catch((error) => {
          this.loading = false
          this.$message.error("查询失败，error : " + JSON.stringify(error));
        })
      },
      //点击行
      handleCurrentChange(e) {
        this.selected = e;
        this.updateForm = {
          remark: e.remark,
          replay: e.reply
        }
      },
      openUpdate() {
        if (!this.selected) {
          this.$message.warning("请选择要处理的留言！");
        } else {
          this.updateFormVisible = true;
        }
        this.updateForm = this.selected
      },
      updateFormConfirm() {
        this.loading = true;
        let hostUrl = this.$service.publicUrl().LeavingMessageUpdate
        this.axios({
          method: "PUT",
          url: hostUrl,
          data: this.updateForm
        }).then((response) => {
          this.loading = false
          this.updateFormVisible = false
          this.updateForm = {
            remark: "",
            replay: ""
          }
          if (response.data.status == 200) {
            this.getDataList();
          } else {
            this.$message.warning("warning : " + response.data.message);
          }
          this.selected = null
        }).catch((error) => {
          this.loading = false;
          this.updateFormVisible = false;
          this.updateForm = {
            remark: "",
            replay: ""
          };
          this.$message.error("修改失败，error : " + JSON.stringify(error));
        })
      },
      // 判断当数据条数为1时
      reset() {
        if (this.tableList.length == 1) {
          if (this.pageSize > 1) {
            this.pageSize -= 1;
          }
        };
      },
      pageSizeChange(e) {
        this.pageSize = e;
        this.getDataList()
      },
      //pageNum变化
      pageNumChange(e) {
        this.pageNum = e
        this.getDataList();
      },
    }
  }
</script>
<style lang="less" scoped>
  /deep/.bar_left .el-form-item__content {
    max-width: 150px;
  }
</style>
