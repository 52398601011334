let publicUrl=function(){
  let domain = "https://api.generone.com/"   //正式
  // let domain = "http://test.api.generone.com/"   //测试
  // let domain = "http://127.0.0.1:8898/"
  let rootPath_M ='web/manage/'//基础业务管理
  let rootPath_S ='web/statistics/'//统计

  let api={

    //-----------------------------------用户---------------------------------
    UserLogin: domain + rootPath_M+'admin/login',//登录
    AdminList: domain + rootPath_M+'admin',//登录

    //-------------------------------基础业务管理-----------------------------------
    PackageSeriesList:domain + rootPath_M +'package-group/list',//应用包系列 列表package/series-add
    PackageSeriesAdd:domain + rootPath_M +'package-group/add',//应用包系列 新增
    PackageSeriesDelete:domain + rootPath_M +'package-group/delete',//应用包系列 删除

    ChannelList: domain + rootPath_M + 'channel/list',   //渠道列表
    ChannelValueList: domain + rootPath_M + 'channel/channelList',   //渠道值列表
    ChannelUpdate: domain + rootPath_M + 'channel/update',   //渠道更新
    ChannelAdd: domain + rootPath_M + 'channel/add',   //渠道新增
    ChannelDelete: domain + rootPath_M + 'channel/delete',   //渠道删除


    PackageList: domain + rootPath_M + 'package/list',   //应用包列表
    PackageListAll: domain + rootPath_M + 'package/all',   //应用包列表
    PackageTree: domain + rootPath_M + 'package/tree',   //应用包树形列表
    PackageUpdate: domain + rootPath_M + 'package/update',   //应用包更新
    PackageAdd: domain + rootPath_M + 'package/add',   //应用包新增
    PackageDelete: domain + rootPath_M + 'package/delete',   //应用包删除

    AgreementList: domain + rootPath_M + 'agreement/list',   //协议列表
    AgreementUpdate: domain + rootPath_M + 'agreement/update',   //协议更新
    AgreementAdd: domain + rootPath_M + 'agreement/add',   //协议新增
    AgreementAddBatch: domain + rootPath_M + 'agreement/add/batch',   //协议新增
    AgreementDelete: domain + rootPath_M + 'agreement/delete',   //协议删除
    AgreementDetail: domain + rootPath_M + 'agreement/',   //协议删除

    WebsiteAppList: domain + rootPath_M + 'website/app/list',   //官网产品列表
    WebsiteAppUpdate: domain + rootPath_M + 'website/app/update',   //官网产品更新
    WebsiteAppAdd: domain + rootPath_M + 'website/app/add',   //官网产品新增
    WebsiteAppDelete: domain + rootPath_M + 'website/app/delete',   //官网产品删除

    ShareList: domain + rootPath_M + 'share/list',   //分享列表
    ShareUpdate: domain + rootPath_M + 'share/update',   //分享修改
    ShareAdd: domain + rootPath_M + 'share/add',   //分享新增
    ShareDelete: domain + rootPath_M + 'share/delete',   //分享列表
    ShareUploadFile: domain + rootPath_M + 'share/upload-file',   //分享上传
    ShareDeleteFile: domain + rootPath_M + 'share/cancel',   //删除图片

    AdvertList: domain + rootPath_M + 'advert/list',  //广告列表
    AdvertAdd: domain + rootPath_M + 'advert/add',  //广告列表
    AdvertUpdate: domain + rootPath_M + 'advert/update',  //广告列表
    AdvertDelete: domain + rootPath_M + 'advert/delete',  //广告列表
    AdvertChannelUpdate: domain + rootPath_M + 'advert-channel/update',  //广告列表

    VipList: domain + rootPath_M + 'vip/list', //Vip商品列表
    VipAdd: domain + rootPath_M + 'vip/save', //Vip商品新增
    VipUpdate: domain + rootPath_M + 'vip/update', //Vip商品修改
    VipDelete: domain + rootPath_M + 'vip/delete', //Vip商品删除

    PayConfigList: domain + rootPath_M + 'config-pay/list', //付费配置列表
    PayConfigAdd: domain + rootPath_M + 'config-pay/save', //付费配置新增
    PayConfigUpdate: domain + rootPath_M + 'config-pay/update', //付费配置修改
    PayConfigDelete: domain + rootPath_M + 'config-pay/delete', //付费配置删除

    //-----------------------------统计--------------------------------
    StatisticsTotal: domain + rootPath_S,    //看板首页数据
    StatisticsTotalList: domain + rootPath_S + 'list',    //看板首页数据

    UserStatisticsHome: domain + rootPath_S + 'user/home',    //用户统计首页
    UserStatisticsTotal: domain + rootPath_S + 'user/total',    //用户统计首页列表
    UserChannelStatisticsDay: domain + rootPath_S + 'user-channel/chart/day',    //用户渠道统计图表数据
    UserChannelStatisticsHour: domain + rootPath_S + 'user-channel/chart/hour',    //用户渠道统计列表（按小时）
    UserStatisticsDay: domain + rootPath_S + 'user/day',    //用户统计列表（按天）
    UserStatisticsChannel: domain + rootPath_S + 'user-channel/list/channel',    //用户渠道统计列表

    AdStatisticsRealTime: domain + rootPath_S + 'statistics-real/ad',    //广告 实时数据
    AdStatisticsList: domain + rootPath_S + 'list/advert',    //广告统计列表

    OrderStatisticsList: domain + rootPath_S + 'order/list',    //订单统计列表

    MenuList: domain + rootPath_M + 'menu/menuList',    //菜单列表
    MenuAdd: domain + rootPath_M + 'menu/menuAdd',    //菜单新增
    MenuEdit: domain + rootPath_M + 'menu/menuEdit',    //菜单修改
    MenuDelete: domain + rootPath_M + 'menu/menuDelete',    //菜单删除
    MenuGroupList: domain + rootPath_M + 'menu/menuGroupList',    //菜单组列表
    MenuGroupAdd: domain + rootPath_M + 'menu/groupAdd',    //菜单组新增
    MenuGroupEdit: domain + rootPath_M + 'menu/groupEdit',    //菜单组修改
    MenuGroupDelete: domain + rootPath_M + 'menu/groupDelete',    //菜单组删除
    MenuIconUpload: domain + rootPath_M + 'menu/uploadFile',    //菜单组删除

    //---------------------------------文档---------------------------------------------
    AppDocList: domain + rootPath_M + 'doc/app/list', //App相关参数文档列表
    AppDocAdd: domain + rootPath_M + 'doc/app/add', //App相关参数文档新增
    AppDocUpdate: domain + rootPath_M + 'doc/app/update', //App相关参数文档修改
    AppDocDelete: domain + rootPath_M + 'doc/app/delete', //App相关参数文档删除

    AppThirdDocList: domain + rootPath_M + 'doc-third/list', //外包项目文档列表
    AppThirdDocAdd: domain + rootPath_M + 'doc-third/add', //外包项目文档新增
    AppThirdDocUpdate: domain + rootPath_M + 'doc-third/update', //外包项目文档修改
    AppThirdDocDelete: domain + rootPath_M + 'doc-third/delete', //外包项目文档删除

    AppVersionList: domain + rootPath_M + 'doc/app/version/list', //App渠道版本列表
    AppVersionAdd: domain + rootPath_M + 'doc/app/version/add', //App渠道版本新增
    AppVersionUpdate: domain + rootPath_M + 'doc/app/version/update', //App渠道版本修改
    AppVersionUpdateBatch: domain + rootPath_M + 'doc/app/version/update/batch', //App渠道版本修改
    AppVersionDelete: domain + rootPath_M + 'doc/app/version/delete', //App渠道版本删除

    //--------------------------------数据列表-------------------------------*/
    LeavingMessage: domain + rootPath_M + 'leave-words/list',      //用户留言
    LeavingMessageDelete: domain + rootPath_M + 'leave-words/delete',      //用户留言删除 {"id": ''}
    LeavingMessageUpdate: domain + rootPath_M + 'leave-words/update',      //用户留言修改 {"id": '', 'remark': '', 'replay': ''}

    VipOrder: domain + rootPath_M + 'order/list',       //VIP订单
    OrderRefund: domain + rootPath_M + 'order/refund',
    UserList: domain + rootPath_M + 'user/list',
    UpdateUser: domain + rootPath_M + 'user/update',

    //---------------------------------客服-----------------------------*/
    // scoketUrl: 'ws://test.api.generone.com/chat/server/',
    scoketUrl: 'wss://api.generone.com/chat/server/',
    messageUserList: domain + '/customer/server/message/history/user',
    userHistoryMessage: domain + '/customer/server/message/history',
    readMessage: domain + '/customer/server/message/read'
  }
  return api
}
//深拷贝
let deepClone=function(target) {
    let result;
    if (typeof target === 'object') {
        if (Array.isArray(target)) {
            result = [];
            for (let i in target) {
                result.push(deepClone(target[i]))
            }
        } else if(target===null) {
            result = null;
        } else if(target.constructor===RegExp){
            result = target;
        }else {
            result = {};
            for (let i in target) {
                result[i] = deepClone(target[i]);
            }
        }
    } else {
        result = target;
    }
    return result;
}
let dateToString=function(date) {
	const year = date.getFullYear();
	let month = date.getMonth() + 1;
	let day = date.getDate();
	let hour = date.getHours();
	let minute = date.getMinutes();
	let second = date.getSeconds();
	month = month > 9 ? month : ('0' + month);
	day = day > 9 ? day : ('0' + day);
	hour = hour > 9 ? hour : ('0' + hour);
	minute = minute > 9 ? minute : ('0' + minute);
	second = second > 9 ? second : ('0' + second);
	const dateTime = year + "-" + month + "-" + day + " " + hour + ":" + minute + ":" + second;
	return dateTime;
}
export default{
  publicUrl:publicUrl,
  deepClone:deepClone,
  dateToString:dateToString
}
