<template>
<div id="dis_list" class="content" v-loading="loading" element-loading-text="加载中">
	<!-- 顶部操作栏 -->
	<div class="cont_bar">
		<div class="bar_left">
			<el-form :inline="true" :model="searchData" class="demo-form-inline" size="small">
			  <el-form-item label="应用名称：">
			    <el-select v-model="searchData.packageName" placeholder="应用名称" :filterable="true" >
			      <el-option label="全部" value=""></el-option>
			      <el-option v-for="item in packageList" :key="item.packageName" :label="item.packageAlias" :value="item.packageName"></el-option>
			    </el-select>
			  </el-form-item>
        <el-form-item label="协议类型：">
			    <el-select v-model="searchData.agreementType" placeholder="协议类型" :filterable="true" >
			      <el-option label="隐私协议" value=1></el-option>
			      <el-option label="用户协议" value=2></el-option>
			      <el-option label="个人信息收集清单" value=3></el-option>
			      <el-option label="三方信息共享清单" value=4></el-option>
			      <el-option label="会员服务协议" value=5></el-option>
			      <el-option label="其他协议" value=0></el-option>
			    </el-select>
			  </el-form-item>
        <el-form-item>
				<el-tooltip content="查询"  placement="top">
					<el-button type="primary" @click="search1()" icon="el-icon-search"></el-button>
				</el-tooltip>
			  </el-form-item>
			</el-form>
		</div>
    <div class="bar_right">
			<el-row>
				<el-tooltip content="预览"  placement="top">
					<el-button type="primary" icon="el-icon-share" size="mini" @click="preview"></el-button>
				</el-tooltip>
        <el-tooltip content="新增"  placement="top">
					<el-button type="success" icon="el-icon-plus" size="mini" @click="openAdd"></el-button>
				</el-tooltip>
        <el-tooltip content="编辑"  placement="top">
					<el-button type="info" icon="el-icon-edit" size="mini" @click="openUpdate"></el-button>
				</el-tooltip>
				<el-tooltip content="删除"  placement="top">
					<el-button type="warning" icon="el-icon-delete" size="mini" @click="openDetele()"></el-button>
				</el-tooltip>
			</el-row>
		</div>
	</div>
	<!-- 主内容区 -->
	<div class="cont_table">
		<!-- 表格 -->
		<el-table ref="goodsTable" id="goodsTable" :data="tableList" highlight-current-row @row-click="handleCurrentChange" width="100%" height="calc(100% - 70px)">
		    <el-table-column prop="id" width="100" label="编号"></el-table-column>
        <el-table-column property="email" label="公司名称" min-width="100">
          <template slot-scope="scope">
            <el-row class="row-op-text">
              {{scope.row.companyName == 'generone' ?
              '初代科技' : scope.row.companyName == 'lotsgain' ?
              '哆萤科技' : scope.row.companyName == 'wisdomtec' ?
              '威仕多网络' : '璟智信息'}}
            </el-row>
          </template>
        </el-table-column>
		    <el-table-column property="packageAlias" label="产品名称" min-width="200"></el-table-column>
		    <!-- <el-table-column property="packageName" label="应用包名" min-width="200"></el-table-column> -->
		    <el-table-column property="channel" label="渠道" min-width="80"></el-table-column>
        <el-table-column property="platform" label="协议平台" min-width="80"></el-table-column>
        <el-table-column property="agreementType" label="协议类型" min-width="100">
          <template slot-scope="scope">
            <el-row class="row-op-text">
              {{scope.row.agreementType == 1 ?
                '隐私协议' : scope.row.agreementType == 2 ?
                '用户协议' : scope.row.agreementType == 3 ?
                '个人信息收集清单' : scope.row.agreementType == 4 ?
                '三方信息共享清单' : scope.row.agreementType == 5 ? '会员服务协议': '其他协议'  }}
            </el-row>
          </template>
        </el-table-column>
        <el-table-column property="agreementUrl" label="发布路径" min-width="300"></el-table-column>
		</el-table>
		<!-- 分页器 -->
		<div class="block">
			<el-pagination :current-page="pageNum" :page-sizes="[30, 50, 100]" :page-size="pageSize" :total="total"
				layout="total, sizes, prev, pager, next, jumper" @size-change="pageSizeChange" @current-change="pageNumChange">
			</el-pagination>
		</div>
	</div>
</div>
</template>
<script>
export default {
	name: 'appAgreementManage',
	//组件创建
	created() {
		this.search(),
    this.getPackageList()
	},
	data() {
		return{
			loading:false,
			tableList:[],//tab列表
      packageList: [],
			selected:null,//当前选中行
			//行详情抽屉
      searchData: {},
      pageNum: 1,
      pageSize: 30,
      total: 0,
		}
	},
	methods:{
    getPackageList() {
    	let hostUrl=this.$service.publicUrl().PackageListAll
    	this.axios({
    		method: "GET",
    		url: hostUrl,
    	}).then((response)=>{
    		this.loading=false
    	    if(response.data.status==200){
    			  this.packageList=response.data.data
    	    }else{
    			  this.$message.warning("warning : "+response.data.message);
    		}
    	}).catch((error)=>{
    		this.loading=false
    		this.$message.error("查询失败，error : "+JSON.stringify(error));
    	})
    },
    search1 () {
      this.pageNum = 1
      this.search()
    },
		//列表查询
		search(){
			let param = {
        pageNum: this.pageNum,
        pageSize: this.pageSize,
        ...this.searchData,
      }
			let hostUrl=this.$service.publicUrl().AgreementList
			this.axios({
				method: "GET",
				url: hostUrl,
				params: param
			}).then((response)=>{
        console.log (response)
				this.loading=false
			    if(response.data.status==200){
					  this.tableList=response.data.data.list
            this.total = response.data.data.total
			    }else{
					  this.$message.warning("warning : "+response.data.message);
				}
				this.selected=null
			}).catch((error)=>{
				this.loading=false
				this.$message.error("查询失败，error : "+JSON.stringify(error));
			})
		},
    preview() {
      if (this.selected == null) {
         this.$message.warning("请选择一条记录");
         return
      }
      window.open(this.selected.agreementUrl)
    },
    openAdd() {
      this.$router.push({ name: 'agreement_detail', params: {type: 'add'}})
    },
    openUpdate() {
      if (this.selected == null) {
         this.$message.warning("请选择一条记录");
         return
      }
      this.$router.push({ name: 'agreement_detail', params: {data: this.selected.id, type: 'edit'}})
    },
    openDetele(){
			if(this.selected==null){
				this.$message.warning("请先选中一条记录！");
				return
			}
			this.$confirm('确认删除该记录?', '提示', {
				confirmButtonText: '确定',
				cancelButtonText: '取消',
				type: 'warning'
			}).then(() => {
				this.deleteChannel()
			}).catch(() => {
			});
		},
    deleteChannel() {
       let param = {
        id: this.selected.id,
      }
			let hostUrl=this.$service.publicUrl().AgreementDelete
			this.axios({
				method: "DELETE",
				url: hostUrl,
				params: param
			}).then((response)=>{
        console.log(response)
				this.loading=false
        if(response.data.status==200){
          this.$message.success("删除成功");
          this.search()
        }else{
          this.$message.warning("warning : "+response.data.message);
        }
        this.selected = null
			}).catch((error)=>{
				this.loading=false
				this.$message.error("删除失败，error : "+JSON.stringify(error));
			})
    },
		//点击行
		handleCurrentChange(e){
			this.selected=e
		},
    pageSizeChange(e){
			this.pageSize=e
			this.search()
		},
		//pageNum变化
		pageNumChange(e){
			this.pageNum=e
			this.search();
		},
	}
}
</script>
<style lang="less" scoped>
.drawer_inner{
	width: 95%;
	height: 100%;
	margin: auto;
}
.cont_table /deep/ .el-icon-tickets{
	font-size: 20px;
	margin-top: 4px;
}
.tips{
	font-size: 8px;
	margin-left: 30px;
	color: #878787;
}
.tips>span{
	color: orangered !important;
}
</style>
