<template>
  <div id="dis_list" class="content" v-loading="loading" element-loading-text="加载中">
    <!-- 顶部操作栏 -->
    <div class="cont_bar">
      <div class="bar_left" style="width: 100%;">
        <el-form :inline="true" :model="searchData" class="demo-form-inline" size="small">
          <el-form-item label="应用系列：">
            <el-select v-model="searchData.packageSeries" placeholder="请选择应用包系列" :filterable="true" @change="search1">
              <el-option :label="item.packageGroupName" :value="item.packageGroupName" v-for="(item, index) in seriesList"
                :key="index"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="应用名称：">
            <el-select v-model="searchData.packageName" placeholder="应用名称" :filterable="true" @change="search()">
              <el-option label="全部" value=""></el-option>
              <el-option v-for="item in packageList" :key="item.id" :label="item.packageAlias" :value="item.packageName">
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="时间：">
            <el-date-picker v-model="searchData.time" value-format="yyyy-MM-dd" :picker-options="pickerOptions"
              type="daterange" range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期" @change="setTime">
            </el-date-picker>
          </el-form-item>
        </el-form>
      </div>
      <div class="bar_right" style="width:20%;">
        <el-row>
          <el-tooltip content="七麦" placement="top">
            <el-button type="success" icon="el-icon-link" size="mini" @click="qimai">七麦</el-button>
          </el-tooltip>
        </el-row>
      </div>
    </div>
    <!-- 主内容区 -->
    <div class="cont_table">
      <!-- 表格 -->
      <div class="table-box">
        <el-table border ref="goodsTable" id="goodsTable" :data="tableList" highlight-current-row width="100%">
          <!-- <el-table-column type="index" width="50" label="编号" header-align="center" fixed></el-table-column> -->
          <el-table-column property="statisticsType" label="统计维度" fixed></el-table-column>
          <el-table-column property="appCount" label="App数量"></el-table-column>
          <el-table-column property="seriesCount" label="系列数量"></el-table-column>
          <el-table-column property="newUser" label="新增"></el-table-column>
          <el-table-column property="activeUser" label="活跃"></el-table-column>
          <el-table-column property="registerUser" label="注册"></el-table-column>
          <el-table-column property="profit" label="广告收益"></el-table-column>
          <el-table-column property="arpuNew" label="广告Arpu"></el-table-column>
          <el-table-column property="totalPay" label="付费收益"></el-table-column>
          <el-table-column property="payRate" label="付费Arpu"></el-table-column>
          <el-table-column property="totalProfit" label="收益合计"></el-table-column>
        </el-table>
      </div>
      <div style="height: 600px;background: #FFFFFF; margin-top: 20px;">
        <!-- 表格 -->
        <el-table size="mini" ref="table1" id="table1" :data="tableList1" highlight-current-row width="100%"
          height="calc(100% - 110px)" @row-click="handleCurrentChange" >
          <el-table-column type="index" width="50" label="编号" header-align="center"></el-table-column>
          <el-table-column property="packageAlias" label="包名" min-width="150"></el-table-column>
          <el-table-column property="recordDate" label="日期" min-width="100"></el-table-column>
          <el-table-column label="用户" header-align="center">
            <el-table-column property="userNew" label="新增用户"></el-table-column>
            <el-table-column property="userActive" label="活跃用户"></el-table-column>
            <el-table-column property="userRegister" label="注册"></el-table-column>
            <el-table-column property="launchTimes" label="运行次数"></el-table-column>
          </el-table-column>
          <el-table-column label="广告" header-align="center">
            <el-table-column property="adShowCount" label="展现量"></el-table-column>
            <el-table-column property="adCpm" label="CPM"></el-table-column>
            <el-table-column property="adArpuNew" label="新增arpu"></el-table-column>
            <el-table-column property="adArpuActive" label="活跃arpu"></el-table-column>
            <el-table-column property="adProfit" label="收益"></el-table-column>
          </el-table-column>
          <el-table-column label="订单" header-align="center">
            <el-table-column property="orderCountPaid" label="订单数"></el-table-column>
            <el-table-column property="orderArpuNew" label="新增arpu"></el-table-column>
            <el-table-column property="orderArpuActive" label="活跃arpu"></el-table-column>
            <el-table-column property="orderProfit" label="付费金额"></el-table-column>
          </el-table-column>
          <el-table-column label="汇总" header-align="center">
            <el-table-column property="totalArpuNew" label="新增arpu"></el-table-column>
            <el-table-column property="totalArpuActive" label="活跃arpu"></el-table-column>
            <el-table-column property="totalProfit" label="总收益"></el-table-column>
          </el-table-column>
        </el-table>
        <!-- 分页器 -->
        <div class="block">
          <el-pagination :current-page="pageNum" :page-sizes="[30, 50, 100]" :page-size="pageSize" :total="total"
            layout="total, sizes, prev, pager, next, jumper" @size-change="pageSizeChange"
            @current-change="pageNumChange">
          </el-pagination>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
  export default {
    name: 'DataBoard',
    //组件创建
    created() {
      this.yesterday = this.getNextDate(new Date(), -1) + " 00:00:00"
      this.searchData = {
        startDate: this.yesterday,
        endDate: this.yesterday,
        time: [
          this.yesterday, this.yesterday,
        ]
      }

      this.getSeriesList()
      this.getPackageList()
      this.search()
    },
    data() {
      return {
        loading: false,
        updateFormVisible: false,
        addFormVisible: false,
        checkAll: false,
        tableList: [], //tab列表
        tableList1: [], //tab列表
        searchData: {

        },
        total: 0,
        pageSize: 30,
        pageNum: 1,
        packageList: [],
        seriesList: [],

        selected: null, //当前选中行
        //行详情抽屉
        rowDrawer: false,
        rowDetail: {},
        yesterday: '',
        pickerOptions: {
          disabledDate(time) {
            return time.getTime() > Date.now() - 3600 * 1000 * 24;
          },
          shortcuts: [{
            text: '昨天',
            onClick(picker) {
              const date = new Date();
              date.setTime(date.getTime() - 3600 * 1000 * 24);
              picker.$emit('pick', [date, date]);
            }
          }, {
            text: '前天',
            onClick(picker) {
              const date = new Date();
              date.setTime(date.getTime() - 3600 * 1000 * 24 * 2);
              picker.$emit('pick', [date, date]);
            }
          }, {
            text: '一周前',
            onClick(picker) {
              const date = new Date();
              date.setTime(date.getTime() - 3600 * 1000 * 24 * 7);
              picker.$emit('pick', [date, new Date()]);
            }
          }]
        },
        // 当屏幕尺寸为mobile
        show: false,
        minDate: new Date(2021, 9, 28),
        maxDate: "",
        activeNames: [],
        searchDataTime: ""
      }
    },
    methods: {
      getNextDate(date, day) {
      　　var dd = new Date(date);
      　　dd.setDate(dd.getDate() + day);
      　　var y = dd.getFullYear();
      　　var m = dd.getMonth() + 1 < 10 ? "0" + (dd.getMonth() + 1) : dd.getMonth() + 1;
      　　var d = dd.getDate() < 10 ? "0" + dd.getDate() : dd.getDate();
      　　return y + "-" + m + "-" + d;
      },
      qimai() {
        if (this.selected == undefined) {
          this.$message.warning("请先选中一条记录");
        } else {
          window.open("https://www.qimai.cn/search/android/market/6/search/" + this.selected.packageName)
        }
      },
      handleChange(val) {
        console.log(val);
      },
      getSeriesList() {
        let hostUrl = this.$service.publicUrl().PackageSeriesList
        this.axios({
          method: "GET",
          url: hostUrl,
        }).then((response) => {
          this.loading = false
          if (response.data.status == 200) {
            this.seriesList = response.data.data
          } else {
            this.$message.warning("warning : " + response.data.message);
          }
          this.selected = null
        }).catch((error) => {
          this.loading = false
          this.$message.error("查询失败，error : " + JSON.stringify(error));
        })
      },
      getPackageList(packageSeries) {
        let param = {
          packageGroupName: packageSeries
        }
        let hostUrl = this.$service.publicUrl().PackageListAll
        this.axios({
          method: "GET",
          url: hostUrl,
          params: param
        }).then((response) => {
          this.loading = false
          if (response.data.status == 200) {
            this.packageList = response.data.data
          } else {
            this.$message.warning("warning : " + response.data.message);
          }
          this.selected = null
        }).catch((error) => {
          this.loading = false
          this.$message.error("查询失败，error : " + JSON.stringify(error));
        })
      },
      setTime(e) {
        if (e) {
          this.searchData.startDate = e[0] + ' 00:00:00'
          this.searchData.endDate = e[1] + ' 23:59:59'
        } else {
          this.searchData.startDate = null
          this.searchData.endDate = null
        }
        this.search()
      },
      search() {
        this.getDataList()
        this.getDataList1()
      },
      search1(series) {
        console.log(series)
        this.getPackageList(series)
        this.getDataList()
        this.getDataList1()
      },
      //列表查询
      getDataList() {
        let hostUrl = this.$service.publicUrl().StatisticsTotal
        this.axios({
          method: "GET",
          url: hostUrl,
          params: this.searchData
        }).then((response) => {
          this.loading = false
          if (response.data.status == 200) {
            this.tableList = response.data.data
            console.log(this.tableList)
            this.tableList.forEach((element, index) => {
              if (index == 0) {
                element.statisticsType = "查询条件"
              } else {
                element.statisticsType = "所有合计"
              }
              if (element.recordDate) {
                element.recordDate = element.recordDate.slice(0, 10)
              }
            });
          } else {
            this.$message.warning("warning : " + response.data.message);
          }
          this.selected = null
        }).catch((error) => {
          this.loading = false
          this.$message.error("查询失败，error : " + JSON.stringify(error));
        })
      },
      getDataList1() {
        let param = {
          ...this.searchData,
          pageSize: this.pageSize,
          pageNum: this.pageNum,
        }
        let hostUrl = this.$service.publicUrl().StatisticsTotalList
        this.axios({
          method: "GET",
          url: hostUrl,
          params: param
        }).then((response) => {
          this.loading = false
          if (response.data.status == 200) {
            this.tableList1 = response.data.data.list
            this.total = response.data.data.total
            console.log(this.tableList1)
            this.tableList.forEach((element, index) => {});
          } else {
            this.$message.warning("warning : " + response.data.message);
          }
        }).catch((error) => {
          this.loading = false
          this.$message.error("查询失败，error : " + JSON.stringify(error));
        })
      },
      pageSizeChange(e) {
        this.pageSize = e
        this.search()
      },
      //pageNum变化
      pageNumChange(e) {
        this.pageNum = e
        this.getDataList1();
      },
      //点击行
      handleCurrentChange(e) {
        this.selected = e
      },
    }
  }
</script>
<style lang="less" scoped>
  .drawer_inner {
    width: 95%;
    height: 100%;
    margin: auto;
  }

  // .cont_table {
  //   height: 100%;
  // }
  .cont_table /deep/ .el-icon-tickets {
    font-size: 20px;
    // margin-top: 4px;
  }

  .tips {
    font-size: 8px;
    margin-left: 30px;
    color: #878787;
  }

  .tips>span {
    color: orangered !important;
  }

  /deep/.el-container {
    overflow-y: auto;
  }

  /deep/.bar_left .el-form-item__content {
    max-width: 195px;
  }
</style>
<style>
  .el-tree-node__content {
    height: 40px !important;
  }
</style>
