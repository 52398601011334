<template>
  <el-container class="home-container">
    <!-- 侧边栏 -->
    <div v-if="!sideBar&&isSideBar" class="drawer-bg" @click="drawer"></div>
    <el-aside v-if="sideBar||isSideBar" :class="[isSideBar?'setDevice':'']"
      :width="sideBar?isCollapse ? '64px' : '230px':'230px'">
      <!-- 头部logo -->
      <template>
        <div class="header-title" v-if="!isCollapse">初代·数据平台</div>
        <div class="header-title-hiddle" v-else></div>
      </template>
      <el-menu class="el-menu-vertical-demo" background-color="rgba(47, 79, 148, 1.0)" text-color="#FFFFFF" active-text-color="#FFFFFF"
        :collapse-transition="false" :default-active='acttive' unique-opened :collapse="isCollapse" router>
        <el-menu-item index="/data_board" @click="selectMenu()">
          <i class="el-icon-s-home"></i>
          <span slot="title">首页</span>
        </el-menu-item>
        <el-submenu v-for="item in menuList" :index="'norouter_'+item.udid" :key="item.udid">
          <template slot="title">
            <i :class="item.icon"></i>
            <span>{{ item.label }}</span>
          </template>
          <el-menu-item v-for="subItem in item.sub" :index="subItem.path" :key="subItem.udid"
            @click="selectMenu(subItem)">
            <i :class="subItem.icon"></i>
            <span slot="title">{{ subItem.label }}</span>
          </el-menu-item>
        </el-submenu>
      </el-menu>
    </el-aside>
    <!-- 主体 -->
    <el-container>
      <el-header class="cont_top">
        <!-- 头部导航栏 -->
        <div class="header-row">
          <div class="toggle-button">
            <div class="toggle-button-i"><i :title="isCollapse?'展开':'收起'" class="fa fa-bars" @click="toggleCollapse"
                size="40"></i></div>
            <div class="top_title"></div>
          </div>

          <div class="header-avatar">
            <div class="user">{{userInfo.name}}</div>
            <el-dropdown @command="handleCommand">
              <span class="el-dropdown-link" style="display: flex;align-items: flex-end;">
                <img width="35" height="35" style="border-radius:50%;background:#dddddd"
                  src="../assets/images/index/user3.png" alt="">
                <i class="el-icon-arrow-down el-icon--right"></i>
              </span>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item command="update-password">修改密码</el-dropdown-item>
                <el-dropdown-item command="logout">退出登录</el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
          </div>
        </div>
        <!-- tab标签页区域 - 用于标签页切换 -->
        <div class="tabs-switch-page">
          <el-tag size="medium" v-for="(tab, index) in tabList" :key="tab.name" :closable="tab.name !== 'home'"
            :effect="activePath === tab.name ? 'dark' : 'plain'" @close="handleClose(tab, index)"
            @click="changeMenu(tab)">
            {{tab.label}}
          </el-tag>
        </div>
      </el-header>
      <!-- 内容区 -->
      <el-main>
        <!-- 路由占位符，用于展示内容区的内容 -->
        <div class="home-main">
          <keep-alive :include="catch_components">
            <router-view />
          </keep-alive>
        </div>
      </el-main>
    </el-container>
  </el-container>
</template>

<script>
  import {
    mapState,
    mapMutations
  } from 'vuex';
  import ResizeMixin from '@/mixin/ResizeHandler';
  export default {
    //组件被创建
    created() {
      if (this.token == null || this.token.length == 0) {
        this.$router.push("/login")
      }
      this.userInfo = JSON.parse(sessionStorage.getItem("userInfo"))
      // 移动端页面刷新
      if (this.$store.state.device == 'mobile') {
        window.addEventListener('pagehide', () => {
          window.sessionStorage.setItem("store", JSON.stringify(this.$store.state))
        }, true)
      }
      //加载菜单
      setTimeout(() => {
        if (this.$store.state.device != 'mobile') {
          this.menuList = this.$initial.menuData();
        } else {
          this.menuList = this.$initial.menuData().splice(0, 3);
        }
      }, 500)
    },
    mixins: [ResizeMixin],
    computed: {
      ...mapState({
        activePath: state => state.activePath, // 已选中菜单
        tabList: state => state.tabList, // tags菜单列表
        catch_components: state => state.catch_components, // keepalive缓存
        device: state => state.device, //判断窗口类型
        sideBar: state => state.sideBar, //判断是否展示sideBar
      }),
      token() {
        return sessionStorage.getItem("sessionToken")
      }
    },
    watch: {
    	$route: {
    		immediate: true, // 一旦监听到路由的变化立即执行
    		deep: true,
    		handler(to, from) {
    			this.acttive = to.path=='/data_board'?'/data_board':to.path;
          let item=to.name=='data_board'?undefined:to
          this.selectMenu(item)
    		},
    	},
    },
    data() {
      return {
        //菜单列表
        userInfo: {},
        menuList: [],
        // 折叠-展开 默认false不折叠
        isCollapse: false,
        // 系统公告
        mottoList: ['test'],
        isSideBar: false,
        acttive:""
      }
    },
    methods: {
      // 点击折叠 展开菜单
      toggleCollapse() {
        if (this.device == 'mobile') {
          this.isSideBar = !this.isSideBar
        } else {
          this.isCollapse = !this.isCollapse;
        }
      },
      drawer() {
        if (this.device == 'mobile') {
          this.isSideBar = !this.isSideBar
        }
      },
      // 点击菜单 - 传入name，添加到keepalive缓存页面
      selectMenu(item) {
        if (item == undefined) {
          item = {
            name: 'welcome',
            label: '首页',
            path:'/data_board'
          }
        }
        // 加入keepalive缓存
        this.$store.commit('addKeepAliveCache', item.name)
        //添加tags标签
        //访问welcome 就代表home
        var name = item.name === 'welcome' ? 'home' : item.name
        var submenu = {
          path: name,
          name: name,
          label: item.label?item.label:item.meta.title
        }
        //修改选中菜单
        this.$store.commit('selectMenu', submenu)
      },
      // 关闭tab标签
      handleClose(tab, index) {
        var oldActivePath = this.$store.state.activePath
        var oldTabList = this.$store.state.tabList
        let length = oldTabList.length - 1
        for (let i = 0; i < oldTabList.length; i++) {
          let item = oldTabList[i]
          if (item.name === tab.name) {
            oldTabList.splice(i, 1)
          }
        }
        this.$store.commit('removeKeepAliveCache', tab.name)
        if (tab.name !== oldActivePath) {
          return
        }
        if (length === 1) {
          this.$store.commit('closeTab', {
            activePath: 'home',
            tabList: oldTabList
          })
          this.$router.push({
            name: oldTabList[index - 1].name
          })
          return
        }
        if (index === length) {
          oldActivePath = oldTabList[index - 1].name
          this.$store.commit('closeTab', {
            activePath: oldActivePath,
            tabList: oldTabList
          })
          this.$router.push({
            name: oldTabList[index - 1].name
          })
        } else {
          oldActivePath = oldTabList[index].name
          this.$store.commit('closeTab', {
            activePath: oldActivePath,
            tabList: oldTabList
          })
          this.$router.push({
            name: oldTabList[index].name
          })
        }
      },
      // 点击标签跳转路由
      changeMenu(item) {
        var oldActivePath = this.$store.state.activePath
        if (oldActivePath === item.name) {
          return
        }
        this.$store.commit('changeMenu', item.name)
        this.$router.push({
          name: item.name
        })
      },
      // 右上角下拉菜单点击事件
      handleCommand(command) {
        switch (command) {
          // 退出
          case 'logout':
            //消息提示
            this.$message.success('退出登录')
            //重置vuex中的数据
            this.$store.commit('clearVUEX');
            sessionStorage.clear()
            //跳转到首页
            this.$router.replace("/login");
            break
            //修改密码
          case 'update-password':
            //消息提示
            this.$message.success('修改密码')
            break
        }
      },
    }
  };
</script>
<style scoped>
  .home-container {
    height: 100%;
  }

  .el-main {
    background: #F8F8F8;
    padding-top: 0 !important;
    height: 100% !important;
  }

  .el-header {
    /* height: 60px!important; */
  }

  .el-aside {
    background: rgba(47, 79, 148, 1.0);
    color: #FFFFFF;
  }

  .el-menu {
    border-right: none;
  }

  .el-submenu__icon-arrow .el-icon-arrow-down {
    color: rgba(255, 255, 255, 0.8) !important;
  }

  .el-submenu__title i {
    color: #FFFFFF !important;
  }


  .setDevice {
    z-index: 1001;
    position: fixed;
    max-height: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }

  .drawer-bg {
    background: #000;
    opacity: 0.3;
    width: 100%;
    top: 0;
    height: 100%;
    position: absolute;
    z-index: 999;
  }


</style>
