<template>
  <div id="dis_list" class="content" style="height: 90vh" v-loading="loading" element-loading-text="加载中">
    <div v-if="device!='mobile'?true:is_showTree?true:false" class="content_left"
      :style="{'width':device=='mobile'?is_showTree?'70%':'':'15%'}">
      <el-tree :data="packageList" :props="defaultProps" @node-click="nodeClick"></el-tree>
    </div>
    <div v-if="device=='mobile'" class="fixed" @click="is_showTree = !is_showTree">
      <van-icon name="label-o" size='30' />
    </div>
    <div class="content_right" :style="{'width':device=='mobile'?is_showTree?'50%':'100%':'85%'}">
      <!-- 顶部操作栏 -->
      <div class="cont_bar">
        <div class="bar_left" style="width: 100%;">
          <el-form :inline="true" :model="searchData" class="demo-form-inline" size="small">
            <el-form-item label="时间: " v-if="device!='mobile'">
              <el-date-picker v-model="searchData.time" :unlink-panels='true' value-format="yyyy-MM-dd"
                :picker-options="pickerOptions" type="daterange" range-separator="至" start-placeholder="开始日期"
                end-placeholder="结束日期" @change="setTime">
              </el-date-picker>
            </el-form-item>
            <el-form-item v-else label="时间: ">
              <div @click="to_dataTime()">
                <el-input  v-model="searchDataTime" :readonly='true'></el-input>
              </div>
            </el-form-item>
          </el-form>
        </div>
      </div>
      <!-- 主内容区 -->
      <div class="cont_table">
        <!-- 表格 -->
        <el-table :show-summary="true" size="mini" ref="goodsTable" id="goodsTable" :data="tableList"
          highlight-current-row @row-click="handleCurrentChange" width="100%" height="80%">
          <el-table-column type="index" width="50" label="编号" header-align="center"></el-table-column>
          <el-table-column property="recordDate" label="日期" min-width="100"></el-table-column>
          <el-table-column label="汇总" header-align="center">
            <el-table-column property="totalArpuNew" label="新增arpu"></el-table-column>
            <el-table-column property="totalArpuActive" label="活跃arpu"></el-table-column>
            <el-table-column property="totalProfit" label="总收益"></el-table-column>
          </el-table-column>
          <el-table-column label="用户" header-align="center">
            <el-table-column property="userNew" label="新增用户"></el-table-column>
            <el-table-column property="userActive" label="活跃用户"></el-table-column>
            <el-table-column property="userRegister" label="注册"></el-table-column>
            <el-table-column property="launchTimes" label="运行次数"></el-table-column>
          </el-table-column>
          <el-table-column label="广告" header-align="center">
            <el-table-column property="adShowCount" label="展现量"></el-table-column>
            <el-table-column property="adCpm" label="CPM"></el-table-column>
            <el-table-column property="adArpuNew" label="新增arpu"></el-table-column>
            <el-table-column property="adArpuActive" label="活跃arpu"></el-table-column>
            <el-table-column property="adProfit" label="收益"></el-table-column>
          </el-table-column>
          <el-table-column label="订单" header-align="center">
            <el-table-column property="orderCountPaid" label="订单数"></el-table-column>
            <el-table-column property="orderArpuNew" label="新增arpu"></el-table-column>
            <el-table-column property="orderArpuActive" label="活跃arpu"></el-table-column>
            <el-table-column property="orderProfit" label="付费金额"></el-table-column>
          </el-table-column>
        </el-table>
        <!-- 分页器 -->
        <div class="block">
          <el-pagination :current-page="pageNum" :page-sizes="[30, 50, 100]" :page-size="pageSize" :total="total"
            layout="total, sizes, prev, pager, next, jumper" @size-change="pageSizeChange"
            @current-change="pageNumChange">
          </el-pagination>
        </div>
      </div>
    </div>
    <van-calendar v-model="show" allow-same-day type="range" @confirm="onConfirm" :min-date="minDate" :max-date="maxDate" />
  </div>
</template>
<script>
  import {
    mapState,
    mapMutations
  } from 'vuex';
  export default {
    name: 'DataStatistics',
    computed: {
      ...mapState({
        device: state => state.device //判断窗口类型
      })
    },
    //组件创建
    created() {
      var date = new Date()
      let month = (date.getMonth() + 1) < 10 ? '0' + (date.getMonth() + 1) : (date.getMonth() + 1)
      let day = (date.getDate() - 1) < 10 ? '0' + (date.getDate() - 1) : (date.getDate() - 1)

      this.yesterday = date.getFullYear() + "-" + month + "-" + day + " 00:00:00"
      //   this.searchData = {
      // startDate: this.yesterday,
      // endDate: this.yesterday,
      //     time: [
      //       this.yesterday, this.yesterday,
      //     ]
      //   }
      this.getDataList();
      this.getPackageList();

      let time = new Date();
      var year = time.getFullYear(),
        months = time.getMonth(),
        date = time.getDate() - 1
      this.maxDate = new Date(year, months, date);
      this.searchDataTime = this.initialize("") + ' 至 ' + this.initialize("")
    },
    updated() {
      this.$nextTick(() => {
        this.$refs.goodsTable.doLayout();
      })
    },
    data() {
      return {
        defaultProps: {
          children: 'children',
          label: 'label'
        },
        loading: false,
        updateFormVisible: false,
        addFormVisible: false,
        checkAll: false,
        tableList: [], //tab列表
        yesterday: '',
        searchData: {},
        packageList: [],
        channelList: [],
        selected: null, //当前选中行
        //行详情抽屉
        rowDrawer: false,
        rowDetail: {},
        pageNum: 1,
        pageSize: 30,
        total: 0,
        pickerOptions: {
          disabledDate(time) {
            return time.getTime() > Date.now() - 3600 * 1000 * 24;
          },
          shortcuts: [{
            text: '昨天',
            onClick(picker) {
              const date = new Date();
              date.setTime(date.getTime() - 3600 * 1000 * 24);
              picker.$emit('pick', [date, date]);
            }
          }, {
            text: '前天',
            onClick(picker) {
              const date = new Date();
              date.setTime(date.getTime() - 3600 * 1000 * 24 * 2);
              picker.$emit('pick', [date, date]);
            }
          }, {
            text: '一周前',
            onClick(picker) {
              const date = new Date();
              date.setTime(date.getTime() - 3600 * 1000 * 24 * 7);
              picker.$emit('pick', [date, new Date()]);
            }
          }]
        },
        // 当屏幕尺寸为mobile
        is_showTree: false,
        show: false,
        minDate: new Date(2021, 9, 28),
        maxDate: "",
        searchDataTime: ""
      }
    },
    methods: {
      setTime(e) {
        if (e) {
          this.searchData.startDate = e[0] + ' 00:00:00'
          this.searchData.endDate = e[1] + ' 23:59:59'
        } else {
          this.searchData.startDate = null
          this.searchData.endDate = null
        }
        this.search()
      },
      getPackageList() {
        let param = {
          token: sessionStorage.getItem("sessionToken")
        }
        let hostUrl = this.$service.publicUrl().PackageTree
        this.axios({
          method: "POST",
          url: hostUrl,
          data: param
        }).then((response) => {
          this.loading = false
          if (response.data.status == 200) {
            this.packageList = response.data.data
            this.packageList.unshift({
              label: '全部',
              value: ''
            })
          } else {
            this.$message.warning("warning : " + response.data.message);
          }
          this.selected = null
        }).catch((error) => {
          this.loading = false
          this.$message.error("查询失败，error : " + JSON.stringify(error));
        })
      },
      search() {
        this.pageNum = 1
        this.getDataList()
      },
      nodeClick(data) {
        if (data.value != undefined) {
          if (data.value == '') {
            this.pageNum = 1
            this.searchData = {
              startDate: this.yesterday,
              endDate: this.yesterday,
            }

          } else {
            this.pageNum = 1
            this.searchData = {}
            this.searchData.packageName = data.value
          }
        } else {
          this.searchData.packageName = ''
          this.searchData.packageSeries = data.label
        }
        this.getDataList()
      },
      //列表查询
      getDataList() {
        let param = {
          pageNum: this.pageNum,
          pageSize: this.pageSize,
          ...this.searchData,
          token: sessionStorage.getItem("sessionToken")
        }
        let hostUrl = this.$service.publicUrl().StatisticsWholeList
        this.axios({
          method: "POST",
          url: hostUrl,
          data: param
        }).then((response) => {
          this.loading = false
          if (response.data.status == 200) {
            this.tableList = response.data.data.list
            this.tableList.forEach(element => {
              element.recordDate = element.recordDate.slice(0, 10)
            });

            this.total = response.data.data.total
          } else {
            this.$message.warning("warning : " + response.data.message);
          }
          this.selected = null
        }).catch((error) => {
          this.loading = false
          this.$message.error("查询失败，error : " + JSON.stringify(error));
        })
      },
      detail() {},
      qimai() {
        if (this.selected == undefined) {
          this.$message.warning("请先选中一条记录");
        } else {
          window.open("https://www.qimai.cn/search/android/market/6/search/" + this.selected.packageName)
        }
      },
      //点击行
      handleCurrentChange(e) {
        this.selected = e
      },
      pageSizeChange(e) {
        this.pageSize = e
        this.search()
      },
      //pageNum变化
      pageNumChange(e) {
        this.pageNum = e;
        this.getDataList();
      },
      to_dataTime() {
        if (this.$store.state.device != 'mobile') {
          return
        }
        this.show = true;
      },
      onConfirm(e) {
        if (e) {
          this.searchData.startDate = this.initialize(e[0]) + ' 00:00:00'
          this.searchData.endDate = this.initialize(e[1]) + ' 23:59:59'
        } else {
          this.searchData.startDate = null
          this.searchData.endDate = null
        }
        this.searchData.time = [this.initialize(e[0]), this.initialize(e[1])];
        this.searchDataTime = this.initialize(e[0]) + ' 至 ' + this.initialize(e[1])
        this.show = false;
        this.search();
      },
      // 初始化
      initialize(e) {
        var nowdate = "";
        if (e) {
          nowdate = e;
        } else {
          nowdate = new Date();
        }
        var year = nowdate.getFullYear(),
          month = (nowdate.getMonth() + 1) < 10 ? "0" + (nowdate.getMonth() + 1) : (nowdate.getMonth() + 1),
          date = e ? nowdate.getDate() < 10 ? "0" + nowdate.getDate() : nowdate.getDate() : (nowdate.getDate() - 1) <
          10 ? "0" + (nowdate.getDate() - 1) : (nowdate.getDate() - 1)
        return year + "-" + month + "-" + date
      }
    }
  }
</script>
<style lang="less" scoped>
  // .home-container {
  //   height: 100%;
  // }

  .drawer_inner {
    width: 95%;
    height: 100%;
    margin: auto;
  }

  .cont_table /deep/ .el-icon-tickets {
    font-size: 20px;
    margin-top: 4px;
  }

  .tips {
    font-size: 8px;
    margin-left: 30px;
    color: #878787;
  }

  .tips>span {
    color: orangered !important;
  }

  .content {
    display: flex;
    flex-direction: row;
    width: 100%;
    // height: 100%;
    // justify-content: space-around;
  }

  .content_left {
    margin-right: 16px;
    padding-top: 21px;
    letter-spacing: 2px;
    width: 15%;
    min-width: 100px;
    height: calc(100% - 65px);
    background: #FFFFFF;
    overflow: auto
  }

  .el-container /deep/ {
    height: 100% !important;
  }

  .cont_bar {
    display: flex;
  }

  // .fixed {
  //   position: fixed;
  //   top: 300px;
  //   right: 20px;
  //   z-index: 9;
  //   opacity: 0.5;
  // }
  /deep/.bar_left .el-form-item__content {
    max-width: 195px;
  }


  .content {
    // height: 100% !important;
  }
</style>
<style>
  .el-tree-node__content {
    height: 40px !important;
  }
</style>
