<template>
  <div id="dis_list" class="content" v-loading="loading" element-loading-text="加载中">
    <div>
      <div class="bar_left" style="width: 100%">
        <el-form :inline="true" :model="searchData" class="demo-form-inline" size="small">
          <el-form-item label="应用名称：">
            <el-select multiple collapse-tags v-model="searchData.advertIds" placeholder="应用名称" :filterable="true"
              @change="getDataList">
              <el-option label="全部" value="000"></el-option>
              <el-option v-for="item in packageList" :key="item.packageName" :label="item.packageAlias"
                :value="item.advertId == null ? '' : item.advertId">
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="统计维度：">
            <el-select v-model="chartsType" placeholder="统计维度" @change="initEchars">
              <el-option v-for="item in chartsTypeList" :key="item.id" :label="item.label" :value="item.id"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="公司主体：">
            <el-select v-model="searchData.companyName" placeholder="公司主体：" @change="getDataList">
              <el-option v-show="userInfo.userLevel == 0 || userInfo.userLevel == item.level" v-for="item in companyList" :key="item.value" :label="item.label" :value="item.value">
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="日期：">
            <el-date-picker :picker-options="pickerOptions" v-model="searchData.time" value-format="yyyy-MM-dd"
              type="date" placeholder="选择日期" @change="setTime" @blur="cancel">
            </el-date-picker>
          </el-form-item>
          <el-form-item style="margin-left: 60px;">
            <el-button icon="el-icon-refresh-right" style="margin-left: 20px;" @click="refresh">刷新</el-button>
          </el-form-item>
        </el-form>
      </div>
      <!-- <div class="bar_right"></div> -->
    </div>
    <!-- 主内容区 -->
    <div class="cont_table">
      <div class="charts">
        <div id="char" style="height:500px;"></div>
      </div>
      <el-table :stripe="true" size="mini" ref="goodsTable" id="goodsTable" :data="tableData" highlight-current-row
        width="100%">
        <el-table-column property="StartDate" label="时间" min-width="150"></el-table-column>
        <el-table-column property="Revenue" label="预估收益(¥)"></el-table-column>
        <el-table-column property="Ecpm" label="eCPM(¥)"></el-table-column>
        <el-table-column property="IpmCnt" label="展示量"></el-table-column>
        <el-table-column property="ClkCnt" label="点击量"></el-table-column>
        <el-table-column property="ClkRate" label="点击率" :formatter="formatColumn"></el-table-column>
        <el-table-column property="ReqCnt" label="广告请求量"></el-table-column>
        <el-table-column property="Response" label="广告返回量"></el-table-column>
        <el-table-column property="FillRate" label="广告填充率" :formatter="formatColumn"></el-table-column>
        <el-table-column property="AdRequest" label="物料请求量"></el-table-column>
        <el-table-column property="RetCnt" label="物料返回量"></el-table-column>
        <el-table-column property="AdFillRate" label="物料填充率" :formatter="formatColumn"></el-table-column>
        <el-table-column property="AdImpressionRate" label="物料展示率" :formatter="formatColumn"></el-table-column>
      </el-table>
    </div>
  </div>
</template>
<script>
  import * as echarts from 'echarts';
  export default {
    name: 'AdvertStatisticsReal',
    //组件创建
    created() {
      this.userInfo = JSON.parse(sessionStorage.getItem("userInfo"))
      let date = new Date();
      let year = date.getFullYear();
      let months = date.getMonth();
      let dates = date.getDate();
      this.now = year + "-" + ((months + 1) < 10 ? '0' + (months + 1) : (months + 1)) + '-' + (dates < 10 ? '0' +
        dates : dates)
      this.searchData.startDate = this.now + " 00:00:00"
      this.searchData.endDate = this.now + " 00:00:00"
      this.getPackageList()
      this.getDataList();
    },
    data() {
      return {
        userInfo: {},
        title: '广告收益',
        loading: false,
        chartsType: 1,
        now: "",
        chart: "",
        chartsTypeList: [{
            id: 1,
            label: '预估收益'
          },
          {
            id: 2,
            label: 'eCPM(¥)'
          },
          {
            id: 3,
            label: '展示量'
          },
          {
            id: 4,
            label: '点击量'
          },
          {
            id: 5,
            label: '点击率'
          },
          {
            id: 6,
            label: '填充率'
          },
        ],
        companyList: [{
            label: '成都初代科技有限公司',
            value: 'generone',
            level: 10
          },
          {
            label: '成都初哆萤技有限公司',
            value: 'lotsgain',
            level: 10
          },
          {value: "wisdomtec", label: "成都威仕多网络科技有限公司"},
          {value: "cdjztec", label: "成都璟智信息技术有限公司"},
        ],
        packageList: [],
        searchData: {
          companyName: 'generone'
        },
        pickerOptions: {
          disabledDate(time) {
            return time.getTime() > Date.now() || time.getTime() < (Date.now() - 3600 * 1000 * 24 * 6)
          }
        },
        dataTotal: [],
        tableData: [],
        dataList: [],
        xData: [
          '00:00~00:59', '01:00~01:59', '02:00~02:59', '03:00~03:59', '04:00~04:59', '05:00~05:59', '06:00~06:59',
          '07:00~07:59', '08:00~08:59', '09:00~09:59', '10:00~10:59', '11:00~11:59', '12:00~12:59', '13:00~13:59',
          '14:00~14:59', '15:00~15:59', '16:00~16:59', '17:00~17:59', '18:00~18:59', '19:00~19:59', '20:00~20:59',
          '21:00~21:59', '22:00~22:59', '23:00~23:59'
        ],
      }
    },
    methods: {
      handleChange(val) {
        console.log(val);
      },
      initEchars() {
        let yData = [];
        for (let i = 0; i < this.xData.length; i++) {
          let flag = 0
          for (let j = this.dataList.length - 1; j >= 0; j--) {
            if (this.dataList[j].StartDate.substr(11, 21) == this.xData[i]) {
              let element = this.dataList[j]
              switch (this.chartsType) {
                case 1:
                  yData.push(element.Revenue)
                  break
                case 2:
                  yData.push(element.Ecpm)
                  break
                case 3:
                  yData.push(element.IpmCnt)
                  break
                case 4:
                  yData.push(element.ClkCnt)
                  break
                case 5:
                  yData.push(element.ClkRate)
                  break
                case 6:
                  yData.push(element.FillRate)
                  break
              }
              flag = 1
              break
            }
          }
          if (flag == 0) {
            yData.push("0.00")
          }
        }
        console.log(yData)
        var chartDom = document.getElementById('char');
        this.chart = echarts.init(chartDom);
        var option = {
          title: {
            text: this.chartsTypeList[this.chartsType - 1].label + '（' + this.now + '）',
            left: 'center'
          },
          tooltip: {
            trigger: 'axis'
          },
          xAxis: {
            type: 'category',
            boundaryGap: false,
            // data: xData
            data: this.xData
          },
          yAxis: {
            type: 'value'
          },
          series: [{
            name: this.chartsTypeList[this.chartsType - 1].label,
            type: 'line',
            smooth: true,
            data: yData
          }]
        };

        option && this.chart.setOption(option, true);
      },
      changePackage(e) {
        console.log(e)
      },
      refresh() {
        this.getDataList()
      },
      setTime(e) {
        if (e) {
          this.searchData.startDate = e + " 00:00:00"
          this.searchData.endDate = e + " 00:00:00"
          this.now = e
        } else {
          var date = new Date()
          this.now = date.getFullYear() + "-" + ((date.getMonth() + 1) < 10 ? '0' + (date.getMonth() + 1) : (date
            .getMonth() + 1)) + '-' + (date.getDate() < 10 ? '0' + date.getDate() : date.getDate())
          this.searchData.startDate = this.now + " 00:00:00"
          this.searchData.endDate = this.now + " 00:00:00"
        }
        this.getDataList()
      },
      cancel(e) {
        console.log(e)
      },
      formatColumn(row, column, cellValue, index) {
        return (Number(cellValue) * 100).toFixed(2) + '%'
        // item.FillRate = (Number(item.FillRate) * 100).toFixed(2) + '%',
        // item.AdImpressionRate = (Number(item.AdImpressionRate) * 100).toFixed(2) + '%',
        // item.AdFillRate = (Number(item.AdFillRate) * 100).toFixed(2) + '%'
      },
      formatNumber() {
        return Number(cellValue)
      },
      getDataList(type) {
        let list = []
        if (this.searchData.advertIds && this.searchData.advertIds[0] != '000') {
          list = JSON.parse(JSON.stringify(this.searchData.advertIds))
        }
        // let param = JSON.parse(JSON.stringify({
        //     ...this.searchData,
        //     pageSize: 100,
        //     pageNum: 1
        //   }))
        this.searchData.advertIdList = list
        let hostUrl = this.$service.publicUrl().AdStatisticsRealTime
        this.axios({
          method: "POST",
          url: hostUrl,
          data: {
            ...this.searchData,
            pageSize: 100,
            pageNum: 1
          },
        }).then((response) => {
          this.loading = false
          if (response.data.status == 200) {
            this.dataList = response.data.data.ReportInfo.ReportList
            this.dataTotal = response.data.data.ReportInfo.Summary,
              this.dataTotal.StartDate = '总计'
            this.tableData = [this.dataTotal].concat(this.dataList)
            this.pageInfo = response.data.data.PageInfo
            this.initEchars()
          } else {
            this.$message.warning("warning : " + response.data.message);
          }
        })
      },
      getPackageList() {
        let hostUrl = this.$service.publicUrl().PackageListAll
        this.axios({
        		method: "GET",
          url: hostUrl,
        }).then((response) => {
        		this.loading = false
          if (response.data.status == 200) {
            this.packageList = response.data.data
          } else {
            this.$message.warning("warning : " + response.data.message);
       		 }
        }).catch((error) => {
       		 this.loading = false
          this.$message.error("查询失败，error : " + JSON.stringify(error));
        })
      },
    }
  }
</script>
<style lang="less" scoped>
  .charts {
    background: #FFFFFF;
    width: 98%;
    padding-right: 5%;
    // display: flex;
    height: 500px;
    margin-top: 10px;
    padding-top: 15px;
  }

  .ranking-item {
    display: flex;
  }

  // .el-main {
  //   padding-top: 0px;
  //   height: 80vh;
  // }
  /deep/.bar_left .el-form-item__content {
    max-width: 190px;
  }

  .cont_bars {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    // overflow-x: auto;
  }
</style>
