<template>
  <div id="dis_list" class="content" v-loading="loading" element-loading-text="加载中">
    <!-- 顶部操作栏 -->
    <div class="cont_bar">
      <div class="bar_left" style="width:100%;">
        <el-form :inline="true" :model="searchData" class="demo-form-inline" size="small">
          <el-form-item label="用户ID：">
            <el-input v-model="searchData.id" placeholder="请输入用户ID" />
          </el-form-item>
          <el-form-item label="是否VIP：">
            <el-select v-model="searchData.vip" placeholder="是否VIP" :filterable="true">
              <el-option label="全部" value=""></el-option>
              <el-option label="是" value="1"></el-option>
              <el-option label="否" value="0"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item>
            <el-tooltip content="查询" placement="top">
              <el-button type="primary" @click="search()" icon="el-icon-search"></el-button>
            </el-tooltip>
          </el-form-item>
        </el-form>
      </div>
    </div>
    <!-- 主内容区 -->
    <div class="cont_table">
      <!-- 表格 -->
      <el-table ref="goodsTable" id="goodsTable" :data="tableList" highlight-current-row
        @row-click="handleCurrentChange" width="100%" height="90%">
        <el-table-column prop="id" width="70" label="用户编号"></el-table-column>
        <el-table-column property="packageName" label="应用包名" min-width="150"></el-table-column>
        <el-table-column property="nickname" label="用户姓名" min-width="100"></el-table-column>
        <el-table-column property="userType" label="注册方式" min-width="100">
          <template slot-scope="scope">
            <el-row class="row-op-text" :style="scope.row.userType == -1 ? 'color: #999999' : 'color: green'">
              {{scope.row.userType == -1 ? '游客' : (scope.row.userType == 1 ? '手机注册' : (scope.row.userType == 2 ? '微信注册' : (scope.row.userType == 3 ? 'QQ注册' : '苹果账号注册'))) }}
            </el-row>
          </template>
        </el-table-column>
        <el-table-column property="vip" label="是否VIP" min-width="100">
          <template slot-scope="scope">
            <el-row class="row-op-text">
              {{scope.row.vip == 1 ? '是' : '否'  }}
            </el-row>
          </template>
        </el-table-column>
        <el-table-column property="vipExpireDate" label="VIP到期时间" min-width="100"></el-table-column>
        <el-table-column property="userName" label="用户姓名" min-width="100"></el-table-column>
        <el-table-column property="contact" label="联系方式" min-width="150"></el-table-column>
        <el-table-column property="channel" label="渠道" min-width="80"></el-table-column>
        <el-table-column property="version" label="版本号" min-width="80"></el-table-column>
        <el-table-column property="createTime" label="创建时间" min-width="150"></el-table-column>
        <el-table-column label="操作" min-width="150" align="center">
          <template slot-scope="scope">
            <el-tooltip content="修改" placement="top">
              <el-button type="text" icon="el-icon-edit" size="medium" @click="openEdit(scope.row)">修改</el-button>
            </el-tooltip>
          </template>
        </el-table-column>
      </el-table>
      <!-- 分页器 -->
      <div class="block">
        <el-pagination :current-page="pageNum" :page-sizes="[10, 15, 20, 30]" :page-size="pageSize" :total="total"
          layout="total, sizes, prev, pager, next, jumper" @size-change="pageSizeChange"
          @current-change="pageNumChange">
        </el-pagination>
      </div>
    </div>
    <el-dialog title="用户修改" :visible="showEdit">
      <el-form :model="selected" v-if="selected!= null">
        <el-form-item label="会员状态: " label-width="150">
          <el-radio-group v-model="selected.vip">
              <el-radio :label="1">会员</el-radio>
              <el-radio :label="0">非会员</el-radio>
            </el-radio-group>
        </el-form-item>
        <el-form-item label="VIP到期时间: " label-width="150">
          <el-date-picker type="date" value-format="yyyy-MM-dd 00:00:00" placeholder="VIP到期时间" v-model="selected.vipExpireDate"></el-date-picker>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="showEdit = false">取 消</el-button>
        <el-button type="primary" @click="updateUser">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>
<script>
  export default {
    name: 'UserManagemment',
    //组件创建
    created() {
      this.getDataList()
    },
    mounted() {},
    data() {
      return {
        loading: false,
        showEdit: false,
        pageSize: 30,
        pageNum: 1,
        total: 0,
        searchData: {},
        selected: null, //当前选中行
        tableList: []
      }
    },
    methods: {
      search() {
        this.pageNum = 1
        this.getDataList()
      },
      getDataList() {
        let param = {
          token: sessionStorage.getItem("sessionToken"),
          ...this.searchData,
          pageNum: this.pageNum,
          pageSize: this.pageSize
        }
        let hostUrl = this.$service.publicUrl().UserList
        this.axios({
          method: "POST",
          url: hostUrl,
          data: param
        }).then((response) => {
          this.loading = false
          if (response.data.status == 200) {
            this.tableList = response.data.data.list
            this.total = response.data.data.total
          } else {
            this.$message.warning("warning : " + response.data.message);
          }
          this.selected = null
        }).catch((error) => {
          this.loading = false
          this.$message.error("查询失败，error : " + JSON.stringify(error));
        })
      },
      //点击行
      handleCurrentChange(e) {
        this.selected = e
      },
      openEdit(e) {
        this.showEdit = true
        this.selected = e
        console.log(this.selected)
      },
      updateUser() {
        if (this.selected == null) {
          this.$message.warning("请选择一条记录");
        } else {
          let param = {
            userId: this.selected.id,
            vip: this.selected.vip,
            vipExpireDate: this.selected.vipExpireDate,
            token: sessionStorage.getItem("sessionToken")
          }
          let hostUrl = this.$service.publicUrl().UpdateUser
          this.axios({
            method: "POST",
            url: hostUrl,
            data: param
          }).then((response) => {
            if (response.data.status == 200) {
              this.$message.success("修改成功");
              this.showEdit = false
              this.selected = null
              this.getDataList()
            } else {
              this.$message.warning("warning : " + response.data.message);
            }
          }).catch((error) => {
            this.loading = false
            this.$message.error("更新失败，error : " + JSON.stringify(error));
          })
        }
      },
      initDialog() {
        this.$refs["addForm"].resetFields()
      },
      pageSizeChange(e) {
        this.pageSize = e
        this.getDataList()
      },
      //pageNum变化
      pageNumChange(e) {
        this.pageNum = e
        this.getDataList();
      },
    }
  }
</script>
<style lang="less" scoped>
</style>
